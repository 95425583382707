import { InteractiveComponent, LiveSessionVisualizationOptions } from "./InteractiveElement";
import React, { useContext, useEffect, useState } from "react";
import { Quiz } from "../../../hybrid-documents/src/Book";

import "./Quiz.scss";
import { ListGroup } from "react-bootstrap";
import { flatten } from "../util/functional";
import { UserStudyContext } from "../../src/study/UserStudy";
import { renderHorizontal } from "../book/render";

export const getLiveSessionVisualizationOptions = (quiz: Quiz): LiveSessionVisualizationOptions => ({
    stages: [{
        maximalProgress: quiz.questions.length,
        displayName: "Quizzing"
    }]
});

export const createQuizComponent = (quiz: Quiz, id: string): InteractiveComponent => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validateState = (inState: any): number[][] => {
        const defaultState = quiz.questions.map(() => []);

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (typeof inState === "object" && inState.length === quiz.questions.length && inState.every && inState.every((a: any, i: number) => {
                const question = quiz.questions[i];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return typeof a === "object" && (question.multiple || a.length <= 1) && a.every && a.every((b: any) => Number.isInteger(b) && b >= 0 && b < question.answers.length);
            })) {
                return inState;
            } else {
                return defaultState;
            }
        } catch (e) {
            return defaultState;
        }
    };

    const arraySetEquals = (a: number[], b: number[]): boolean => a.every((ae) => b.includes(ae)) && b.every((be) => a.includes(be));

    const QuizComponent: InteractiveComponent = ({ state: inState, setState, markAsSolved }) => {
        const [selections, setSelections] = React.useState<number[][]>(validateState(inState));
        const [dirty, setDirty] = React.useState<boolean>(false);

        const userStudy = useContext(UserStudyContext);

        // ↓ OK because we manually avoid a loop with the dirty flag
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => {
            if (dirty) { // only save when something changed to decrease performance impact on chapter load
                setState(selections);
                setDirty(false);
            }
        });

        const select = (questionIndex: number, answerIndex: number): void => {
            const question = quiz.questions[questionIndex];
            if (question.multiple) {
                if (selections[questionIndex].includes(answerIndex)) {
                    setSelections(selections.map((s, i) => i === questionIndex ? s.filter((a) => a !== answerIndex) : s));
                    setDirty(true);
                    if (question.answers[answerIndex].correct) userStudy.reportEvent({ type: "INTERACTIVE_REPORT_ERROR", "interactiveType": "quiz", interactiveId: id });
                } else {
                    setSelections(selections.map((s, i) => i === questionIndex ? [...s, answerIndex].sort() : s));
                    setDirty(true);
                    if (!question.answers[answerIndex].correct) userStudy.reportEvent({ type: "INTERACTIVE_REPORT_ERROR", "interactiveType": "quiz", interactiveId: id });
                }
            } else {
                setSelections(selections.map((s, i) => i === questionIndex ? [answerIndex] : s));
                setDirty(true);
                if (!question.answers[answerIndex].correct) userStudy.reportEvent({ type: "INTERACTIVE_REPORT_ERROR", "interactiveType": "quiz", interactiveId: id });
            }
        };

        const solved = quiz.questions.map((question, questionIndex) => arraySetEquals(selections[questionIndex], flatten(question.answers.map((answer, i) => answer.correct ? [i] : []))));

        const [lastReportedProgress, setLastReportedProgress] = useState<number>(-1);

        useEffect(() => {
            const progress = solved.filter((b) => b).length;
            if (progress !== lastReportedProgress) {
                userStudy.reportEvent({ type: "INTERACTIVE_PROGRESS_UPDATE", interactiveType: "quiz", interactiveId: id, stage: 0, progress });
                setLastReportedProgress(progress);
            }
        }, [lastReportedProgress, solved, userStudy]);

        const solvedQuestions = solved.filter((b) => b).length;
        const totalQuestions = solved.length;
        const quizSolved = solvedQuestions >= totalQuestions;
        if (quizSolved) markAsSolved();

        const [quizPreviouslySolved, setQuizPreviouslySolved] = useState<boolean>(false);
        useEffect(() => {
            if (quizSolved && !quizPreviouslySolved) setQuizPreviouslySolved(true);
        }, [quizPreviouslySolved, quizSolved]);

        return <div className="quiz">
            {quiz.questions.map((question, questionIndex) => <div className="question" key={questionIndex}>
                <div className="prompt">
                    { question.prompt.map((he, i) => renderHorizontal(he, i, id)) }
                    { solved[questionIndex] ? <span className="solved-marker">✓</span> : null }
                </div>
                <ListGroup>
                    {question.answers.map((answer, answerIndex) =>
                        <ListGroup.Item
                            className="answer"
                            key={answerIndex}
                            variant="dark"
                            action
                            active={selections[questionIndex].includes(answerIndex)}
                            onClick={(): void => select(questionIndex, answerIndex)}
                        >
                            { answer.content.map((he, i) => renderHorizontal(he, i, id)) }
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </div>)}
            <div className="quiz-evaluation">
                { quizSolved ? <div className="quiz-evaluation-solved">
                    All questions solved correctly ✓
                </div> : <div className={"quiz-evaluation-unsolved" + (quizPreviouslySolved ? " was-previously-solved" : "")}>
                    { solvedQuestions } of { totalQuestions } question{ totalQuestions !== 1 ? "s" : ""} solved correctly.
                </div> }
            </div>
        </div>;
    };
    return QuizComponent;
};

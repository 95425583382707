import React, { PropsWithChildren, useEffect, useState } from "react";

import "./KeyboardEventBarrier.scss";

/**
 * This element blocks keydown elements from bubbling up.
 */
export const KeyDownEventBarrier: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    // we store the ref in state so we can hang on to it to properly remove the event listener again
    const [element, setElement] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (element !== null) {
            const listener = (e: KeyboardEvent) => e.stopPropagation();
            element.addEventListener("keydown", listener);
            return () => {
                element.removeEventListener("keydown", listener);
            };
        }
    }, [element]);

    return <div ref={setElement} className="keyboard-event-barrier">
        { children }
    </div>;
};

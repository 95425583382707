import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollContainer from '../../../hybrid-documents-reader-core/ScrollContainer';

import "./LandingPage.scss";

import logo from "../../../img/logo.svg";

export const LandingPage: React.FC<{}> = () => {
    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="landing">
            <div className="pseuco-logo-rail">
                <img className="pseuco-logo" src={logo} />
            </div>
            <h1>pseuCo Book</h1>
            <p>This is a digital book to teach you concurrent programming from the grounds up. It starts with a deep theoretical foundation based on the process calculus CCS. Using pseuCo, a minimal academic programming language, it then teaches the fundamentals of practical concurrent programming, and finally applies this knowledge to Java.</p>
            <p>But this is more than just a digitized version of a printed book: All chapters are built around interactive exercises and demonstrations that allow you to experience and interact with the presented materials.           </p>

            <h2>Get Started</h2>
            <Link to="/read"><Button variant="primary" size="lg"><strong>Start Reading</strong></Button></Link>
            <a href="./pseuco-book.pdf"><Button className="pdf-download" variant="secondary" size="lg">Download PDF version</Button></a>

            <h2>Project Status</h2>
            <p>This book is a work-in-progress. The chapters on <a href="#/read/ccs">CCS</a>, <a href="#/read/equality">Equality</a>, and <a href="#/read/pseuco">Programming with pseuCo</a> are completed are stable.</p>
            <p>Nonetheless, pseuCo Book is far from finished. Many chapters are missing, and it does not do the full concurrent programming story justice. In the meantime, this book should be used in conjunction with the script of the <a href="https://dcms.cs.uni-saarland.de/np_22/">Concurrent Programming lecture</a>.</p>
            <div className="pseuco-logo-clear" />
        </Container>
    </ScrollContainer>;
};


/**
 * Finds the last index in an array pointing to an element that fulfills a predicate.
 * @param array The array to search in.
 * @param f The predicate to check against.
 */
export const findLastIndex = <T>(array: T[], f: (e: T) => boolean): number => {
    let i = array.length - 1;
    while (i >= 0 && !f(array[i])) i--;
    return i;
};
import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { applicationVersion, applicationName } from '../../ProductInfo';
import { OfflineStatus } from '../settings/OfflineStatus';
import ScrollContainer from '../../../hybrid-documents-reader-core/ScrollContainer';
import { UserStudyContext } from '../../study/UserStudy';
import { UserStudyPrivacyPolicy } from '../../study/PrivacyPolicy';

import "./AboutPage.scss";

import imgCC from "../../../img/cc.svg";
import imgBY from "../../../img/by.svg";
import imgNC from "../../../img/nc.svg";
import imgSA from "../../../img/sa.svg";

const AboutPage: React.FC<{}> = () => {
    const userStudy = useContext(UserStudyContext);

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="about-page">
            <h1>About this Application</h1>
            <h2>Version</h2>
            <div>This is version <code>{ applicationVersion }</code> of { applicationName }.</div>
            <div>PseuCo Book is open source. You can find its source code <a href="https://dgit.cs.uni-saarland.de/fefrei/pseuco-book">here</a>.</div>
            <OfflineStatus />

            <h1>Legal Notices</h1>
            <h2>Imprint</h2>
            <p>This website is provided by Saarland University as a public corporation. The university is represented by its president.</p>
            <p>Regulatory authority: Saarland State Chancellery</p>
            <p>
                Saarland University<br />
                Saarbrücken Campus<br />
                66123 Saarbrücken<br />
                Germany<br /> 
            </p>
            <p>
                Phone: +49 681 302-0<br />
                Fax: +49 681 302-2500<br />
                E-mail: <a href="mailto:info@uni-saarland.de">info@uni-saarland.de</a>
            </p>
            <p>You can find more information in <a href="https://www.uni-saarland.de/en/legal-notice.html">Saarland University's imprint</a>.</p>

            <h2>Privacy Policy</h2>
            { userStudy.enabled ? <>
                <p>By itself, this web application, {applicationName}, does not collect any personally identifiable information. All data entered within the application is only stored locally in your browser and never leaves your device. Access logs in the webserver are disabled.</p>
                <p>Currently, we are running the {applicationName} user study. If you decide to enroll in this study, the following privacy policy applies:</p>
                <h3>{applicationName} User Study Privacy Policy</h3>
                <UserStudyPrivacyPolicy />
            </> : <>
                <p>This web application, {applicationName}, does not collect any personally identifiable information. All data entered within the application is only stored locally in your browser and never leaves your device. Access logs in the webserver are disabled.</p>
                <p>Therefore, it does not have a privacy policy.</p>
            </> }

            <h2>Copyright</h2>
            <p>
                <span>PseuCo Book</span> by <span>Felix Freiberger (Saarland University)</span> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style={{ display: "inline-block" }}> CC BY-NC-SA 4.0 <img className="cc-license-logo" src={imgCC} alt="" /><img className="cc-license-logo" src={imgBY} alt="" /><img className="cc-license-logo" src={imgNC} alt="" /><img className="cc-license-logo" src={imgSA} alt="" />
                </a>.
            </p>
            <p>PseuCo Book is based on the MIT-licensed <a href="https://dgit.cs.uni-saarland.de/fefrei/hybrid-documents-reader-core">Hybrid Documents Reader Core</a>.</p>
            <p>Some icons © Jan Kovařík, see <a href="https://glyphicons.com/">GLYPHICONS.com</a>. All rights reserved.</p>
        </Container>
    </ScrollContainer>;
};

export default AboutPage;

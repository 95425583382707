import React, { useCallback, useEffect } from 'react';
import { TableOfContents } from './TableOfContents';
import { ReadView, NavigationTarget, Navigation } from './ReadView';
import { Book, Chapter, Part } from '../../../../hybrid-documents/src/Book';

import { getBook } from '../../book/Book';
import { Spinner } from 'react-bootstrap';

import "./ReadPage.scss";
import { RouteComponentProps, useHistory, Redirect } from 'react-router';
import { Link } from 'react-router-dom';

type Params = {
    chapter?: string;
    part?: string;
    anchor?: string;
}

/**
 * Everything that identifies one page to be shown in the read view.
 */
export type PageId = {
    chapterId: string;
    partId: string;
};

export const ReadPage: React.FC<RouteComponentProps<Params>> = ({ match }) => {
    const [tocExpanded, setTocExpanded] = React.useState(false);

    const [book, setBook] = React.useState<Book | null>(null);
    const [bookLoading, setBookLoading] = React.useState<boolean>(false);
    const [bookError, setBookError] = React.useState<boolean>(false);

    const [part, setPart] = React.useState<Part | null>(null);
    const [chapter, setChapter] = React.useState<Chapter | null>(null);
    const [pageId, setPageId] = React.useState<PageId | null>(null);

    const [notFoundError, setNotFoundError] = React.useState<boolean>(false);
    
    const [sectionId, setSectionId] = React.useState<string | null>(null);

    const anchorIdFromRoute = match.params.anchor && match.params.anchor.length ? match.params.anchor : null;

    let navigationGeneration = 0;

    const navigateToPage = (pageId: PageId | null): void => {
        setPageId(pageId);
        setPart(null);
        setChapter(null);
        setSectionId(null);
        setNotFoundError(false);
        if (pageId === null) return;
        if (book === null) throw new Error("Cannot navigate to chapter while book is not loaded");

        const chapter = book.chapters.find((c) => c.id === pageId.chapterId);
        if (chapter) {
            const part = chapter.parts.find((p) => p.id === pageId.partId);
            if (part) {
                setChapter(chapter);

                const thisNavigationGeneration = ++navigationGeneration; // ensure we drop the async set if we missed the opportunity because the user navigated really fast
                setTimeout(() => {
                    // delay the expensive part loading so we react quickly to navigation
                    if (navigationGeneration === thisNavigationGeneration) setPart(part);
                });
            } else {
                setNotFoundError(true);
            }
        } else {
            setNotFoundError(true);
        }
    };

    const pageIdFromRoute: PageId | null = match.params.chapter && match.params.part ? { chapterId: match.params.chapter, partId: match.params.part } : null;
    if (book !== null && JSON.stringify(pageId) !== JSON.stringify(pageIdFromRoute)) {
        navigateToPage(pageIdFromRoute);
    }

    let redirectTarget = ""; // only used if needed, see JSX below
    if (book) {
        redirectTarget = `${book.chapters[0].id}/${book.chapters[0].parts[0].id}`; // go to beginning
        if (match.params.chapter) {
            const chapter = book.chapters.find((c) => c.id === match.params.chapter);
            if (chapter) {
                redirectTarget = `${match.params.chapter}/${chapter.parts[0].id}`; // go to beginning of specified chapter
            }
        }
    }

    useEffect(() => {
        if (book === null && !bookLoading) {
            setBookLoading(true);
            getBook().then((book) => {
                setBook(book);
            }, () => {
                setBookError(true);
            }).finally(() => setBookLoading(false));
        }
    }, [book, bookLoading]);

    const history = useHistory();

    const onScrolledToAnchor = (anchorId: string | null, sectionId: string | null): void => {
        if (!pageId) throw new Error("Scrolled to anchor, but no page is loaded.");

        if (anchorId !== anchorIdFromRoute) {
            let targetPath = `/read/${pageId.chapterId}/${pageId.partId}`;
            if (anchorId !== null) targetPath += `/${anchorId}`;
            history.replace(targetPath);
        }

        setSectionId(sectionId);
    };

    const getNavigation = (): Navigation => {
        const nullNavigation = { prevPart: null, nextPart: null };
        if (book === null || pageId === null) return nullNavigation;
        const currentChapterIndex = book.chapters.findIndex((c) => c.id === pageId.chapterId);
        if (currentChapterIndex < 0) return nullNavigation;
        const chapter = book.chapters[currentChapterIndex];
        const currentPartIndex = chapter.parts.findIndex((c) => c.id === pageId.partId);
        if (currentPartIndex < 0) return nullNavigation;

        type PartIndexes = { chapter: number; part: number };

        let prevPartIndexes: PartIndexes | null = null;
        let nextPartIndexes: PartIndexes | null = null;

        if (currentPartIndex > 0) {
            prevPartIndexes = {
                chapter: currentChapterIndex,
                part: currentPartIndex - 1
            };
        } else {
            if (currentChapterIndex > 0) {
                // last part in previous chapter
                prevPartIndexes = {
                    chapter: currentChapterIndex - 1,
                    part: book.chapters[currentChapterIndex - 1].parts.length - 1
                };
            }
        }

        if (currentPartIndex < chapter.parts.length - 1) {
            nextPartIndexes = {
                chapter: currentChapterIndex,
                part: currentPartIndex + 1
            };
        } else {
            if (currentChapterIndex < book.chapters.length - 1) {
                // first part in next chapter
                nextPartIndexes = {
                    chapter: currentChapterIndex + 1,
                    part: 0
                };
            }
        }

        const indexesToNavigationTarget = (indexes: PartIndexes | null): NavigationTarget | null => indexes === null ? null : {
            displayName: book.chapters[indexes.chapter].parts[indexes.part].displayName,
            route: `/read/${book.chapters[indexes.chapter].id}/${book.chapters[indexes.chapter].parts[indexes.part].id}`
        };

        return {
            prevPart: indexesToNavigationTarget(prevPartIndexes),
            nextPart: indexesToNavigationTarget(nextPartIndexes)
        };
    };

    const navigation = getNavigation();

    const handleKeyDown = useCallback((e: KeyboardEvent): void => {
        if (e.key === 'ArrowLeft' && navigation.prevPart) history.push(navigation.prevPart.route);
        if (e.key === 'ArrowRight' && navigation.nextPart) history.push(navigation.nextPart.route);
    }, [history, navigation.nextPart, navigation.prevPart]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    if (book === null) {
        if (bookError) {
            return <div className="read-page">
                <div className="error-container pad-navbar pad-top">
                    <p><strong>Failed to load book.</strong></p>
                    <p>Please check your internet connection.</p>
                </div>
            </div>;
        } else if (bookLoading) {
            return <div className="read-page">
                <div className="spinner-container pad-navbar pad-top">
                    <p>Loading book…</p>
                    <Spinner animation="border" />
                </div>
            </div>;

        } else {
            return null;
        }
    } else {
        return <div className="read-page">
            <TableOfContents tocExpanded={tocExpanded} setTocExpanded={setTocExpanded} book={book} selectedChapterId={pageId?.chapterId ?? null} selectedChapter={chapter} selectedPartId={pageId?.partId ?? null} selectedPart={part} selectedSectionId={sectionId} />
            {
                (pageId === null) ? (
                    <Redirect to={`/read/${redirectTarget}`} />
                ) : (
                    chapter == null || part === null
                        ? (notFoundError ? <div className="notfound-container toc-offset pad-navbar">
                            <div className="notfound-message">
                                <div className="subtitle">— not found —</div>
                                <div className="title">404!</div>
                                <div className="explainer">That's an error.</div>
                                <div className="message">This chapter or part does not exist. You can select a different one in the table of contents, or go to <Link to="/read">the beginning</Link>.</div>
                            </div>
                        </div> : <div className="spinner-container toc-offset pad-navbar">
                            <p>Loading section…</p>
                            <Spinner animation="border" />
                        </div>)
                        : <ReadView key={JSON.stringify(pageId)} chapterId={pageId.chapterId} partId={pageId.partId} anchorId={anchorIdFromRoute} chapter={chapter} part={part} onScrolledToAnchor={onScrolledToAnchor} navigation={navigation} />
                )
            }
        </div>;
    }
};

import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Container, Form, Modal, Table } from "react-bootstrap";
import { Book } from "../../../../../hybrid-documents/src/Book";
import { LiveSessionActivity } from "../../../../../pseuco-book-server/src/types";
import { getBook } from "../../../../hybrid-documents-reader-core/book/Book";
import interactiveElementDefinitions from "../../../interactive/interactive";
import ScrollContainer from "../../../../hybrid-documents-reader-core/ScrollContainer";
import { getJoinLinkForLiveSession, UserStudyContext } from "../../../study/UserStudy";

import "./LiveTeacherControls.scss";

import eyeOffIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-53-eye-off.svg";
import bookLibrarySearchIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-959-book-library-search.svg";
import checkIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-739-check.svg";
import { ToastContext } from "../../../../hybrid-documents-reader-core/Toaster";
import { StudentsPoolViewer } from "./StudentsPoolViewer";
import { PseudonymColorBox } from "./PseudonymColorBox";
import { getLiveSessionVisualizationOptions } from "../../../../hybrid-documents-reader-core/interactive/Quiz";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const LiveTeacherControls: React.FC<Record<string, never>> = () => {
    const userStudy = useContext(UserStudyContext);
    const toaster = useContext(ToastContext);

    const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
    const [newSessionName, setNewSessionName] = useState<string>("");
    
    const [showSelectActivityModal, setShowSelectActivityModal] = useState<boolean>(false);
    const [callAttentionOnActivitySelect, setCallAttentionOnActivitySelect] = useState<boolean>(true);
    const [newActivity, setNewActivity] = useState<LiveSessionActivity | null>(null);

    const [book, setBook] = useState<Book | null>(null);
    useEffect(() => {
        getBook().then((book) => setBook(book));
    }, []);

    const [rawScale, setRawScale] = useState<number>(100);
    const scale = (1.0 - (0.8 * (100-rawScale) / 100));

    const [fullscreenActivityProgress, setFullscreenActivityProgress] = useState<boolean>(false);

    if (!userStudy.liveSessionStatus?.joinedAsTeacher) return <p>You are not joined to a live session as a teacher.</p>;
    if (!book) return <p>Loading book…</p>;

    const startRename = () => {
        setNewSessionName(userStudy.liveSessionStatus?.name ?? "");
        setShowRenameModal(true);
    };

    const activity = userStudy.liveSessionStatus.status.activity;

    const selectActivity = () => {
        setNewActivity(activity);
        setCallAttentionOnActivitySelect(true);
        setShowSelectActivityModal(true);
    };

    const copyJoinLink = async () => {
        if (!userStudy.liveSessionId) throw new Error("Cannot copy link – not joined to a live session.");
        await navigator.clipboard.writeText(getJoinLinkForLiveSession(userStudy.liveSessionId));
        toaster.toast("success", "Link Copied to Clipboard", "Send this link to students to invite them.", 5000);
    };

    const visualizationOptions = activity?.type === "interactive" ? interactiveElementDefinitions[activity.interactiveId].liveSessionVisualizationOptions ?? null : activity?.type === "quiz" ? getLiveSessionVisualizationOptions(book.quizzes[activity.quizId]) : null;

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className={classNames({ "live-session-teacher": true, fullscreen: fullscreenActivityProgress })}>
            <Modal show={showRenameModal} onHide={() => setShowRenameModal(false)}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Rename Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>New Session Name</Form.Label>
                            <Form.Control value={newSessionName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewSessionName(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowRenameModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" onClick={() => {
                            setShowRenameModal(false);
                            userStudy.updateLiveSessionStatus({ name: newSessionName });
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showSelectActivityModal} onHide={() => setShowSelectActivityModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <ButtonGroup>
                                <Button variant="secondary" active={newActivity === null} onClick={() => setNewActivity(null)}>none</Button>
                                <Button variant="secondary" active={newActivity?.type === "interactive"} onClick={() => setNewActivity({ type: "interactive", interactiveId: newActivity?.type === "interactive" ? newActivity.interactiveId : Object.keys(interactiveElementDefinitions)[0] })}>Exercise</Button>
                                <Button variant="secondary" active={newActivity?.type === "quiz"} onClick={() => setNewActivity({ type: "quiz", quizId: newActivity?.type === "quiz" ? newActivity.quizId : Object.keys(book.quizzes)[0] })}>Quiz</Button>
                            </ButtonGroup>
                        </Form.Group>
                        { newActivity?.type === "interactive" ? <Form.Group>
                            <Form.Label>Select an Exercise</Form.Label>
                            <Form.Control as="select" value={newActivity.interactiveId} onChange={(e) => setNewActivity({ type: "interactive", interactiveId: e.target.value })}>
                                { Object.entries(interactiveElementDefinitions).map(([id, def]) => <option key={id} value={id}>{ def.title }</option>) }
                            </Form.Control>
                        </Form.Group> : null }
                        { newActivity?.type === "quiz" ? <Form.Group>
                            <Form.Label>Select a Quiz</Form.Label>
                            <Form.Control as="select" value={newActivity.quizId} onChange={(e) => setNewActivity({ type: "quiz", quizId: e.target.value })}>
                                { Object.entries(book.quizzes).map(([id, def]) => <option key={id} value={id}>{ def.title }</option>) }
                            </Form.Control>
                        </Form.Group> : null }
                        <Form.Group>
                            <Form.Check type="checkbox" checked={callAttentionOnActivitySelect && newActivity !== null} onChange={() => setCallAttentionOnActivitySelect(!callAttentionOnActivitySelect)} disabled={newActivity === null} label="Call Attention" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSelectActivityModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => {
                        setShowSelectActivityModal(false);
                        userStudy.updateLiveSessionStatus({ status: { activity: newActivity } });
                        if (callAttentionOnActivitySelect && newActivity !== null) userStudy.liveSessionCallAttention();
                    }}>
                        Activate Activity
                    </Button>
                </Modal.Footer>
            </Modal>

            <h1>
                Live Session: { userStudy.liveSessionStatus.name } { fullscreenActivityProgress ? <Button size="sm" variant="secondary" onClick={() => setFullscreenActivityProgress(false)}>Leave Fullscreen</Button> : <>
                    <Button size="sm" variant="secondary" onClick={startRename}>Rename</Button> <Button size="sm" variant="secondary" onClick={copyJoinLink}>Copy Link</Button> <Button size="sm" variant="warning" onClick={() => userStudy.leaveLiveSession()}>Leave</Button>
                </> }
            </h1>
            { fullscreenActivityProgress ? null : <>
                <div className="current-activity">
                    Current activity: { activity === null ? "none" : activity.type === "interactive" ? <>
                        <Link to={`/interactive/${activity.interactiveId}`}>Interactive Element <code>{ activity.interactiveId }</code></Link>
                    </> : activity.type === "quiz" ? <>
                        <Link to={`/quiz/${activity.quizId}`}>Quiz <code>{ activity.quizId }</code></Link>
                    </> : "unknown" }
                </div>
                <Button variant="primary" className="action-button" onClick={() => userStudy.liveSessionCallAttention()}>Call Attention</Button>
                <Button variant="primary" className="action-button" onClick={() => selectActivity()}>Select Activity</Button>
            </> }

            { userStudy.liveSessionStudents && userStudy.liveSessionStatus.status.activity && visualizationOptions ? <>
                { fullscreenActivityProgress ? null : <h2 className="sub-heading">Activity Progress Overview <Button size="sm" variant="secondary" onClick={() => setFullscreenActivityProgress(true)}>Fullscreen</Button></h2> }
                <StudentsPoolViewer students={userStudy.liveSessionStudents.students.map((s) => ({
                    key: s.pseudonymousId,
                    color: s.pseudonymousColor,
                    engaged: s.state.sessionVisible && s.state.navigatedToLiveSession,
                    solved: s.state.activityState?.solved ?? false,
                    stage: s.state.activityState?.stage ?? 0,
                    progress: s.state.activityState?.progress ?? 0
                }))} visualizationOptions={visualizationOptions} scale={scale} />
                <div className="scale">
                    Scale:{" "}
                    <input type="range" name="range" defaultValue={100} className="scale-slider" onChange={event => setRawScale(parseInt(event.target.value))} min="0" max="100" />
                </div>
            </> : null }

            { fullscreenActivityProgress ? null : <>
                <h2 className="sub-heading">Students</h2>
                { userStudy.liveSessionStudents ? <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Pseudonym</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        { userStudy.liveSessionStudents.students.map((student, i) => <tr key={i}>
                            <td>{ student.pseudonym } <PseudonymColorBox color={student.pseudonymousColor} /></td>
                            <td>
                                { student.state.sessionVisible ? (student.state.navigatedToLiveSession ? <img className="status-icon invert-dark" src={checkIcon} /> : <img className="status-icon invert-dark" src={bookLibrarySearchIcon} />) : <img className="status-icon invert-dark" src={eyeOffIcon} /> }
                                {
                                    student.state.activityState && (student.state.activityState.type === "interactive" || student.state.activityState.type === "quiz") ? <span className="interactive-quiz-status-details">
                                        stage: { student.state.activityState.stage },
                                        progress: { student.state.activityState.progress },
                                        errors: { student.state.activityState.errors } ({ student.state.activityState.errorsSinceLastProgress }),
                                        solved: { student.state.activityState.solved ? "✓" : "✗" }
                                    </span>: null
                                }
                            </td>
                        </tr>) }
                    </tbody>
                </Table> : <p>No information available.</p> }
            </> }
        </Container>
    </ScrollContainer>;
};

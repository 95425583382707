import React, { useContext } from "react";
import { Button, Container } from "react-bootstrap";
import ScrollContainer from "../../../../hybrid-documents-reader-core/ScrollContainer";
import { UserStudyContext } from "../../../study/UserStudy";

import "./LivePage.scss";

import chemistryIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-626-chemistry-alt.svg";
import networkIcon from '../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-849-computer-network.svg';
import { applicationName } from "../../../ProductInfo";
import { Link, useHistory } from "react-router-dom";
import { LiveTeacherControls } from "./LiveTeacherControls";
import { LiveStudentControls } from "./LiveStudentControls";

export const LivePage: React.FC<Record<string, never>> = () => {
    const userStudy = useContext(UserStudyContext);
    const history = useHistory();

    return (userStudy.liveSessionJoinState === "JOINED" || (userStudy.liveSessionStatus !== null && !userStudy.liveSessionStatus.joinedAsTeacher)) ? (userStudy.liveSessionStatus?.joinedAsTeacher ? <LiveTeacherControls /> : <LiveStudentControls />) : <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="live-session">
            { userStudy.liveSessionJoinState === "NOT_JOINED" ? <div className="simple-status-message">
                You have not joined any live session.<br />
                Look for an access link to join one.
            </div> : userStudy.liveSessionJoinState === "JOINING" ? <div className="simple-status-message">
                Joining Live Session…
            </div> : /* LOCALLY_JOINED */ !userStudy.loggedIn || userStudy.connectionState === "UNENROLLED" ? <>
                <div className="user-study-needed-explainer">
                    <div className="science-icon-container">
                        <img className="science-icon invert-dark" src={chemistryIcon} />
                    </div>
                    <div className="user-study-needed-explainer-content">
                        <h1>One More Step</h1>
                        <p>
                            Live Sessions are part of the <Link to="/study">{applicationName} User Study</Link>.
                            To connect to the Live Session, please join the user study.
                        </p>
                        <div className="float-end">
                            <Button variant="danger" className="reject-join-button" onClick={() => userStudy.leaveLiveSession()}>Cancel</Button>
                            <Button variant="primary" className="join-button" onClick={() => history.push("/study")}>Join User Study</Button>
                        </div>
                    </div>
                </div>
            </> : <>
                <div className="not-connected">
                    <img className="not-connected-icon invert-dark" src={networkIcon} />
                    { userStudy.connectionState !== "DISCONNECTED" ? <p>Connecting…</p> : <p>Not connected.<br />Check your internet connection. <a className="link-action" onClick={() => userStudy.connect()}>Connect?</a><br /></p>}
                </div>
            </> }
        </Container>
    </ScrollContainer>;
};

import { useContext, useEffect, useState } from "react";
import { UserStudyContext } from "./UserStudy";

export const UserStudyVisibilityReporter: React.FC<Record<string, null>> = () => {
    const userStudy = useContext(UserStudyContext);

    const [hidden, setHidden] = useState<boolean>(document.hidden);

    useEffect(() => {
        const handler = () => setHidden(document.hidden);
        document.addEventListener("visibilitychange", handler);
        return () => document.removeEventListener("visibilitychange", handler);
    }, []);

    useEffect(() => {
        if (userStudy.connectionState === "CONNECTED") {
            userStudy.reportEvent({
                type: "SESSION_VISIBILITY_UPDATE",
                visible: !hidden
            });
        }
    // ↓ needed to ensure other changes to not fire this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hidden, userStudy.connectionState]);

    return null;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react';

import "./OopsBoundary.scss";
import { issueReportUrl } from '../src/ProductInfo';
import { Button } from 'react-bootstrap';

type OopsBoundaryState = {
    hasError: boolean;
    error: any;
}

export class OopsBoundary extends React.Component<PropsWithChildren<{}>, OopsBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: {
        name: string;
        message: string;
    }): OopsBoundaryState {
        return { hasError: true, error };
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return <div className="oops">
                <div className="oops-panel pad-navbar">
                    <h1>Oops.</h1>
                    <p><strong>We're sorry, but an error is preventing this application from working correctly.</strong></p>
                    <p>Please report this error <a href={issueReportUrl}>here</a>. Be sure to include a description of what you did before this error occurred, and include the following information:</p>
                    <pre className="oops-details">
                        Name: {this.state.error.name}<br />
                        Message: {this.state.error.message}<br />
                        Object: {JSON.stringify(this.state.error)}
                    </pre>
                    <Button className="float-end" variant="primary" onClick={(): void => window.location.reload()}>Reload Page</Button>
                </div>
            </div>;
        }

        return this.props.children;
    }
}

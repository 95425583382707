import React, { useContext } from "react";
import { Alert, Container } from "react-bootstrap";
import { InteractiveElementBox } from "../../../../hybrid-documents-reader-core/interactive/InteractiveElement";
import { applicationName } from "../../../ProductInfo";
import { UserStudyContext } from "../../../study/UserStudy";
import { ToastContext } from "../../../../hybrid-documents-reader-core/Toaster";
import { OfflineStatus } from "../../settings/OfflineStatus";

import "./LiveStudentControls.scss";
import { PseudonymColorBox } from "./PseudonymColorBox";

export const LiveStudentControls: React.FC<Record<string, never>> = () => {
    const userStudy = useContext(UserStudyContext);
    const toaster = useContext(ToastContext);

    if (!userStudy.liveSessionStatus) return <p>You are not joined to a live session.</p>;

    const activity = userStudy.liveSessionStatus.status.activity;

    return <div className="pad-navbar live-session-student">
        <Container fluid className="live-session-student-container">
            { userStudy.liveSessionJoinState !== "JOINED" ? <div className="connection-lost-container">
                <Alert variant="danger">
                    <b>Connection to live session lost.</b> Check your internet connection, {applicationName} will reconnect automatically.<br />
                    You may continue working, but the session will not update until the connection has been reestablished.
                </Alert>
            </div> : null }
            <div className="current-activity-container">
                { activity === null ? <div className="no-activity">
                    <p><strong>No current activity.</strong></p>
                    <p>
                        When your teacher selects an activity, it will appear here.<br />
                        Feel free to use { applicationName } in the meantime.
                    </p>
                </div> : activity.type === "interactive" ? <>
                    <InteractiveElementBox type="interactive" id={activity.interactiveId} launchOnLoad={true} showDedicatedRouteLink={false} fullHeight={true} visible={true} />
                </> : activity.type === "quiz" ? <>
                    <InteractiveElementBox type="quiz" id={activity.quizId} launchOnLoad={true} showDedicatedRouteLink={false} fullHeight={true} visible={true} />
                </> : <div className="unsupported-activity">
                    <p><strong>Unsupported activity!</strong></p>
                    <p>
                        Something's wrong – you're probably running an older version of pseuCo Book.<br />
                    </p>
                    <OfflineStatus />
                </div> }
            </div>
            <div className="footer">
                <div className="status">
                    Live Session: { userStudy.liveSessionStatus.name } — Pseudonym: { userStudy.liveSessionStatus.pseudonym } <PseudonymColorBox color={userStudy.liveSessionStatus.pseudonymousColor} />
                </div>
                <div className="spreader" />
                <div className="leave">
                    <a className="leave-link" onClick={() => {
                        userStudy.leaveLiveSession();
                        toaster.toast("primary", "Live Session Left", "The live session has been left successfully.", 5000);
                    }}>Leave Live Session</a>
                </div>
            </div>
        </Container>
    </div>;
};

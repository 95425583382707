import React, { useCallback, useEffect } from "react";
import { useState } from "react";

import { select } from 'd3';
import { forceSimulation as d3ForceSimulation, Simulation as d3Simulation, forceLink as d3ForceLink, forceCollide as d3ForceCollide } from 'd3-force';

import "./StudentsPoolViewer.scss";
import { LiveSessionVisualizationOptions } from "../../../../hybrid-documents-reader-core/interactive/InteractiveElement";
import classNames from "classnames";

/**
 * Externally provided data is on a virtual 100 × 100 pixel grid.
 */
export type XY = {
    x: number;
    y: number;
}

export type StudentPointData ={
    key: string;
    color: string;
    engaged: boolean;
    solved: boolean;
    stage: number;
    progress: number;
};

export const StudentsPoolViewer: React.FC<{
    students: StudentPointData[],
    visualizationOptions: LiveSessionVisualizationOptions,
    scale: number
}> = ({ students, visualizationOptions, scale }) => {
    const [svgElement, setSvgElement] = useState<SVGElement | null>(null);
    const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

    const engagedstudentCircleRadius = 12 * scale;
    const disengagedstudentCircleRadius = 8 * scale;

    // stages

    const stages = [...visualizationOptions.stages, { maximalProgress: 1, displayName: "Done" }];

    // resize handling

    const [size, setSize] = useState<XY>({ x: 0, y: 0 });

    useEffect(() => {
        if (!containerElement) return;

        const updateSize = () => {
            setSize({
                x: containerElement.clientWidth,
                y: containerElement.clientHeight
            });
        };
        const observer = new ResizeObserver(updateSize);
        observer.observe(containerElement);
        updateSize();

        return () => {
            observer.disconnect();
        };
    }, [containerElement]);

    // coordinate calculations

    // MMMMM 00000 11111 22222 33333 MMMMM
    //   |           |                 |  
    // margin        |              margin
    //          getCenterForTick(1)       
    //                                    
    //       <---nonMarginWidth---->      
    //       <---> widthPerTick

    const margin = 0;
    const innerMargin = 20;

    const nonMarginWidth = size.x - 2 * margin;
    const neededTicks = stages.reduce<number>((a, stage) => a + stage.maximalProgress, 0);
    const widthPerTick = Math.max(0, (nonMarginWidth - innerMargin * (stages.length - 1)) / neededTicks);
    const leftEdgePositionPerStage = stages.reduce<{ res: number[], offset: number }>(({ res, offset }, stage) => ({ res: [...res, offset], offset: offset + stage.maximalProgress * widthPerTick + innerMargin }), { res: [], offset: margin }).res;
    const getLeftEdge = (stage: number, progress = 0) => leftEdgePositionPerStage[stage] + widthPerTick * Math.min(Math.max(progress, 0), stages[stage].maximalProgress - 1);
    const getCenter = (stage: number, progress: number | null = null) => progress === null ? (getLeftEdge(stage, 0) + 0.5 * widthPerTick * stages[stage].maximalProgress) : (getLeftEdge(stage, progress) + 0.5 * widthPerTick);

    const legendHeight = 50;
    const mainHeight = Math.max(0, size.y - legendHeight);

    const getStudentPosition = (student: StudentPointData): XY => ({ x: student.solved ? getCenter(stages.length - 1, 0) : getCenter(Math.min(Math.max(student.stage, 0), stages.length - 2), student.progress), y: 0.5 * (size.y - legendHeight) + 2 * (Math.random() - 0.5) }); // stages.length - 2: last stage is the "Done" stage, cannot be selected by student


    // representations

    type StudentRepresentation = XY & {
        type: "student";
        student: StudentPointData
    };

    type Target = XY & {
        type: "target";
        fx: number;
        fy: number;
    };

    type StudentTargetLink = {
        source: StudentRepresentation;
        target: Target;
    };

    const getRadiusForStudentRepresentation = useCallback((s: StudentRepresentation): number => s.student.engaged ? engagedstudentCircleRadius : disengagedstudentCircleRadius, [disengagedstudentCircleRadius, engagedstudentCircleRadius]);

    const [studentRepresentations, setStudentRepresentations] = useState<StudentRepresentation[]>([]);
    const [links, setLinks] = useState<StudentTargetLink[]>([]);
    const [targets, setTargets] = useState<Target[]>([]);

    useEffect(() => {
        const survivors: StudentRepresentation[] = [];
        studentRepresentations.forEach((r) => {
            const student = students.find((s) => s.key === r.student.key);
            if (student) {
                survivors.push(r);
                r.student = student; // link to new student data
            }
        });

        const newborns: StudentRepresentation[] = [];
        students.forEach((s) => {
            if (!survivors.some((r) => s === r.student)) { // can compare objects now as we updated the survivors already
                newborns.push({
                    type: "student",
                    student: s,
                    x: -50, // start out of bounds, to the left
                    y: 0
                });
            }
        });

        const newStudentRepresentations = [...survivors, ...newborns];
        setStudentRepresentations(newStudentRepresentations);

        const targets: Target[] = [];
        setLinks(newStudentRepresentations.map((r) => {
            const { x, y } = getStudentPosition(r.student);
            const target: Target = {
                type: "target",
                x: x,
                y: y,
                fx: x,
                fy: y
            };
            targets.push(target);
            return {
                source: r,
                target
            };
        }));
        setTargets(targets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [students, size]);

    // forces

    const [forceSimulation] = useState<d3Simulation<StudentRepresentation | Target, StudentTargetLink>>(() => {
        const forceSimulation = d3ForceSimulation<StudentRepresentation | Target, StudentTargetLink>();
        forceSimulation.alpha(1);
        forceSimulation.alphaDecay(0.01);
        forceSimulation.velocityDecay(0.05);
        return forceSimulation;
    });

    useEffect(() => {
        forceSimulation.restart();
        return () => {
            forceSimulation.stop();
        };
    }, [forceSimulation]);

    useEffect(() => {
        forceSimulation.nodes([...studentRepresentations, ...targets]);
        forceSimulation.restart();
    }, [forceSimulation, studentRepresentations, targets]);

    const [linkForce] = useState(() => d3ForceLink<StudentRepresentation | Target, StudentTargetLink>().distance(0).strength(0.1));
    useEffect(() => {
        linkForce.links(links);
    }, [linkForce, links]);

    const [collisionForce] = useState(() => d3ForceCollide<StudentRepresentation | Target>().strength(1));

    useEffect(() => {
        collisionForce.radius((d) => d.type === "student" ? (getRadiusForStudentRepresentation(d)) : 0 );
    }, [collisionForce, getRadiusForStudentRepresentation]);

    // register forces
    useEffect(() => {
        forceSimulation.force("link", linkForce);
        forceSimulation.force("collision", collisionForce);
    }, [forceSimulation, linkForce, collisionForce]);

    // re-heat simulation as needed
    useEffect(() => {
        forceSimulation.alpha(1);
        forceSimulation.restart();
    }, [forceSimulation, studentRepresentations, links]);

    // SVG updates

    const updatePositions = useCallback(() => {
        if (!svgElement) return;
        const studentsSelection = select(svgElement).select(".students").selectAll<SVGCircleElement, StudentRepresentation>(".student");
        studentsSelection.attr('transform', (d) => `translate(${d.x} ${d.y})`);
    }, [svgElement]);

    useEffect(() => {
        if (!svgElement) return;
        const studentsSelection = select(svgElement).select(".students").selectAll<SVGCircleElement, StudentRepresentation>(".student.alive");

        studentsSelection
            .data(studentRepresentations, (d) => d.student.key)
            .join<SVGCircleElement>((enter) => {
            const newElements = enter.append("circle")
                .classed("student alive", true)
                .attr("r", getRadiusForStudentRepresentation);
            newElements.attr('opacity', 0.0).transition("fade-in").duration(1000).attr('opacity', 1.0);
            return newElements;
        }, (update) => {
            update.transition().duration(250).attr("r", getRadiusForStudentRepresentation);
            return update;
        }, (exit) => {
            exit.classed("alive", false).transition("fade-out").duration(1000).attr("opacity", 0.0).remove();
        }).style("fill", (d) => d.student.color);

        updatePositions();
    }, [getRadiusForStudentRepresentation, studentRepresentations, svgElement, updatePositions]);

    useEffect(() => {
        forceSimulation.on("tick.update-positions", updatePositions);
        return () => {
            forceSimulation.on("tick.update-positions"); // unregister listener
        };
    }, [forceSimulation, updatePositions]);

    // return a wrapping div element because ResizeObserver observes the bounding box for SVG elements (https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver) which does not change on resize

    return <div className="students-pool-viewer" ref={setContainerElement}>
        <svg className="pool-svg" ref={setSvgElement}>
            <g className="stages">
                { stages.map((stage, i) => <g key={i} className={classNames({ stage: true, finish: i === stages.length - 1 })}>
                    <rect className="stage-box" x={getLeftEdge(i)} width={widthPerTick * stage.maximalProgress} y={0} height={mainHeight} />
                    <line className="stage-line" x1={getLeftEdge(i)} y1={mainHeight} x2={getLeftEdge(i) + widthPerTick * stage.maximalProgress} y2={mainHeight} />
                    <text className="stage-label" x={getCenter(i)} y={mainHeight} dy={"1.2em"}>{ stage.displayName }</text>
                </g>) }
            </g>
            <g className="students" />
        </svg>
    </div>;
};

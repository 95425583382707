import React, { useContext } from "react";
import { applyAvailableUpdateNow } from "../../../hybrid-documents-reader-core/OfflineManagement";
import { Alert } from "react-bootstrap";
import { applicationName } from "../../ProductInfo";
import { OfflineStatusContext } from "../../../hybrid-documents-reader-core/OfflineStatusContext";

export const OfflineStatus: React.FC<{}> = () => {
    const status = useContext(OfflineStatusContext);

    return <div>
        {
            status.updateReady ? <Alert variant="primary">An update is available and will be applied automatically when all tabs of {applicationName} have been closed. <strong><a className="cursor-pointer" onClick={applyAvailableUpdateNow}>Update Now</a></strong></Alert> :
                status.updateApplying ? <Alert variant="primary">Applying update…</Alert> :
                    null
        }
    </div>;
};

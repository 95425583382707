// image wrapper module
import imgVar0 from './img/img001.svg';
import imgVar1 from './img/img002.svg';
import imgVar2 from './img/img003.svg';
import imgVar3 from './img/img004.svg';
import imgVar4 from './img/img005.svg';
import imgVar5 from './img/img006.svg';
import imgVar6 from './img/img007.svg';
import imgVar7 from './img/img008.svg';
import imgVar8 from './img/img009.svg';
import imgVar9 from './img/img010.svg';
import imgVar10 from './img/img011.svg';
import imgVar11 from './img/img012.svg';
import imgVar12 from './img/img013.svg';
import imgVar13 from './img/img014.svg';
import imgVar14 from './img/img015.svg';
import imgVar15 from './img/img016.svg';
import imgVar16 from './img/img017.svg';
import imgVar17 from './img/img018.svg';
import imgVar18 from './img/img019.svg';
import imgVar19 from './img/img020.svg';
import imgVar20 from './img/img021.svg';
import imgVar21 from './img/img022.svg';
import imgVar22 from './img/img023.svg';
import imgVar23 from './img/img024.svg';
import imgVar24 from './img/img025.svg';
import imgVar25 from './img/img026.svg';
import imgVar26 from './img/img027.svg';
import imgVar27 from './img/img028.svg';
import imgVar28 from './img/img029.svg';
import imgVar29 from './img/img030.svg';
import imgVar30 from './img/img031.svg';
import imgVar31 from './img/img032.svg';
import imgVar32 from './img/img033.svg';
import imgVar33 from './img/img034.svg';
import imgVar34 from './img/img035.svg';
import imgVar35 from './img/img036.svg';
import imgVar36 from './img/img037.svg';
import imgVar37 from './img/img038.svg';

const images: {[imageName: string]: string} = {
    "img001": imgVar0,
    "img002": imgVar1,
    "img003": imgVar2,
    "img004": imgVar3,
    "img005": imgVar4,
    "img006": imgVar5,
    "img007": imgVar6,
    "img008": imgVar7,
    "img009": imgVar8,
    "img010": imgVar9,
    "img011": imgVar10,
    "img012": imgVar11,
    "img013": imgVar12,
    "img014": imgVar13,
    "img015": imgVar14,
    "img016": imgVar15,
    "img017": imgVar16,
    "img018": imgVar17,
    "img019": imgVar18,
    "img020": imgVar19,
    "img021": imgVar20,
    "img022": imgVar21,
    "img023": imgVar22,
    "img024": imgVar23,
    "img025": imgVar24,
    "img026": imgVar25,
    "img027": imgVar26,
    "img028": imgVar27,
    "img029": imgVar28,
    "img030": imgVar29,
    "img031": imgVar30,
    "img032": imgVar31,
    "img033": imgVar32,
    "img034": imgVar33,
    "img035": imgVar34,
    "img036": imgVar35,
    "img037": imgVar36,
    "img038": imgVar37,
};

export const getImage = (imageName: string): string => {
    return images[imageName];
};
            
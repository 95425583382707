import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { initializeOfflineManagement } from '../hybrid-documents-reader-core/OfflineManagement';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

initializeOfflineManagement();

// auth token management
try {
    const urlMatch = /^\/(\w+)\//.exec(window.location.pathname);
    if (urlMatch) {
        const authTokenFromURL = urlMatch[1];
        window.localStorage.setItem("auth-token", authTokenFromURL);
    }
} catch (e) {
    // ignore
}

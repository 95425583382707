const byteToHex: string[] = [];

for (let i = 0; i < 256; ++i) {
    byteToHex.push((i + 0x100).toString(16).substr(1));
}

export const getRandomByteString = (bytes: number): string => {
    const array = new Uint8Array(bytes);
    window.crypto.getRandomValues(array);
    return [...array].map((b) => byteToHex[b]).join('');
};

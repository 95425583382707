import React, { PropsWithChildren, useContext } from "react";
import { createContext } from "react";
import { useHistory } from "react-router-dom";

export type linkNavigation = (target: string) => void;

/**
 * This context provides a simple method to navigate somewhere.
 * The idea is that this is simply a history.push, but allows this behaviour to be overridden.
 * The [[ReadView]] can use this to capture links to the currently scrolled-to section, allowing it to indicate this situation to the user.
 */
export const LinkNavigationSupportContext = createContext<linkNavigation>(() => {
    throw new Error("linkNavigation helper cannot be used outside of a LinkNavigationSupportContext");
});

export const LinkNavigationSupportContextBasicProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const history = useHistory();
    return <LinkNavigationSupportContext.Provider value={(target) => {
        history.push(target);
    }}>
        { children }
    </LinkNavigationSupportContext.Provider>;
};

export const InternalNavigationLink: React.FC<PropsWithChildren<{ to: string }>> = ({ to, children }) => {
    const linkNavigation = useContext(LinkNavigationSupportContext);
    return <a href={`#${to}`} onClick={() => linkNavigation(to)}>{ children }</a>;
};
import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { LiveSessionTeacherData } from "../../../../../pseuco-book-server/src/types";
import ScrollContainer from "../../../../hybrid-documents-reader-core/ScrollContainer";
import { getJoinLinkForLiveSession, UserStudyContext } from "../../../study/UserStudy";
import { ToastContext } from "../../../../hybrid-documents-reader-core/Toaster";

import binIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-17-bin.svg";
import refreshIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-82-refresh.svg";

import "./LiveTeachPage.scss";
import { useHistory } from "react-router";

const LiveSessionManager: React.FC<{}> = () => {
    const userStudy = useContext(UserStudyContext);
    const history = useHistory();

    const toaster = useContext(ToastContext);

    const [liveSessions, setLiveSessions] = useState<LiveSessionTeacherData[]>([]);
    const [liveSessionsUpdating, setLiveSessionsUpdating] = useState<boolean>(false);

    const [initialized, setInitialized] = useState<boolean>(false);

    const updateLiveSessions = useCallback(async () => {
        if (liveSessionsUpdating) return;
        setLiveSessionsUpdating(true);
        try {
            setLiveSessions(await userStudy.listLiveSessions());
        } finally {
            setLiveSessionsUpdating(false);
        }
    }, [userStudy, liveSessionsUpdating]);
    useEffect(() => {
        if (!initialized) {
            updateLiveSessions();
            setInitialized(true);
        }
    }, [updateLiveSessions, initialized]);

    const [sessionCreating, setSessionCreating] = useState<boolean>(false);
    const [newSessionName, setNewSessionName] = useState<string>("");

    const createSession = async () => {
        setSessionCreating(true);
        try {
            await userStudy.createLiveSession(newSessionName);
            setNewSessionName("");
            updateLiveSessions();
        } finally {
            setSessionCreating(false);
        }
    };

    const [sessionDeleting, setSessionDeleting] = useState<boolean>(false);

    const deleteSession = async (session: LiveSessionTeacherData) => {
        setSessionDeleting(true);
        try {
            await userStudy.deleteLiveSession(session.id);
            updateLiveSessions();
        } finally {
            setSessionDeleting(false);
        }
    };

    const joinSession = (session: LiveSessionTeacherData) => {
        userStudy.joinLiveSession(session.id, true);
        history.push("/live");
    };

    const copyJoinLink = async (id: string) => {
        await navigator.clipboard.writeText(getJoinLinkForLiveSession(id));
        toaster.toast("success", "Link Copied to Clipboard", "Send this link to students to invite them.", 5000);
    };

    return <>
        <h1>Manage Live Sessions</h1>
        <h2>Existing Live Sessions</h2>
        <p>
            You are currently hosting the following live sessions:
            <Button size="sm" className="update-button" onClick={updateLiveSessions} disabled={liveSessionsUpdating}><img className="refresh-icon" src={refreshIcon} /></Button>
        </p>
        <ListGroup as="ul" className="live-session-list">
            { liveSessions.length > 0 ? liveSessions.map((ls, i) => <ListGroup.Item key={i} as="li" action={true} className="pointer" onClick={() => joinSession(ls)}>
                <div>
                    <strong>{ ls.name }</strong>
                    <span className="float-end">
                        <Button size="sm" variant="danger" disabled={sessionDeleting} onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            deleteSession(ls);
                        }}><img className="delete-icon" src={binIcon} /></Button>
                    </span>
                </div>
                <div>
                    Join Link: <code>{ getJoinLinkForLiveSession(ls.id) }</code> (<a className="pointer" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.stopPropagation();
                        copyJoinLink(ls.id);
                    }}>Copy</a>)
                </div>
            </ListGroup.Item>) : <ListGroup.Item disabled className="no-sessions" as="li">
                No Live Sessions Found
            </ListGroup.Item> }
        </ListGroup>
        { userStudy.liveSessionId === null ? <p>Select a live session to join it.</p> : <p>You are already joined to a live session. You can <a onClick={userStudy.leaveLiveSession} className="pointer">leave it</a>, or click any live session to join it.</p> }

        <h2>Create Live Session</h2>
        <Form>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    Name
                </Form.Label>
                <Col sm={10}>
                    <Form.Control disabled={sessionCreating} value={newSessionName} onChange={(e: React.ChangeEvent<HTMLInputElement> ) => setNewSessionName(e.target.value)} placeholder="Lecture 42" />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                    <Button type="submit" onClick={createSession} disabled={sessionCreating || newSessionName.length <= 0}>Create Session</Button>
                </Col>
            </Form.Group>
        </Form>
    </>;
};

export const LiveTeachPage: React.FC<{}> = () => {
    const userStudy = useContext(UserStudyContext);

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="live-session-teach">
            { userStudy.userRecord?.enrolled && userStudy.userRecord?.teacher ? <LiveSessionManager /> : <div className="teach-disabled">
                <div>— Teacher controls unavailable —</div>
                <div>Make sure you are connected to the user study and logged in as a teacher.</div>
            </div> }
        </Container>
    </ScrollContainer>;
};

/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState, useContext, useImperativeHandle, forwardRef, useMemo, useCallback } from 'react';

import interactiveElementDefinitions from "../../src/interactive/interactive";
import { Button } from 'react-bootstrap';

import "./InteractiveElement.scss";
import { Link } from 'react-router-dom';
import GlobalSettingsContext from '../../src/GlobalSettings';
import { createQuizComponent } from './Quiz';
import { getBook } from '../../hybrid-documents-reader-core/book/Book';
import { localStoragePrefix } from '../../src/ProductInfo';
import { OopsBoundary } from '../OopsBoundary';

import fullscreenIcon from '../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-216-fullscreen.svg';
import { UserStudyContext } from '../../src/study/UserStudy';
import { NotFoundPage } from '../pages/notfound/NotFoundPage';
import { KeyDownEventBarrier } from '../../pseuco-shared-components/ui/KeyboardEventBarrier';

export type InteractiveComponent = React.FunctionComponent<{
    state: any;
    setState: (state: any) => void;
    markAsSolved: () => void;
    id: string;
}>;

export type LiveSessionVisualizationOptions = {
    stages: {
        maximalProgress: number;
        displayName: string;
    }[];
};

export type InteractiveElementDefinition = {
    title: string;
    height?: number;
    getComponent: () => Promise<InteractiveComponent>;
    liveSessionVisualizationOptions?: LiveSessionVisualizationOptions
}

export type InteractiveElementBoxAPI = {
    loaded: boolean;
};

export const InteractiveElementLoader: React.FC<{
    component: InteractiveComponent;
    type: "interactive" | "quiz";
    id: string;
}> = ({ component: Component, type, id }) => {
    const settings = useContext(GlobalSettingsContext);
    const userStudy = useContext(UserStudyContext);

    const saveKey = getSaveKeyForInteractive(type, id);

    const [state, setState] = React.useState<any>(() => {
        try {
            const stateString = localStorage.getItem(`${localStoragePrefix}interactive-state-${saveKey}`);
            if (stateString) return JSON.parse(stateString);
        } catch (e) {
            return null;
        }
    });

    const setAndPersistState = useCallback((state: any): void => {
        localStorage.setItem(`${localStoragePrefix}interactive-state-${saveKey}`, JSON.stringify(state));
        // wrapped in timeout to avoid setting state in another component during render (as this is expected to be called during renders)
        // see https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
        setTimeout(() => setState(state));
    }, [saveKey]);

    const [solvedReportSent, setSolvedReportSent] = useState<boolean>(false);
    useEffect(() => {
        setSolvedReportSent(false); // reset flag on exercise change
    }, [type, id]);

    const markAsSolved = useMemo((): () => void => {
        let fired = false;
        return () => {
            if (!fired) {
                fired = true;
                if (!solvedReportSent) {
                    setTimeout(() => {
                        setSolvedReportSent(true);
                        userStudy.reportEvent({ type: "INTERACTIVE_REPORT_SOLVED", interactiveType: type, interactiveId: id });
                    });
                }
                if (!settings.current.interactiveCompletion[saveKey]) {
                    setTimeout(() => {
                        // wrapped in timeout to avoid setting state in another component during render (as this is expected to be called during renders)
                        // see https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
                        settings.set({ ...settings.current, interactiveCompletion: {...settings.current.interactiveCompletion, [saveKey]: true} });
                        if (userStudy.shouldAskForEnrollment) userStudy.showEnrollmentCTA();
                        if (type === "interactive" && userStudy.connectionState === "CONNECTED") userStudy.showInteractiveFeedbackSurvey(id);
                    });
                }
            }
        };
    }, [id, saveKey, settings, solvedReportSent, type, userStudy]);

    return <OopsBoundary>
        <Component state={state} setState={setAndPersistState} markAsSolved={markAsSolved} id={id} />
    </OopsBoundary>;
};

export const getSaveKeyForInteractive = (type: string, id: string): string => `${type}-${id}`;

type InteractiveElementBoxProps = {
    type: "interactive" | "quiz";
    id: string;
    htmlId?: string;
    replacementText?: string;
    launchOnLoad: boolean;
    showDedicatedRouteLink: boolean;
    fullHeight: boolean;
    className?: string;

    /**
     * Specifies whether the InteractiveElementBox is visible or scrolled out of view.
     * Used for user study reporting.
     * May also be used for performance optimizations, to disable animations while they are out of view.
     */
    visible?: boolean;

    /**
     * Called when loading has finished.
     * Changing this causes a reload.
     */
    onLoaded?: () => void;
};

export const InteractiveElementBox = forwardRef<InteractiveElementBoxAPI, InteractiveElementBoxProps>(function InteractiveElementBox ({ type, id, htmlId, replacementText, launchOnLoad, showDedicatedRouteLink, fullHeight, visible, onLoaded, className }, ref) {
    const settings = useContext(GlobalSettingsContext);
    const userStudy = useContext(UserStudyContext);

    const saveKey = getSaveKeyForInteractive(type, id);

    const [component, setComponent] = useState<{ // stores the loaded type and id to avoid re-loading if only other props change
        component: InteractiveComponent,
        type: "interactive" | "quiz",
        id: string
    } | null>(null);
    const [title, setTitle] = useState<string>("");
    const [height, setHeight] = useState<number | undefined>(200);
    const [expanded, setExpanded] = useState<boolean>(launchOnLoad);

    useEffect(() => {
        if (expanded) {
            userStudy.reportEvent({ type: "INTERACTIVE_SESSION_START", interactiveType: type, interactiveId: id, solved: !!settings.current.interactiveCompletion[saveKey] });
            return () => {
                userStudy.reportEvent({ type: "INTERACTIVE_SESSION_END", interactiveType: type, interactiveId: id });
            };
        }
    // ↓ needed to ensure other changes to not fire this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, expanded, userStudy.connectionState]);

    useEffect(() => {
        if (expanded && visible !== undefined) {
            userStudy.reportEvent({ type: "INTERACTIVE_VISIBILITY_UPDATE", interactiveType: type, interactiveId: id, visible });
        }
    // ↓ needed to ensure other changes to not fire this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, visible, expanded, userStudy.connectionState]);

    useEffect(() => {
        if (component && component.type === type && component.id === id) return; // loaded already, only other props changed
        setComponent(null);
        setTitle("");
        setHeight(undefined);

        let cancel = false; // fixes races where the component navigates while the element is loading

        switch (type) {
            case "interactive": {
                const definition = interactiveElementDefinitions[id];
                
                if (definition !== undefined) {
                    setTitle(definition.title);
                    setHeight(definition.height);
                    (async (): Promise<void> => {
                        const component = await definition.getComponent();
                        if (cancel) return;
                        setComponent({ component, type, id });
                        onLoaded?.();
                    })();
                } else {
                    setTitle("Not Found");
                    setComponent({ component: NotFoundPage, type, id });
                }
                break;
            }
            case "quiz": {
                (async (): Promise<void> => {
                    const book = await getBook();
                    if (cancel) return;
                    const quiz = book.quizzes[id];

                    if (quiz) {
                        setTitle(quiz.title);
                        const quizComponent = createQuizComponent(quiz, id);
                        setComponent({ component: quizComponent, type, id });
                    } else {
                        setComponent({ component: NotFoundPage, type, id });
                    }
                })();
                break;
            }
            default: throw new Error(`Unknown interactive type: ${type}`);
        }
        return (): void => {
            cancel = true;
        };
    }, [type, id, onLoaded, component]);

    useImperativeHandle(ref, (): InteractiveElementBoxAPI => ({
        loaded: !!component
    }));

    const [onLoadedCalled, setOnLoadedCalled] = useState(false);
    useEffect(() => {
        if (component && !onLoadedCalled) {
            setOnLoadedCalled(true);
            onLoaded?.();
        }
    }, [component, onLoaded, onLoadedCalled]);

    return <div id={htmlId} className={`interactive-box ${className ?? ""}`} style={fullHeight || height === undefined ? { height: "100%" } : {}}>
        <div className="titlebar">
            <span className="title">{ title }</span>
            {settings.current.interactiveCompletion[saveKey] ? <span className="completed-indicator">✓</span> : null}
            <div className="spacer" />
            {showDedicatedRouteLink ? <Link to={`/${type}/${id}`} className="fullscreen-link"><Button variant="secondary" size="sm"><img style={{height: "16px"}} src={fullscreenIcon} /></Button></Link> : null}
        </div>
        {!(component && component.type === type && component.id === id) ? <div className="interactive-element-placeholder">Loading...</div> :
            !expanded ? <div className="interactive-element-placeholder">
                <p>{ replacementText }</p>
                <Button variant="primary" className="float-end" onClick={(): void => setExpanded(true)}>Go</Button>
                <div className="clearfix" />
            </div> : <div className="content" style={fullHeight || height === undefined ? {} : { height: `${height}px` }}>
                <KeyDownEventBarrier>
                    <InteractiveElementLoader component={component.component} type={type} id={id} />
                </KeyDownEventBarrier>
            </div>
        }
    </div>;
});

import React, { useEffect, useState } from "react";
import { applicationName } from "../ProductInfo";

import chemistryIcon from "../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-626-chemistry-alt.svg";

import "./UserStudyCallToAction.scss";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router";

export const UserStudyCallToAction: React.FC<{}> = () => {
    return <div className="user-study-call-to-action">
        <div className="science-icon-container">
            <img className="science-icon invert-dark" src={chemistryIcon} />
        </div>
        <div className="user-study-call-to-action-content">
            <h1>Help Us Make Science!</h1>
            <p>
                We want to measure the impact of {applicationName} and make it better. Please help us by joining the <strong>{applicationName} User Study</strong>. All students of <em>Concurrent Programming</em> at Saarland University are eligible to participate.
            </p>
            <p>
                If you join, you'll not only help us make {applicationName} more useful for future generations – you'll also be able to join <strong>Live Sessions</strong> during class, automatically giving live feedback to your teachers to help them make the lecture better for you.
            </p>
        </div>
    </div>;
};

/**
 * Renders a post-exercise-completion enrollment CTA.
 */
export const UserStudyEnrollmentCallToActionModal: React.FC<{ show: {} | null }> = ({ show }) => {
    const history = useHistory();

    const [state, setState] = useState<{} | null>(null);

    useEffect(() => {
        setState(show === null ? null : {});
    }, [show]); // reset state whenever show is updated

    return <Modal className="enroll-cta-modal" show={state !== null} onHide={() => setState(null)}>
        <Modal.Header closeButton>
            <Modal.Title>Exercise Solved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="congratulations">
                <div className="emoji">🎉</div>
                <div className="label">Well done!</div>
            </div>
            <p>
                Please help us to make this exercise and {applicationName} better by joining the <strong>{applicationName} User Study</strong>. All students of <em>Concurrent Programming</em> at Saarland University are eligible to participate.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setState(null)}>Not Now</Button>
            <Button variant="primary" onClick={() => {
                setState(null);
                history.push("/study");
            }}>Join User Study</Button>
        </Modal.Footer>
    </Modal>
    ;
};

export const UserStudyMiniCTA: React.FC<{}> = () => {
    const history = useHistory();

    return <div className="user-study-mini-cta">
        Please join our User Study to help us improve {applicationName}!
        <a className="join-link" onClick={ () => history.push("/study") }>More Info</a>
    </div>;
};

import React, { useState, useEffect, PropsWithChildren } from 'react';
import { localStoragePrefix } from './ProductInfo';
import { mapObject } from '../pseuco-shared-components/util/functional';

type Theme = 'light' | 'dark';

type Optional<ValueType> = null | { value: ValueType };

type Extractor<ValueType> = (untrustedValue: unknown) => Optional<ValueType>;

const orNullExtractor = <T,>(valueExtractor: Extractor<T>): Extractor<T | null> => (value: unknown): Optional<T | null> => value === null ? { value } : valueExtractor(value);

const booleanExtractor: Extractor<boolean> = (value: unknown): Optional<boolean> => typeof value === "boolean" ? { value } : null;
const stringExtractor: Extractor<string> = (value: unknown): Optional<string> => typeof value === "string" ? { value } : null;
const enumExtractor = <EnumType extends string>(allowedValues: EnumType[]): Extractor<EnumType> => (value: unknown): Optional<EnumType> => typeof value === "string" && allowedValues.includes(value as EnumType) ? { value: value as EnumType } : null;
const objectExtractor = <ValueType,>(valueExtractor: Extractor<ValueType>): Extractor<{ [key: string]: ValueType }> => (value: unknown): Optional<{ [key: string]: ValueType }> => {
    if (typeof value === "object" && value !== null) {
        try {
            return { value: mapObject(value as { [key: string]: unknown }, (value) => {
                const extraction = valueExtractor(value);
                if (extraction === null) throw new Error("Could not extract inner value.");
                return extraction.value;
            })};
        } catch (e) {
            return null; // if one key fails to extract, fail whole extraction
        }
    } else {
        return null;
    }
};

// ▽▽▽ settings definitions ▽▽▽

type GlobalSettings = {
    theme: Theme;
    followSystemDarkMode: boolean;
    showDebugPage: boolean;
    enableUserStudy: boolean;
    userStudyAuthenticationToken: string | null;
    userStudyForceOfficialServer: boolean;
    userStudyLiveSessionId: string | null;
    userStudyLiveSessionValidated: boolean;
    userStudyLiveSessionJoinAsTeacher: boolean;
    userStudySurveySaveState: string | null;
    interactiveCompletion: { [interactiveId: string]: boolean };
}

type SettingKey = keyof GlobalSettings;

const defaultValues: {
    [settingKey in SettingKey]: GlobalSettings[settingKey];
} = {
    theme: "light",
    followSystemDarkMode: true,
    showDebugPage: false,
    enableUserStudy: false,
    userStudyAuthenticationToken: null,
    userStudyForceOfficialServer: false,
    userStudyLiveSessionId: null,
    userStudyLiveSessionValidated: false,
    userStudyLiveSessionJoinAsTeacher: false,
    userStudySurveySaveState: null,
    interactiveCompletion: {}
};

const extractors: {
    [settingKey in SettingKey]: Extractor<GlobalSettings[settingKey]>;
} = {
    theme: enumExtractor(["light", "dark"]),
    followSystemDarkMode: booleanExtractor,
    showDebugPage: booleanExtractor,
    enableUserStudy: booleanExtractor,
    userStudyAuthenticationToken: orNullExtractor(stringExtractor),
    userStudyForceOfficialServer: booleanExtractor,
    userStudyLiveSessionId: orNullExtractor(stringExtractor),
    userStudyLiveSessionValidated: booleanExtractor,
    userStudyLiveSessionJoinAsTeacher: booleanExtractor,
    userStudySurveySaveState: orNullExtractor(stringExtractor),
    interactiveCompletion: objectExtractor(booleanExtractor)
};

// △△△ settings definitions △△△

type GlobalSettingsContextData = {
    current: GlobalSettings;
    set: (newSettings: GlobalSettings) => void;
};

const GlobalSettingsContext = React.createContext<GlobalSettingsContextData>({
    current: defaultValues,
    set: (): never => { throw new Error("Setting global settings outside of a provider."); }
});
export default GlobalSettingsContext;

export const GlobalSettingsProvider: React.FC<PropsWithChildren<{}>> = (props) => {
    const previousSettings = defaultValues;

    const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
    if (!settingsLoaded) {
        // we're just booting up, so we need to grab the state from local storage to initialize React state with proper values
        try {
            const settingsString = localStorage.getItem(`${localStoragePrefix}settings`);
            if (settingsString !== null) {
                const settingsFromStorage = JSON.parse(settingsString);
    
                // restore properties on-by-one if they match expected types
                if (typeof settingsFromStorage === "object" && settingsFromStorage !== null) {
                    const restorableKeys = Object.keys(previousSettings) as SettingKey[];
                    restorableKeys.forEach((key: SettingKey) => {
                        const extraction = extractors[key](settingsFromStorage[key]);
                        if (extraction !== null) (previousSettings[key] as unknown) = extraction.value; // cast needed as TS does not understand the key ensures the type is right
                    });
                }
            } else {
                console.log("No settings found, using default settings.");
            }
        } catch (e) {
            console.log(`Cannot load settings: ${e}`);
        }

        setSettingsLoaded(true);
    }

    const [settings, setSettings] = useState<GlobalSettings>(previousSettings);

    useEffect(() => {
        try {
            localStorage.setItem(`${localStoragePrefix}settings`, JSON.stringify(settings));
        } catch (e) {
            console.log(`Cannot persist settings: ${e}`);
        }
    });
    
    return <GlobalSettingsContext.Provider value={{
        current: settings,
        set: setSettings
    }}>
        {props.children}
    </GlobalSettingsContext.Provider>;

};

import React from "react";
import { applicationName } from "../ProductInfo";

export const UserStudyPrivacyPolicy: React.FC<Record<string, never>> = () => {
    return <div>
        <h4>Name and Address of the Data Controller</h4>
        <p>The data controller as defined by the General Data Protection Regulation, national data protection laws of member states, and other data protection regulations is:</p>
        <p>
            Universität des Saarlandes<br />
            Prof. Dr.-Ing. Holger Hermanns<br />
            Department of Computer Science<br />
            Gebäude E1 3, Raum 501<br />
            66123 Saarbrücken<br />
            Germany
        </p>
        <p>
            E-Mail: <a href="mailto:privacy@depend.uni-saarland.de">privacy@depend.uni-saarland.de</a><br />
            Phone: +49 681 302 5631
        </p>
        <p>
            Universität des Saarlandes is represented by its University President; Address: Campus, 66123 Saarbrücken, Germany; E-Mail: <a href=",mailto:postzentrale@uni-saarland.de">postzentrale@uni-saarland.de</a>; Phone: +49 681 302 0

        </p>

        <h4>Contact details for the data protection officer </h4>
        <p>
            Meerwiesertalweg 15<br />
            66123 Saarbrücken<br />
            Germany
        </p>
        <p>
            E-Mail: <a href="mailto:datenschutz@uni-saarland.de">datenschutz@uni-saarland.de</a><br />
            Phone: +49 681 302 2813<br />
            Website: <a href="https://www.uni-saarland.de/en/privacy.html">https://www.uni-saarland.de/en/privacy.html</a>
        </p>

        <h4>Purpose and Scope of Processing Personal Data</h4>
        <p>
            The {applicationName} User Study aims to evaluate the effectiveness of {applicationName} and the interactive exercises therein.
            To this end, we collect data that enables us to reconstruct usage patterns and corellate usage of certain parts of {applicationName} to survey results and indicators of academic performance.
        </p>

        <h5>User Record</h5>
        <p>
            We store a limited amount of personal information, including a unique identifier for you, when you log in to {applicationName} user study.
            This enables us to recognize you and to administer the study.
        </p>
        
        <h5>{applicationName} Interaction Logs</h5>
        <p>
            When you use {applicationName} while being logged in and enrolled into the user study, we collect detailed logs of your interactions with {applicationName}.
            This enables us to measure how much time you spend in which parts of {applicationName} and how you interact with it.
        </p>
        
        <h5>Live Sessions</h5>
        <p>
            If you are enrolled in {applicationName} User Study, during certain parts of the <em>Concurrent Programming</em> module, you may be asked to join a <em>live session</em>.
            During such a live session, your instructor will be able to send you exercises and see a brief summary of your and everyone's progress within these exercises, e.g. how many students are actively working on an exercise and in which part of the exercise they are.
            This enables the instructor to adapt to the overall progress of the students.
            Any information the instructor receives is pseudonymous, i.e. your instructor will not be able to link this information to you unless you share your pseudonym or anything that uniquely identifies you with them.
        </p>

        <h5>Survey Results</h5>
        <p>
            At certain points during your use of {applicationName}, we will show surveys containing questions about the feature you just used.
            These surveys enable us to directly evaluate certain aspects (e.g. measure whether exercises are perceived as confusing) and look for correlations with other factors (e.g. whether students who find an exercise to be easy perform better in similar exercises later on).
        </p>

        <h5>Grades</h5>
        <p>
            We will also retrieve the grades you obtained in the <em>Concurrent Programming</em> module.
            We do so to corellate any of the aforementioned data types to your performance in the module.
        </p>

        <h5>Analysis Results</h5>
        <p>
            All data collected will be analysed for research purposes as explained above.
            These analyses will be based on an aggregation of multiple data sets, making them fully anonymous (i.e. not contain any individual-related data).
            Therefore, the GDPR does not apply to this data.
            Analysis results may be published worldwide and indefinitely as scientific contributions.
        </p>

        <h4>Legal Basis for Processing Personal Data</h4>
        <p>
            If you enroll into {applicationName} User Study, the legal basis for collecting and processing your data is Art. 6(1)(a) GDPR, i.e. your explicit consent to contribute your data as described in this privacy statement.
        </p>
        <p>
            For your grades, this only concerns storage as part of {applicationName} User Study.
            Storage in dCMS for the purposes of running the <em>Concurrent Programming</em> module and to comply with archival requirements of examination regulation is not part of {applicationName} User Study and out of scope for this privacy policy.
        </p>

        <h4>Description and Scope of Data Processing</h4>
        <h5>User Record</h5>
        <p>
            When you enroll and sign in to the {applicationName} User Study, we store
        </p>
        <ul>
            <li>a personal identifier linking your identity to your dCMS account,</li>
            <li>an additional <em>pseudonymization identifier</em> for you,</li>
            <li>your full name, and</li>
            <li>that you enrolled in the study.</li>
        </ul>
        
        <h5>{applicationName} Interaction Logs</h5>
        <p>
            When you use {applicationName} while being logged in and enrolled into the user study, we record events that enable us to reconstruct your usage of {applicationName}. For example, we record an event whenever you
        </p>
        <ul>
            <li>connect to or disconnect from the user study service (i.e. open or close {applicationName} or lose or gain internet connection),</li>
            <li>join or leave a live session,</li>
            <li>start or stop using {applicationName},</li>
            <li>navigate to a different part of {applicationName},</li>
            <li>open, close, or start or stop working on an interactive exercise,</li>
            <li>make progress in an interactive exercise,</li>
            <li>solve an interactive exercise, or</li>
            <li>make a mistake in an interactive exercise.</li>
        </ul>
        <p>
            For any of these events, in addition to the details about the event, we also record
        </p>
        <ul>
            <li>the current date and time,</li>
            <li>your pseudonymization identifier (which can be associated with your real identity until your user record is deleted),</li>
            <li>an identifier for your current {applicationName} session, and</li>
            <li>the application version you are using.</li>
        </ul>

        <h5>Live Sessions</h5>
        <p>
            The live sessions feature is built upon the interaction logs.
            No additional information is collected for these live sessions beyond what is described in the <em>{applicationName} Interaction Logs</em> section above.
        </p>

        <h5>Survey Results</h5>
        <p>
            When you submit a survey, we store all answers you provided.
            In addition, we record
        </p>
        <ul>
            <li>the current date and time,</li>
            <li>your pseudonymization identifier (which can be associated with your real identity until your user record is deleted),</li>
            <li>an identifier for your current {applicationName} session, and</li>
            <li>the application version you were using.</li>
        </ul>

        <h5>Grades</h5>
        <p>
            After the <em>Concurrent Programming</em> module has concluded, if you are enrolled into the {applicationName} User Study, we will retrieve your marks for all exams and tests and store them associated with your pseudonymization identifier.
        </p>

        <h4>Reason for Collecting User Identifiers</h4>
        <p>
            The {applicationName} User Study looks for corellations between your usage of {applicationName}, survey results and your academic performance.
            The data can only be corellated if we can link data from different sources to a single person.
        </p>

        <h4>Storage Location and Erasure of Data </h4>
        <p>
            Your personal data is erased or blocked as soon as the reason for storing it ceases to exist.
            Storage beyond this time period may occur if provided for by European or national legislators in Union regulations or national legislation and rules to which the data controller is subject.
            Such data shall also be blocked or erased if a storage period prescribed by one of the aforementioned legal standards expires. 
        </p>
        <p>
            We store all data collected as part of the study for at most 15 years, in accordance with Art. 5(1)(e) GDPR.
            According to Guideline 17 of the <em>Guidelines for Safeguarding Good Research Practice</em> of the German Research Foundation DFG (as of September 2019), it is necessary to keep source data for 10 years after publication of our research results derived from that data.
            Prior to that, it may take up to 5 years until our research results are published.
        </p>
        <p>
            For technical reasons, all data may persist up to 12 weeks in our backup system after erasure from the main system.
            All data is stored on servers of Universität des Saarlandes.
        </p>

        <h5>Pseudonymization</h5>
        <p>
            Once data collection has concluded at the end of the <em>Concurrent Programming</em> module and we have confirmed that grades from dCMS have been correctly associated with your pseudonymization identifier (but at most 1 year after data is collected), it will be pseudonymized by deleting your user record.
            After this, we will still be able to associate all data collected from you (e.g. link your grades and your interaction logs), but this data will no longer be directly linked to your identity.
            Despite pseudonymization, data collected from you is still personally identifiable information (as it could still be linked to you if combined with data from external data sources) and will be treated and protected as outlined in this privacy policy.
        </p>

        <h4>Recipients</h4>
        <h5>User Records, {applicationName} Interaction Logs, Survey Results and Grades</h5>
        <p>
            We will never publish or share individual data from the user study.
            Universität des Saarlandes is the only recipient of this data, and all data processing will be handled by employees or students of Universität des Saarlandes after signing a non-disclosure agreement.
            It is processed for the purpose of research or seminar projects (including, for example, bachelor, master, dissertation and habilitation theses).
            Resulting publications contain only fully anonymised analysis results.
        </p>
        <p>
            An exception to this are freeform responses to survey questions which we may publish in anonymous form if they appear to be of particular interest.
        </p>

        <h5>Live Sessions</h5>
        <p>
            If you participate in a live session and an exercise is run in this live session, we will share a brief summary of your activity and progress within these exercises with your instructor.
            This information is shared pseudonymously, no identifying information will be shared with the instructor.
        </p>
        <p>
            The instructor may share a summary of this information with the class.
            This summary is pseudonymized, too, and only shows your progress within the exercise and whether you are currently working on the exercise.
        </p>

        <h5>Analysis Results</h5>
        <p>
            Aggregate, non-personally-identifiable analysis results may be published worldwide and indefinitely as scientific contributions.
        </p>

        <h4>Data Processors</h4>
        <p>
            Data collected as part of the user study may be processed on our behalf by trustworthy processors in the European Union in accordance with Art. 28 GDPR.
        </p>

        <h4>Transfers of Personal Data to Third Countries or International Organisations</h4>
        <p>
            We do not transfer personal data to third countries or international organisations.
        </p>

        <h4>Your Rights</h4>
        <p>
            Your rights as a data subject are defined in Chapter 3 of the EU General Data Protection Regulation (GDPR).
        </p>

        <h5>Right of Access and Right to Data Portability </h5>
        <p>
            You have the right to obtain a record of the personal data we collected about you.
            We provide this data to you in a machine-readable format.
            You can exercise these rights if and only if data can be associated to your identity.
            After pseudonymization (as described above), we will no longer be able to associate data with your identity.
        </p>

        <h5>Right to Erasure and Right to Restriction of Processing</h5>
        <p>
            You have the right to obtain from us the erasure of personal data and the restriction of processing your personal data.
            As from receiving your request, we will delete your data after at most 7 working days from the main system.
            Your data will then no longer be processed.
            For technical reasons, it may take up to 12 weeks until all your data is removed from the backup system.
            You can exercise these rights if and only if data can be associated with your identity.
            After pseudonymization (as described above), we will no longer be able to associate data with your identity.
        </p>
        <p>
            If your data has already been analysed and is part of an aggregated and anonymous analysis result, it is technically not possible to remove your data from the analysis result.
        </p>
        <p>
            This only covers processing as part of {applicationName} User Study.
            For grades, while we can erase data that was stored as part of the user study, copies in dCMS or HISPOS are unaffected and will be kept to comply with archival requirements of examination regulation.
            You can find more details in the respective privacy policies.
        </p>

        <h5>Right to Rectification</h5>
        <p>
            You have the right to obtain from the controller without undue delay the rectification of your personal data concerning if it is inaccurate or incomplete.
        </p>
        <p>
            Your right to rectification may be restricted in so far as it is likely to render impossible or seriously impair the achievement of the scientific purpose, and such derogations are necessary for the fulfilment of the purpose of research.
        </p>
        <p>
            To exercise these rights, please contact the data controller or the data protection officer.
        </p>

        <h5>Withdrawal of Consent</h5>
        <p>
            You can withdraw your declaration of consent to take part in the user study at any time.
        </p>
        <ul>
            <li>If you wish to only withdraw consent to collecting additional {applicationName} interaction logs or survey results, you can do so by simply logging out of the user study.</li>
            <li>In any other case, e.g. if you wish to withdraw consent to processing your grades or any already collected data, please contact the data controller (or the data protection officer).</li>
        </ul>
        <p>
            The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.
        </p>

        <h4>Consequences if Consent is not Given or Withdrawn</h4>
        <p>
            If you do not consent to processing your data, you cannot take part in {applicationName} User Study, but can continue using {applicationName} without restriction.
            You will be unable to join live sessions.
            However, all exercises shared as part of live sessions can be found within {applicationName} – consenting to data processing and joining the live session is not required for access to the content, merely to be automatically directed to the right exercise and to provide automatic live feedback to your instructor.
        </p>
        <p>
            Your participation or non-participation in {applicationName} User Study does not affect your participation in the <em>Concurrent Programming</em> module.
        </p>

        <h4>Right to Lodge a Complaint with a Supervisory Authority</h4>
        <p>
            Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority, in particular in the EU member state of your habitual residence, your place of work or the place of the alleged infringement if you consider that the processing of your personal data infringes the GDPR.
        </p>
        <p>
            The supervisory authority with which the complaint has been lodged shall inform the complainant of the progress and the outcome of the complaint, including the possibility of a judicial remedy in accordance with Art. 78 GDPR.
        </p>
        <p>
            The regulatory authority responsible for Universität des Saarlandes is:
        </p>
        <p>
            Unabhängiges Datenschutzzentrum Saarland<br />
            Die Landesbeauftragte für Datenschutz und Informationsfreiheit<br />
            Fritz-Dobisch-Straße 12<br />
            66111 Saarbrücken<br />
            Germany
        </p>
        <p>
            E-Mail: <a href="mailto:poststelle@datenschutz.saarland.de">poststelle@datenschutz.saarland.de</a><br />
            Phone: +49 681 94781-0
        </p>

        <h4>Updates to this Privacy Policy</h4>
        <p>
            We might need to update this privacy policy in the future.
            If there is a substantial or material change, we will explicitly ask for approval before a new version of this policy is applied to your account or data collected from you.
        </p>
    </div>;
};

import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { UserStudyContext } from "./UserStudy";

const splitPath = (path: string): { head: string; rest: string | undefined } => {
    const match = /^\/([^/]*)(\/.*)?$/.exec(path);
    if (!match) throw new Error(`Cannot split path ${path}.`);
    return { head: match[1], rest: match[2] };
};

const limitPathDepth = (path: string, length: number): string => {
    let result = "";
    while (true) {
        const { head, rest } = splitPath(path);
        if (rest === undefined || length <= 1) {
            return `${result}/${head}`;
        }

        result += `/${head}`;
        path = rest;
        length--;
    }
};

const shortenPath = (path: string): string => {
    const { head } = splitPath(path);
    switch (head) {
        case "read": {
            return limitPathDepth(path, 3);
        }
        case "study": {
            return limitPathDepth(path, 1);
        }
        case "live": {
            return limitPathDepth(path, 2);
        }
        default: {
            return path;
        }
    }
};

export const UserStudyNavigationReporter: React.FC<Record<string, null>> = () => {
    const userStudy = useContext(UserStudyContext);
    const location = useLocation();

    const shortPath = shortenPath(location.pathname);

    useEffect(() => {
        if (userStudy.connectionState === "CONNECTED") {
            userStudy.reportEvent({
                type: "NAVIGATE",
                path: shortPath
            });
        }
    // ↓ needed to ensure other changes to not fire this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortPath, userStudy.connectionState]);

    return null;
};

import { CallPayloadTypes, CallReturnTypes, CallTypes, EventPayloadTypes, EventTypes } from "./types";

/**
 * An “abstract” WebSocket type, using only the features required by [[Router]].
 * This is intended to be filled by either the standard `window.WebSocket` from the DOM API or the `WebSocket` class from the `ws` NPM package.
 */
export interface WebSocket {
    readyState: number;
    CONNECTING: number;
    OPEN: number;
    CLOSING: number;
    CLOSED: number;

    addEventListener(method: 'open', cb: (event: { target: WebSocket }) => void): void;
    addEventListener(method: 'message', cb: (event: {
        data: unknown;
        type: string;
        target: WebSocket
    }) => void): void;
    addEventListener(method: 'close', cb: (event: {
        wasClean: boolean; code: number;
        reason: string; target: WebSocket
    }) => void): void;

    close(code?: number, data?: string): void;
    send(data: unknown, cb?: (err?: Error) => void): void;
    send(data: unknown, options: { mask?: boolean; binary?: boolean; compress?: boolean; fin?: boolean }, cb?: (err?: Error) => void): void;
}

export type CallHandlers = {
    [callType in keyof CallPayloadTypes]?: (payload: CallPayloadTypes[callType]) => Promise<CallReturnTypes[callType]>;
}


export type EventHandlers = {
    [eventType in EventTypes]?: (payload: EventPayloadTypes[eventType]) => void
}

/**
 * The type of all messages [[Router]] sends over the socket.
 */
type Message = {
    type: "E", // event
    eventType: EventTypes;
    payload: unknown
} | {
    type: "C", // call
    callType: CallTypes;
    id: number,
    payload: unknown
} | {
    type: "R", // return
    id: number,
    payload: unknown
} | {
    type: "ERR", // return error
    id: number
}

/**
 * A router is attached to an already opened WebSocket (that should not have a `message` handler).
 * It allows sending an receiving messages, and assumes the other side to use this router, too.
 * It's core feature is that in addition to _events_ (one-way messages that are simply routed to a handler based on their type), it also supports _calls_, which are messages that should elicit a response.
 * On the caller side, calls return a [[Promise]] that allows awaiting the response.
 * On the callee side, calls are processed by a handler that is expected to return the response.
 */
export class Router {
    readonly socket: WebSocket;
    callHandlers: CallHandlers;
    eventHandlers: EventHandlers;

    /**
     * List of messages that could not be sent yet because the connection has not been opened yet.
     */
    private readonly queuedMessages: Message[] = [];

    private nextId = 0;

    private openCalls: {
        [id: number]: {
            resolve: (payload: unknown) => void,
            reject: () => void
        }
    } = {};

    public setHandlers(callHandlers: CallHandlers = {}, eventHandlers: EventHandlers = {}): void {
        this.callHandlers = callHandlers;
        this.eventHandlers = eventHandlers;
    }

    constructor(socket: WebSocket, callHandlers: CallHandlers = {}, eventHandlers: EventHandlers = {}) {
        this.socket = socket;
        this.callHandlers = callHandlers;
        this.eventHandlers = eventHandlers;

        if (this.socket.readyState === this.socket.CONNECTING) {
            this.socket.addEventListener("open", () => {
                // connection established, send any queued messages
                while (true) {
                    const msg = this.queuedMessages.pop();
                    if (msg === undefined) break;
                    this.sendMessage(msg);
                }
            });
        }

        this.socket.addEventListener("close", () => {
            // reject all open calls
            for (const id in this.openCalls) {
                const openCall = this.openCalls[id];
                openCall.reject();
                delete this.openCalls[id];
            }
        });

        this.socket.addEventListener("message", async ({ data }) => {
            try {
                const message = JSON.parse(data as string) as Message;
                switch (message.type) {
                    case "E": {
                        const eventType = message.eventType;
                        const handler = this.eventHandlers[eventType];
                        if (handler !== undefined) {
                            try {
                                handler(message.payload as never);
                            } catch (e) {
                                console.log(`Unhandled error in event handler: ${e}`);
                            }
                        } else {
                            throw new Error(`Received event of unhandled type ${eventType}.`);
                        }
                        break;
                    }
                    case "C": {
                        const callType = message.callType;
                        const handler = this.callHandlers[callType];
                        if (handler !== undefined) {
                            try {
                                const returnValue = await handler(message.payload as never);
                                this.sendMessage({
                                    type: "R",
                                    id: message.id,
                                    payload: returnValue
                                });
                            } catch (e) {
                                console.log(`Unhandled error in call handler: ${e}`);
                                this.sendMessage({
                                    type: "ERR",
                                    id: message.id
                                });
                            }
                        } else {
                            console.log(`Received call of unhandled type ${callType}.`);
                            this.sendMessage({
                                type: "ERR",
                                id: message.id
                            });
                        }
                        break;
                    }
                    case "R": {
                        const openCall = this.openCalls[message.id];
                        if (!openCall) throw new Error(`Received return for non-open call ${message.id}`);
                        delete this.openCalls[message.id];
                        openCall.resolve(message.payload);
                        break;
                    }
                    case "ERR": {
                        const openCall = this.openCalls[message.id];
                        if (!openCall) throw new Error(`Received error for non-open call ${message.id}`);
                        delete this.openCalls[message.id];
                        openCall.reject();
                        break;
                    }
                    default: {
                        throw new Error(`Unknown message type: ${(message as Message).type}`);
                    }
                }
            } catch (e) {
                console.log(`Error while processing incoming message ${JSON.stringify(data)}: ${e.message}`);
                this.socket.close();
            }
        });
    }

    private sendMessage(message: Message) {
        if (this.socket.readyState === this.socket.CONNECTING) {
            // not connected yet, queue message to be sent later
            this.queuedMessages.push(message);
        } else {
            // just (attempt to) send
            this.socket.send(JSON.stringify(message));
        }
    }

    /**
     * Sends an event to the other side.
     * @param type An identifier for this type of event.
     * @param payload Any data that will be transmitted as part of the event.
     */
    event<T extends keyof EventPayloadTypes>(type: T, payload: EventPayloadTypes[T]): void {
        this.sendMessage({
            type: "E",
            eventType: type,
            payload
        });
    }

    call<T extends keyof CallPayloadTypes>(type: T, payload: CallPayloadTypes[T]): Promise<CallReturnTypes[T]> {
        const id = this.nextId++;
        this.sendMessage({
            type: "C",
            id,
            callType: type,
            payload
        });

        return new Promise((resolve, reject) => this.openCalls[id] = { resolve: resolve as (payload: unknown) => void, reject });
    }
}

export const flatten = <T>(a: T[][]): T[] => ([] as T[]).concat(...a);

/**
 * Functional map for object.
 * @param obj The object to map.
 * @param fn Value and key and should return new value.
 */
export const mapObject = <V1, V2>(obj: {[k: string]: V1}, fn: (v: V1, k: string) => V2): {[k: string]: V2} =>
    Object.fromEntries(
        Object.entries(obj).map(
            ([k, v]) => [k, fn(v, k)]
        )
    );

/**
 * Computes a big union operator.
 * @param sets The sets to unify.
 */
export const union = <T>(sets: Set<T>[]): Set<T> => {
    const res = new Set<T>();
    sets.forEach((s) => s.forEach((e) => res.add(e)));
    return res;
};

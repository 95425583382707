import React, { Fragment, useContext } from 'react';

import "./TableOfContents.scss";
import { Book, Chapter, Part } from '../../../../hybrid-documents/src/Book';
import { isHeading } from '../../book/Book';
import { Link } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import { UserStudyMiniCTA } from '../../../src/study/UserStudyCallToAction';
import { UserStudyContext } from '../../../src/study/UserStudy';

const PartToc: React.FC<{
    part: Part;
    chapterId: string;
    partId: string;
    selectedSectionId: string | null;
    onSectionSelected: () => void;
}> = ({ part: chapter, selectedSectionId, onSectionSelected, chapterId, partId }) => {
    const headings = chapter.content.filter(isHeading);

    return <div className="part-sections">
        {headings.map((heading) => {
            return <div className={"section" + (selectedSectionId === heading.id ? " active" : "")} data-level={heading.level} key={heading.id}>
                <Link onClick={onSectionSelected} to={`/read/${chapterId}/${partId}/section/${heading.id}`}>
                    {heading.displayName}
                </Link>
            </div>;
        })}
    </div>;
};

export const TableOfContents: React.FC<{
    tocExpanded: boolean;
    setTocExpanded: (tocExpanded: boolean) => void;
    book: Book;
    selectedChapterId: string | null;
    selectedChapter: Chapter | null;
    selectedPartId: string | null;
    selectedPart: Part | null;
    selectedSectionId: string | null;
}> = ({ tocExpanded, setTocExpanded, book, selectedChapterId, selectedChapter, selectedPartId, selectedPart, selectedSectionId }) => {
    const userStudy = useContext(UserStudyContext);

    return <div className={"toc" + (tocExpanded ? " expanded" : "")}>
        <div className="expand-toggle">
            <span className="toc-label">Table of Contents</span>
            <span className="toggle-show" onClick={(): void => setTocExpanded(true)}>show ▽</span>
            <span className="toggle-hide" onClick={(): void => setTocExpanded(false)}>hide △</span>
        </div>
        <div className="toc-content">
            <Container>
                {book.chapters.map((c) => 
                    <div className={"chapter" + (c.id === selectedChapterId ? " active" : "")} key={c.id}>
                        <div className="chapter-line">
                            <Link onClick={(): void => setTocExpanded(false)} to={`/read/${c.id}`}>
                                {c.displayName}
                            </Link>
                        </div>
                        { selectedChapter !== null && selectedChapterId === c.id ? <Fragment>
                            {selectedChapter.parts.map((p) =>
                                <div className={"part" + (p.id === selectedPartId ? " active" : "")} key={p.id}>
                                    <div className="part-line">
                                        <Link onClick={(): void => setTocExpanded(false)} to={`/read/${selectedChapterId}/${p.id}`}>
                                            {p.displayName}
                                        </Link>
                                    </div>
                                    { selectedPartId === p.id ?
                                        (selectedPart !== null ? <PartToc part={selectedPart} chapterId={selectedChapterId} partId={selectedPartId} selectedSectionId={selectedSectionId} onSectionSelected={(): void => setTocExpanded(false)} /> : <div className="part-toc-loading"><Spinner animation="border" size="sm" className="part-toc-spinner" /> Loading...</div>)
                                        : null }
                                </div>
                            )}
                        </Fragment> : null }
                    </div>
                )}
                { userStudy.shouldAskForEnrollment ? <>
                    <div className="spacer" />
                    <UserStudyMiniCTA />
                </> : null }
            </Container>
        </div>
    </div>;
};

import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import ScrollContainer from '../../../hybrid-documents-reader-core/ScrollContainer';
import { UserStudyContext } from '../../study/UserStudy';
import { applicationName } from '../../ProductInfo';

import "./UserStudyPage.scss";

import refreshIcon from '../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-82-refresh.svg';
import networkIcon from '../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-849-computer-network.svg';

import classNames from 'classnames';
import { UserStudyPrivacyPolicy } from '../../study/PrivacyPolicy';
import { useHistory } from 'react-router';
import { UserStudyCallToAction } from '../../study/UserStudyCallToAction';

const ConsentText: React.FC<{}> = () => <>
    <p>
        Here's what you should know before signing up:
    </p>
    <ul>
        <li>
            When you participate in the study, we will log your interactions with {applicationName}.
            This allows us to identify how much {applicationName} is being used, where users spend their time, which exercises students are struggling with the most, and more.
        </li>
        <li>
            We will only publish aggregate information (e.g. average time spent on an exercise per student), no information that could be linked to particular students.
        </li>
        <li>
            If you participate in the study, at the end of term, we will link your logs with your marks in the <em>Concurrent Programming</em> lecture.
            This allows us to analyze correlations, e.g. whether spending more time in certain exercises of {applicationName} appears to improve performance in related exam exercises.
        </li>
        <li>
            If you participate in a live session, your teacher will be shown your progress within the exercise in real-time.
        </li>
    </ul>
    <p>
        You can find more information, including the privacy policy, below.
    </p>
    <p>
        By signing up for {applicationName} user study, I consent to donate my usage data of {applicationName} to Saarland University and for it to be corellated with my marks from the <em>Concurrent Programming</em> lecture in accordance with out privacy policy. For a duration of 15 years, my data may be processed by Saarland University and scientifically analysed with data of other users. This consent is voluntary. I can refuse it without giving reasons and without having to fear any disadvantages. I can also revoke this consent at any time in text form (e.g. letter or e-mail) to the data controller named below.
    </p>
</>;

export const UserStudyPage: React.FC<{}> = () => {
    const userStudy = useContext(UserStudyContext);
    const history = useHistory();

    const [action, setAction] = useState<null | "login" | "enroll" | "logout">(null);
    const [showPreApprovedConsentRejectionModal, setShowPreApprovedConsentRejectionModal] = useState<boolean>(false);

    useEffect(() => { // prevent user from getting stuck in „logging in“ if they cancel navigation
        if (action === "login") {
            const t = setTimeout(() => setAction(null), 10000);
            return () => clearTimeout(t);
        }
    }, [action]);

    const firstName = (userStudy.userRecord?.name ?? "<anonymous").split(" ").slice(0, 1)[0];

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="user-study">
            { userStudy.enabled ? <>
                <UserStudyCallToAction />
                { !userStudy.loggedIn ? <div className="login">
                    <p>
                        Hello! Thank you for your interest in joining the {applicationName} User Study.
                        To get started, please log in with your <em>Concurrent Programming</em> account.
                    </p>
                    <ConsentText />
                    <div className="enrollment-actions">
                        <Button className="enroll-confirm" variant="primary" disabled={!!action} onClick={ () => { setAction("login"); userStudy.login(); } }>
                            { action === "login" ? <>
                                <span>Logging in…</span>
                            </> : "I Agree – Log In" }
                            <img className={classNames({ "working-icon": true, "invert-dark": true, "active": action === "login" })} src={refreshIcon} />
                        </Button>
                        <Button className="enroll-cancel" variant="secondary" disabled={!!action} onClick={ () => setShowPreApprovedConsentRejectionModal(true) }>
                            Reject
                            <img className={classNames({ "working-icon": true, "invert-dark": true, "active": action === "logout" })} src={refreshIcon} />
                        </Button>
                    </div>
                    <div className="clearfix" />
                    <div>
                        <h3>Privacy Policy</h3>
                        <UserStudyPrivacyPolicy />
                    </div>
                </div> : userStudy.connectionState === "UNENROLLED" ? <div>
                    <h2>{applicationName} User Study Enrollment</h2>
                    <p>
                        Hello, {firstName}! Thank you for your interest in joining the {applicationName} User Study.
                    </p>
                    <ConsentText />
                    <div className="enrollment-actions">
                        <Button className="enroll-confirm" variant="success" disabled={!!action} onClick={ async () => {
                            setAction("enroll");
                            await userStudy.enroll();
                            setAction(null);
                        } }>
                            I Agree – Sign Up
                            <img className={classNames({ "working-icon": true, "invert-dark": true, "active": action === "enroll" })} src={refreshIcon} />
                        </Button>
                        <Button className="enroll-cancel" variant="danger" disabled={!!action} onClick={ async () => {
                            setAction("logout");
                            await userStudy.logout();
                            setAction(null);
                        } }>
                            Reject – Logout
                            <img className={classNames({ "working-icon": true, "invert-dark": true, "active": action === "logout" })} src={refreshIcon} />
                        </Button>
                    </div>
                    <div className="clearfix" />
                    <div>
                        <h3>Privacy Policy</h3>
                        <UserStudyPrivacyPolicy />
                    </div>
                </div> : userStudy.connectionState === "CONNECTED" ? <div className="connected">
                    { userStudy.liveSessionJoinState === "JOINED" ? <Alert variant="success" className="live-session-alert">
                        <b>You are currently in a live session.</b>
                        <Button variant="success" onClick={() => history.push("/live")}>Go There</Button>
                    </Alert> : null }
                    { userStudy.shouldAskForSurvey ? <Alert variant="success" className="live-session-alert">
                        <b>Please fill out a final survey to complete your participation in pseuCo Book User Study.</b>
                        <Button variant="success" onClick={() => history.push("/study/survey")}>Continue</Button>
                    </Alert> : null }
                    <p>
                        Hello, {firstName}! Thank you for participating in {applicationName} User Study.
                    </p>
                    <p>
                        You're all set – just continue using {applicationName} as usual.
                        If you use {applicationName} on multiple devices, make sure you log in on all of them.
                        If you want to join a live session, look for an access link provided by your teacher.
                    </p>
                    <Button size="sm" className="logout" variant="danger" disabled={!!action} onClick={ async () => {
                        setAction("logout");
                        await userStudy.logout();
                        setAction(null);
                    } }>
                        Logout
                        <img className={classNames({ "working-icon": true, "invert-dark": true, "active": action === "logout" })} src={refreshIcon} />
                    </Button>
                    <div className="clearfix" />
                </div> : <div className="not-connected">
                    <img className="not-connected-icon invert-dark" src={networkIcon} />
                    { userStudy.connectionState === "ESTABLISHING" ? <p>Connecting…</p> : <p>Not connected.<br />Check your internet connection. <a className="link-action" onClick={() => userStudy.connect()}>Connect?</a><br /><a className="link-action" onClick={() => userStudy.localLogout()}>Log out?</a></p>}
                </div> }
            </> : <div className="study-disabled">
                — {applicationName} User Study is not available —
            </div> }
        </Container>

        <Modal className="enroll-cta-modal" show={showPreApprovedConsentRejectionModal} onHide={() => setShowPreApprovedConsentRejectionModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Cannot Join {applicationName} User Study</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    You cannot join the {applicationName} User Study if you do not consent to us processing your data.
                </p>
                <p>
                    Joining the {applicationName} User Study is optional.
                    You can continue using {applicationName} without having joined the user study.
                    You will be unable to join live sessions, but all exercises shown in live sessions can be accessed directly in the book, too.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setShowPreApprovedConsentRejectionModal(false)}>Close</Button>
                <Button variant="primary" onClick={() => {
                    setShowPreApprovedConsentRejectionModal(false);
                    history.push("/");
                }}>Go to Home Page</Button>
            </Modal.Footer>
        </Modal>
    </ScrollContainer>;
};

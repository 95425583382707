import { PropsWithChildren, createContext } from "react";
import { offlineStatus, offlineStatusEvents } from "./OfflineManagement";
import React from "react";

export const OfflineStatusContext = createContext<typeof offlineStatus>(offlineStatus);

export const OfflineStatusContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [status, setStatus] = React.useState<typeof offlineStatus>({ ...offlineStatus });

    const updateStatus = (): void => {
        setStatus({ ...offlineStatus });
    };
 
    React.useEffect(() => {
        offlineStatusEvents.on('update', updateStatus);
        return (): void => {
            offlineStatusEvents.off('update', updateStatus);
        };
    }, []);

    return <OfflineStatusContext.Provider value={status}>
        { children }
    </OfflineStatusContext.Provider>;
};

import React, { Fragment, ReactElement, createElement, createRef } from "react";
import { OverlayTrigger, Tooltip, Badge, Button } from "react-bootstrap";
import { Book, HorizontalBox, Part, VerticalBox } from "../../../hybrid-documents/src/Book";
import classNames from "classnames";
import TextEditor from "../../pseuco-shared-components/ui/editors/text/TextEditor";
import { openFileInConcurrentProgrammingWeb } from "../../pseuco-shared-components/util/pseuCoUrlFileEncoder";
import { InteractiveElementBoxAPI, InteractiveElementBox } from "../interactive/InteractiveElement";
import { getImage } from "./Book";
import { Theme } from "../ThemeApplicator";
import { InternalNavigationLink } from "../pages/read/LinkNavigationSupport";

const getHByLevel = (level: number): string => {
    switch (level) {
        case 0: return 'h3';
        case 1: return 'h4';
        case 2: return 'h5';
        case 3: return 'h6';
    }
    throw new Error("Invalid heading level.");
};

/**
 * Renders a horizontal element.
 * @param element The element to render.
 * @param key The key that will be given to the main element.
 * @param parentId The id of the parent element, or another sufficiently unique string. Used to generate unique HTML ids for elements where this is internally required.
 * @param currentLocation Optionally, the current location (internal React routing path). Only used for onLinkToCurrentLocation.
 * @param onLinkToCurrentLocation Called when a link to the current location is clicked. Optional, used by renderers that want to ensure these links produce some feedback to the user.
 * @returns 
 */
export const renderHorizontal = (element: HorizontalBox, key: number, parentId: string): React.ReactElement => {

    switch (element.type) {
        case 'text': {
            return <span key={key}>{element.content}</span>;
        }
        case 'emph': {
            return <i key={key}>{element.content.map((ihe, i) => renderHorizontal(ihe, i, parentId))}</i>;
        }
        case 'strong': {
            return <strong key={key}>{element.content.map((ihe, i) => renderHorizontal(ihe, i, parentId))}</strong>;
        }
        case 'inline-math': {
            return <span key={key} dangerouslySetInnerHTML={{__html: element.content}}></span>; // trust me
        }
        case 'reference': {
            let to = "";
            switch (element.targetType) {
                case "image": {
                    to = `/read/${element.targetLocation.chapter}/${element.targetLocation.part}/figure/${element.targetId}`;
                    break;
                }
                case "section": {
                    to = `/read/${element.targetLocation.chapter}/${element.targetLocation.part}/section/${element.targetId}`;
                    break;
                }
                case "lemma": {
                    to = `/read/${element.targetLocation.chapter}/${element.targetLocation.part}/lemma/${element.targetId}`;
                    break;
                }
                case "listing": {
                    to = `/read/${element.targetLocation.chapter}/${element.targetLocation.part}/listing/${element.targetId}`;
                    break;
                }
                case "definition": {
                    to = `/read/${element.targetLocation.chapter}/${element.targetLocation.part}/definition/${element.targetId}`;
                    break;
                }
                case "part": {
                    to = `/read/${element.targetLocation.chapter}/${element.targetLocation.part}/`;
                    break;
                }
                case "chapter": {
                    to = `/read/${element.targetLocation.chapter}/`;
                    break;
                }
                default: {
                    throw new Error(`Cannot render reference to type ${element.targetType}!`);
                }
            }
            return <InternalNavigationLink key={key} to={to}>{ element.content }</InternalNavigationLink>;
        }
        case 'footnote': {
            const content = element.content.map((ihe, i) => renderHorizontal(ihe, i, parentId));
            return <OverlayTrigger key={key} delay={{ show: 250, hide: 1000 }} overlay={
                <Tooltip id={`footnote-${parentId}-${key}`}>
                    { content }
                </Tooltip>
            }>
                <Badge className="footnote-mark" bg="secondary">*</Badge>
            </OverlayTrigger>;
        }
        case 'code': {
            return <code key={key}>{ element.code }</code>;
        }
        case 'link': {
            return <a key={key} href={element.url}>{ element.content.map((ihe, i) => renderHorizontal(ihe, i, parentId)) }</a>;
        }
        default: {
            throw new Error(`Failed to render horizontal element of unknown type ${(element as HorizontalBox).type}.`);
        }
    }
};

/**
 * Renders a vertical element.
 * @param element The element to be rendered.
 * @param index The index of the element. This is usually an array of length one. When the function is called recursively, to render more complex nested elements like lists, it becomes a longer array, with the last element being the current index (so e.g. [3, 7] indicates we are within a list or something similiar with index 3, and our subelement has index 7).
 * @param id The HTML id to give to the main element.
 * @param currentLocation Optionally, the current location (internal React routing path). Only used for onLinkToCurrentLocation.
 * @param onLinkToCurrentLocation Called when a link to the current location is clicked. Optional, used by renderers that want to ensure these links produce some feedback to the user.
 */
export const renderVertical = (
    element: VerticalBox,
    index: number[],
    id: string,
    theme: Theme,
    interactiveElementRefs?: React.Ref<InteractiveElementBoxAPI>[],
    anchorVisibilities?: boolean[],
    updateInteractiveLoadStatus?: () => void
): React.ReactElement => {
    const key = index.toString(); // allows mapping renderVertical() to a list

    switch (element.type) {
        case 'paragraph': {
            return <p id={id} key={key}>
                { element.content.map((he, i) => renderHorizontal(he, i, id)) }
                { element.postContent ? <Fragment>
                    <span className="paragraph-post-content">{
                        element.postContent.map((he, i) => renderHorizontal(he, i, id))
                    }</span>
                    <span className="paragraph-post-content-clearfix" />
                </Fragment> : null }
            </p>;
        }
        case 'heading': {
            const heading = getHByLevel(element.level);
            return createElement(heading, { id, key }, [element.displayName]);
        }
        case 'display-math': {
            return <div id={id} key={key} className={`display-math`} dangerouslySetInnerHTML={{__html: element.content}}></div>;
        }
        case 'interactive': {
            if (index.length !== 1) throw new Error("Interactive elements are only supported on the top-level of the document.");
            if (interactiveElementRefs && !interactiveElementRefs[index[0]]) interactiveElementRefs[index[0]] = createRef<InteractiveElementBoxAPI>();
            return <InteractiveElementBox ref={interactiveElementRefs ? interactiveElementRefs[index[0]] : undefined} htmlId={id} key={key} type="interactive" id={element.id} replacementText={element.replacementText} launchOnLoad={false} showDedicatedRouteLink={true} fullHeight={false} visible={anchorVisibilities ? !!anchorVisibilities[index[0]] : false} onLoaded={updateInteractiveLoadStatus} />;
        }
        case 'quiz': {
            if (index.length !== 1) throw new Error("Quizzes elements are only supported on the top-level of the document.");
            return <InteractiveElementBox htmlId={id} key={key} type="quiz" id={element.id} replacementText="Quiz" launchOnLoad={true} showDedicatedRouteLink={true} fullHeight={true} visible={anchorVisibilities ? !!anchorVisibilities[index[0]] : false} onLoaded={updateInteractiveLoadStatus} />;
        }
        case 'list': {
            const content: ReactElement[] = element.content.map((li, i) => <li key={i}>{ li.map((ce, j) => renderVertical(ce, [...index, i, j], `${id}-list-item-${i}-${j}`, theme, interactiveElementRefs, anchorVisibilities, updateInteractiveLoadStatus)) }</li>);
            return element.ordered ? <ol id={id} key={key}>{content}</ol> : <ul id={id} key={key}>{content}</ul>;
        }
        case 'definition': {
            return <div id={id} key={key} className={`definition`}>
                <div className="definition-name"><span>Definition:</span> {element.name.map((ne, i) => renderHorizontal(ne, i, `${index}`))}.</div>
                <div>{element.content.map((ce, i) => renderVertical(ce, [...index, i], `${id}-definition-content-${i}`, theme, interactiveElementRefs, anchorVisibilities, updateInteractiveLoadStatus))}</div>
            </div>;
        }
        case 'image': {
            const aspectRatio = element.intrinsicSize.width / element.intrinsicSize.height;

            return <div id={id} key={key} className={`image-frame`}>
                <div className="horizontal-track">
                    <div className="image-width-placeholder" style={{ width: 2*element.intrinsicSize.width }}>
                        <div className="image-aspect-ratio-placeholder" style={{ paddingBottom: `${100/aspectRatio}%` }}>
                            <img className={ classNames({
                                image: true,
                                "dark-mode-invert": element.darkMode?.mode === "invert"
                            }) } src={getImage(element.src)} />
                        </div>
                    </div>
                </div>
                {
                    element.caption == null ? null : <div className="horizontal-track">
                        <div className="caption">{ element.caption.map((he, i) => renderHorizontal(he, i, id)) }</div>
                    </div>
                }
            </div>;
        }
        case 'todo': {
            const humanReadableSeverities = {
                "note": "Note",
                "warning": "Warning",
                "error": "Error"
            };

            return <div id={id} key={key} className={`todo todo-${element.severity}`}>
                <div><span className="todo-type-label">{humanReadableSeverities[element.severity]}:</span> <span>{element.summary}</span></div>
                <div>{element.content.map((ce, i) => renderVertical(ce, [...index, i], `${id}-todo-content-${i}`, theme, interactiveElementRefs, anchorVisibilities, updateInteractiveLoadStatus))}</div>
            </div>;
        }
        case 'listing': {
            return <div id={id} key={key} className={`listing-frame`}>
                <div className="text-editor-frame">
                    <TextEditor type={element.listingType} data={{ core: element.code, extended: {} }} readOnly={true} options={{ theme, noStatusBar: true, naturalHeight: true, noActiveLineBackground: true }} />
                    { element.listingType === "pseuco" ? <Button className="pseuco-export-button" variant="secondary" size="sm" onClick={() => openFileInConcurrentProgrammingWeb({ type: "pseuco", content: element.code })}>Open in pseuCo.com</Button> : null }
                </div>
                {
                    element.caption == null ? null : <div className="horizontal-track">
                        <div className="caption">{ element.caption.map((he, i) => renderHorizontal(he, i, id)) }</div>
                    </div>
                }
            </div>;
        }
        case 'box': {
            return <div id={id} key={key} className={`box-frame document-box-${element.boxType}`}>
                <div className="box-content">
                    {element.content.map((ce, i) => renderVertical(ce, [...index, i], `${id}-box-content-${i}`, theme, interactiveElementRefs, anchorVisibilities, updateInteractiveLoadStatus))}
                </div>
            </div>;
        }
        default: {
            throw new Error(`Failed to render vertical element of unknown type ${(element as VerticalBox).type}.`);
        }
    }
};

/**
 * Renders a horizontal element as a string only, as far as possible. Intended for search.
 */
export const renderHorizontalToSearchableText = (element: HorizontalBox): string => {
    switch (element.type) {
        case 'text': {
            return element.content;
        }
        case 'emph': {
            return element.content.map(renderHorizontalToSearchableText).join("");
        }
        case 'strong': {
            return element.content.map(renderHorizontalToSearchableText).join("");
        }
        case 'inline-math': {
            return element.source;
        }
        case 'reference': {
            return element.content;
        }
        case 'footnote': {
            return element.content.map(renderHorizontalToSearchableText).join("");
        }
        case 'code': {
            return element.code;
        }
        case 'link': {
            return element.content.map(renderHorizontalToSearchableText).join("");
        }
        default: {
            throw new Error(`Failed to render horizontal element of unknown type ${(element as HorizontalBox).type} to string.`);
        }
    }
};

/**
 * Renders a vertical element as a string only, as far as possible. Intended for search.
 */
export const renderVerticalToSearchableText = (element: VerticalBox, book?: Book): string => {
    switch (element.type) {
        case 'paragraph': {
            let res = element.content.map(renderHorizontalToSearchableText).join("");
            if (element.postContent) res += " " + element.postContent.map(renderHorizontalToSearchableText);
            return res;
        }
        case 'heading': {
            return element.displayName;
        }
        case 'display-math': {
            return element.source; // source code is the best we can do for math
        }
        case 'interactive': {
            return element.replacementText;
        }
        case 'quiz': {
            if (!book) return "";
            const quiz = book.quizzes[element.id];
            return `${quiz.title} ${quiz.questions.map((q) => q.prompt.map(renderHorizontalToSearchableText) + "\n" + q.answers.map((a) => a.content.map(renderHorizontalToSearchableText).join("")).join("\n"))}`;
        }
        case 'list': {
            return element.content.map((ves) => ves.map((ve) => renderVerticalToSearchableText(ve)).join("\n")).join("\n");
        }
        case 'definition': {
            return element.name.map(renderHorizontalToSearchableText) + "\n" + element.content.map((ve) => renderVerticalToSearchableText(ve)).join("\n");
        }
        case 'image': {
            return element.caption?.map(renderHorizontalToSearchableText).join("") ?? "";
        }
        case 'todo': {
            return element.summary + "\n" + element.content.map((ve) => renderVerticalToSearchableText(ve)).join("\n");
        }
        case 'listing': {
            let res = element.code;
            if (element.caption) res += element.caption.map(renderHorizontalToSearchableText).join("");
            return res;
        }
        case 'box': {
            return element.content.map((ve) => renderVerticalToSearchableText(ve)).join("");
        }
        default: {
            throw new Error(`Failed to render vertical element of unknown type ${(element as VerticalBox).type} to string.`);
        }
    }
};

/**
 * maps vertical element indexes to anchor definitions
 */
export const generateAnchors = (part: Part) => {
    const anchors: {
        anchorId: string; // the id for this position as seen in the URL
        sectionId: null | string; // the ID of the section this is in (for ToC)
        htmlId: string; // the ID to use for the HTML element
    }[] = [];

    let lastSectionId: null | string = null;
    let lastDedicatedAnchor = "";
    let lastDedicatedAnchorIndex = -1;
    for (let i = 0; i < part.content.length; i++) {
        const element = part.content[i];
        const htmlId = `vertical-element-${i.toString().padStart(3, '0')}`;

        const addAnchor = (anchorId: string): void => {
            anchors.push({ anchorId, sectionId: lastSectionId, htmlId });
            lastDedicatedAnchor = `${anchorId}/`;
            lastDedicatedAnchorIndex = i;
        };
        const implicitAnchor = (): void => {
            const anchorId = `${lastDedicatedAnchor}${i - lastDedicatedAnchorIndex}`;
            anchors.push({ anchorId, sectionId: lastSectionId, htmlId });
        };

        switch (element.type) {
            case 'paragraph': {
                if (element.label) {
                    addAnchor(`${element.label.type}/${element.label.id}`);
                } else {
                    implicitAnchor();
                }
                break;
            }
            case 'heading': {
                const anchorId = `section/${element.id}`;
                const sectionId = element.id;
                anchors.push({ anchorId, sectionId, htmlId });
                lastDedicatedAnchor = `${anchorId}/`;
                lastDedicatedAnchorIndex = i;
                lastSectionId = sectionId;
                break;
            }
            case 'interactive':
            case 'quiz': {
                addAnchor(`interactive/${element.id}`);
                break;
            }
            case 'definition': {
                addAnchor(`definition/${element.id}`);
                break;
            }
            case 'image': {
                if (element.id) {
                    addAnchor(`figure/${element.id}`);
                    break;
                } else {
                    implicitAnchor();
                    break;
                }
            }
            case 'listing': {
                if (element.id) {
                    addAnchor(`listing/${element.id}`);
                    break;
                } else {
                    implicitAnchor();
                    break;
                }
            }
            default: {
                implicitAnchor();
            }
        }
    }

    return anchors;
};

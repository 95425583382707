import React from 'react';
import { Container, Form } from 'react-bootstrap';
import GlobalSettingsContext from '../../GlobalSettings';
import ScrollContainer from '../../../hybrid-documents-reader-core/ScrollContainer';

export const SettingsPage: React.FC<{}> = () => {
    const settings = React.useContext(GlobalSettingsContext);
    
    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container>
            <h1>Theme settings</h1>
            <Form>
                <Form.Check type="checkbox" id="settings-match-system-dark-mode" label="Match my system's dark mode setting if my browser supports it" checked={settings.current.followSystemDarkMode} onChange={(): void => settings.set({ ...settings.current, followSystemDarkMode: !settings.current.followSystemDarkMode })} />
            </Form>
        </Container>
    </ScrollContainer>;
};

import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import ScrollContainer from "../../../hybrid-documents-reader-core/ScrollContainer";
import { UserStudyContext } from "../../study/UserStudy";

import "./UserStudyCompleteLoginPage.scss";

import refreshIcon from '../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-82-refresh.svg';
import errorIcon from '../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-193-circle-empty-remove.svg';
import successIcon from '../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-194-circle-empty-check.svg';

export const UserStudyCompleteLoginPage: React.FC<{}> = () => {
    const [state, setState] = useState<"NOT_STARTED" | "WORKING" | "SUCCESS" | "FAILED">("NOT_STARTED");

    const userStudy = useContext(UserStudyContext);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (state === "NOT_STARTED") {
            if (userStudy.loggedIn) {
                // already logged in
                history.replace("/study");
            } else {
                setState("WORKING");
                userStudy.completeLogin(location.search).then(() => {
                    setState("SUCCESS");
                }, (e) => {
                    console.log(e);
                    setState("FAILED");
                });
            }
        }
    }, [history, location.search, state, userStudy]);

    useEffect(() => {
        if (state === "SUCCESS") {
            const t = setTimeout(() => {
                history.replace("/study");
            }, 1000);
            return () => clearTimeout(t);
        }
    }, [history, state]);

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="user-study-complete-login">
            <div className="status-panel">
                { state === "WORKING" ? <>
                    <img className="status-icon invert-dark working" src={refreshIcon} />
                    <span className="status-message">Logging you in…</span>
                </> : null }
                { state === "FAILED" ? <>
                    <img className="status-icon invert-dark failed" src={errorIcon} />
                    <span className="status-message">Sorry, something went wrong.<br />Check your internet connection and try again later.<br />If the issue persists, let us know.</span>
                </> : null }
                { state === "SUCCESS" ? <>
                    <img className="status-icon invert-dark success" src={successIcon} />
                    <span className="status-message">Logged in!</span>
                </> : null }
            </div>
        </Container>
    </ScrollContainer>;
};

import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

export const RatingControl: React.FC<{
    rating: number | null,
    onChange: (rating: number | null) => void,
    ratings: { value: number, label: string }[],
    disabled?: boolean
}> = ({ rating, onChange, ratings, disabled }) => {
    return <ButtonGroup className="rating-control">
        { ratings.map((r, i) => <Button key={i} active={r.value === rating} variant={r.value === rating ? "primary" : "secondary"} disabled={disabled} onClick={() => {
            if (r.value === rating) onChange(null);
            else onChange(r.value);
        }}>{ r.label }</Button>) }
    </ButtonGroup>;
};

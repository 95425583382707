import { InteractiveElementDefinition, InteractiveComponent } from "../../hybrid-documents-reader-core/interactive/InteractiveElement";

const interactiveElementDefinitions: { [id: string]: InteractiveElementDefinition } = {
    "proving-bisimilarity-simple": {
        title: "Proving Bisimilarity – Round 1",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/gx1mahy6p3ovmdfoen6n
            // right: https://pseuco.com/#/edit/remote/uhkvdjces4xydvfw53q2
            const ltsLeftImport = (await import('./provingBisimilarity/lts-simple-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-simple-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "C" }, {
                showFirstTimeHints: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Proving", maximalProgress: 9 }
            ]
        }
    },
    "proving-bisimilarity-harder": {
        title: "Proving Bisimilarity – Round 2",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/ak6xwzxpsitqzpdzjh8b
            // right: https://pseuco.com/#/edit/remote/qnp3i2cx64h2v383nd2w
            const ltsLeftImport = (await import('./provingBisimilarity/lts-harder-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-harder-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "F" }, {
                suggestBuildingRelationFirst: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Proving", maximalProgress: 15 }
            ]
        }
    },
    "proving-bisimilarity-impossible": {
        title: "Proving Bisimilarity – The Impossible One",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/50n6omyiynp5109008cd
            // right: https://pseuco.com/#/edit/remote/4o3n9rk1mvwj6ei3wdso
            const ltsLeftImport = (await import('./provingBisimilarity/lts-not-bisimilar-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-not-bisimilar-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "D" }, {
                showFirstTimeHints: false,
                solvedOnConflicts: [
                    { left: "B", right: "E" },
                    { left: "B", right: "F" },
                ]
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Trying", maximalProgress: 2 }
            ]
        }
    },
    "disproving-bisimilarity-non-branching": {
        title: "Playing the Game, Round 1",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/50n6omyiynp5109008cd
            // right: https://pseuco.com/#/edit/remote/4o3n9rk1mvwj6ei3wdso
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-no-swap-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-no-swap-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A1", right: "B1" }, {
                noIntermediateSideSwaps: true,
                defenderHeuristicRelation: [
                    { left: "A1", right: "B1" },
                    { left: "A3", right: "B2" },
                    { left: "A6", right: "B3" },
                    { left: "A7", right: "B4" },
                    { left: "A3", right: "B5" },
                    { left: "A6", right: "B6" }
                ]
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "disproving-bisimilarity-simple": {
        title: "Playing the Game, Round 2",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/50n6omyiynp5109008cd
            // right: https://pseuco.com/#/edit/remote/4o3n9rk1mvwj6ei3wdso
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-no-swap-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-no-swap-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A1", right: "B1" }, {
                noIntermediateSideSwaps: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "disproving-bisimilarity-harder": {
        title: "Playing the Game, Round 3",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/21fq5rgoqmjgcvthaleq
            // right: https://pseuco.com/#/edit/remote/kvhdc4z5uf7u3fwv393z
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-swap-required-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-swap-required-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A1", right: "B1" }, {});
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "disproving-bisimilarity-weak-simple": {
        title: "Playing the Game, Weak Edition",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/g5eydb6tys7vi9tsn9il
            // right: https://pseuco.com/#/edit/remote/qanuqi1vgh0ywm5xpzsm
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-weak-simple-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-weak-simple-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "D" }, {
                weak : true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "disproving-bisimilarity-weak-attacker-steps": {
        title: "Playing the Game, Weak Strategies",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/mj12ztqgdyf585efb3ed
            // right: https://pseuco.com/#/edit/remote/pl0efekq3hwmztq5w0og
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-weak-attacker-steps-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-weak-attacker-steps-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "E" }, {
                weak : true,
                weakAttackerSteps: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "disproving-observation-congruence-simple": {
        title: "Playing the Game – Observation Congruence Edition",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/9n6anjpniu64wgfnbjig
            // right: https://pseuco.com/#/edit/remote/ynfb48ra5jw2upii0g1s
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-observation-congruence-simple-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-observation-congruence-simple-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "D" }, {
                weak : true,
                observationCongruent: true,
                weakAttackerSteps: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "isomorphism-roulette": {
        title: "Isomorphism Roulette",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import('./isomorphismRoulette/isomorphismRoulette')).default;
            return builder([
                [
                    [
                        await import('./isomorphismRoulette/lts-1-1-1.json'),
                        await import('./isomorphismRoulette/lts-1-1-2.json')
                    ],
                    [
                        await import('./isomorphismRoulette/lts-1-2-1.json'),
                        await import('./isomorphismRoulette/lts-1-2-2.json')
                    ],
                    [
                        await import('./isomorphismRoulette/lts-1-3-1.json'),
                        await import('./isomorphismRoulette/lts-1-3-2.json')
                    ],
                    [
                        await import('./isomorphismRoulette/lts-1-4-1.json'),
                        await import('./isomorphismRoulette/lts-1-4-2.json'),
                        await import('./isomorphismRoulette/lts-1-4-3.json')
                    ],
                    [
                        await import('./isomorphismRoulette/lts-1-5-1.json'),
                        await import('./isomorphismRoulette/lts-1-5-2.json')
                    ]
                ],
                [
                    [
                        await import('./isomorphismRoulette/lts-2-1-1.json'),
                        await import('./isomorphismRoulette/lts-2-1-2.json'),
                        await import('./isomorphismRoulette/lts-2-1-3.json')
                    ],
                    [
                        await import('./isomorphismRoulette/lts-2-2-1.json'),
                        await import('./isomorphismRoulette/lts-2-2-2.json')
                    ],
                    [
                        await import('./isomorphismRoulette/lts-2-3-1.json'),
                        await import('./isomorphismRoulette/lts-2-3-2.json')
                    ],
                    [
                        await import('./isomorphismRoulette/lts-2-4-1.json'),
                        await import('./isomorphismRoulette/lts-2-4-2.json')
                    ]
                ]
            ]);
        },
        liveSessionVisualizationOptions: {
            stages: [
                { maximalProgress: 10, displayName: "Roulette" }
            ]
        }
    },
    "trace-equivalent-vending-machines": {
        title: "Getting Some Drinks",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsLeftImport = (await import('./vendingMachines/vending-good.json')).default;
            const ltsRightImport = (await import('./vendingMachines/vending-bad.json')).default;

            const builder = (await import("./vendingMachines/vendingMachines")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "Holger's Drinks", right: "Felix' Refreshment Store" }, {
                checkTraces: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Testing", maximalProgress: 1 },
                { displayName: "Analyzing", maximalProgress: 1 }
            ]
        }
    },
    "relevant-tau-vending-machines": {
        title: "Getting Some Drinks, Weak Edition",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsLeftImport = (await import('./vendingMachines/vending-good.json')).default;
            const ltsRightImport = (await import('./vendingMachines/vending-timed.json')).default;

            const builder = (await import("./vendingMachines/vendingMachines")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "Holger's Drinks", right: "Gregory's Cold Beverages" }, {
                checkTauRelevance: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Testing", maximalProgress: 1 },
                { displayName: "Analyzing", maximalProgress: 1 }
            ]
        }
    },
    "proving-bisimilarity-weak": {
        title: "Proving Bisimilarity – Let's Get Weak",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsLeftImport = (await import('./provingBisimilarity/lts-weak-bisimilar-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-weak-bisimilar-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "D" }, {
                weak: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Proving", maximalProgress: 6 }
            ]
        }
    },
    "proving-observation-congruence-simple": {
        title: "Proving Observation Congruence",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/11fxjno3w4mmhavv0kmn
            // right: https://pseuco.com/#/edit/remote/m1udyswqlyht1bpf49a2
            const ltsLeftImport = (await import('./provingBisimilarity/lts-observation-congruence-simple-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-observation-congruence-simple-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "E" }, {
                weak: true,
                observationCongruent: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Proving", maximalProgress: 18 }
            ]
        }
    },
    "proving-bisimilarity-weak-is-no-congruence-example": {
        title: "Highway to Hell, Step 1",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsLeftImport = (await import('./provingBisimilarity/lts-weak-is-no-congruence-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-weak-is-no-congruence-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "b!.(0)", right: "τ.(b!.(0))" }, {
                weak: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Proving", maximalProgress: 4 }
            ]
        }
    },
    "disproving-bisimilarity-weak-is-no-congruence-example": {
        title: "Highway to Hell, Step 2",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-weak-is-no-congruence-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-weak-is-no-congruence-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "a!.(0) + b!.(0)", right: "a!.(0) + τ.(b!.(0))" }, {
                weak : true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "strong-bisimilarity-minimization": {
        title: "Minimizing – Step by Step",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsImport = (await import('./minimization/first-minimization-lts.json')).default;

            const builder = (await import("./minimization/minimization")).default;
            return builder(ltsImport, {
                preferredRepresentatives: ["A", "B", "C", "D"],
                transitionPositionOverrides: [
                    { source: "A", label: "c", target: "B", x: 120, y: 90 }
                ]
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Partitioning", maximalProgress: 4 }, // number of states in final LTS
                { displayName: "Reconstructing", maximalProgress: 7 }, // number of transitions after reconstructions plus 1
            ]
        }
    },
    "strong-bisimilarity-minimization-harder": {
        title: "Minimizing – Advanced Edition",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsImport = (await import('./minimization/minimization-harder-lts.json')).default;

            const builder = (await import("./minimization/minimization")).default;
            return builder(ltsImport, {
                preferredRepresentatives: ["A0", "A6", "A3", "A4"],
                transitionPositionOverrides: [
                    { source: "A6", label: "a", target: "A0", x: 230, y: 150 },
                    { source: "A6", label: "c", target: "A6", x: 380, y: 220 },
                    { source: "A0", label: "a", target: "A4", x: 80, y: 80 },
                ]
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Partitioning", maximalProgress: 4 }, // number of states in final LTS
                { displayName: "Reconstructing", maximalProgress: 9 }, // number of transitions after reconstructions plus 1
            ]
        }
    },
    "observation-congruence-minimization": {
        title: "Minimizing – Observation Congruence Edition",
        getComponent: async (): Promise<InteractiveComponent> => {
            // https://pseuco.com/#/edit/remote/0rbzm77jtwd43llthpow
            const ltsImport = (await import('./minimization/observation-congruent-minimization-lts.json')).default;

            const builder = (await import("./minimization/minimization")).default;
            return builder(ltsImport, {
                preferredRepresentatives: ["B", "E", "F"],
                transitionPositionOverrides: [
                    { source: "E", label: "τ", target: "E", x: 400, y: 50 }
                ],
                weak: true,
                observationCongruent: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Partitioning", maximalProgress: 4 }, // number of states in final LTS
                { displayName: "Reconstructing", maximalProgress: 10 }, // number of transitions after reconstructions plus 1
                { displayName: "Reducing", maximalProgress: 5 }, // number of transitions in final LTS
                { displayName: "initial τ", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-sequential-hello-world": {
        title: "Hello World!",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/sequentialHelloWorld/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "uncoordinated-shared-memory-hello-world": {
        title: "Venturing Into the Dark",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/uncoordinatedSharedMemoryHelloWorld/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-message-passing-hello-world": {
        title: "Hello Message Passing World!",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/messagePassingHelloWorld/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-message-passing-hello-world-async": {
        title: "Hello Message Passing World, Asynchronous Edition",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/messagePassingHelloWorldAsync/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-message-passing-select-case-termination": {
        title: "Adding Termination",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/messagePassingSelectCaseTermination/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-shared-memory-locked-counting": {
        title: "Concurrent Counting with Locks",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/sharedMemoryLockedCounting/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "message-passing-prime-producer-factorial-consumer": {
        title: "Producing & Consuming Primes",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/messagePassingPrimeProducerFactorialConsumer/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "message-passing-pipelining-prime-sieve": {
        title: "Producing & Consuming Primes",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/messagePassingPipeliningPrimeSieve/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-shared-memory-hidden-data-race": {
        title: "Hide & Seek: Data Races in Arrays and Structs",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/sharedMemoryHiddenDataRace/config")).default;
            return builder(config);
        },
        height: 400,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-shared-memory-locked-message-box": {
        title: "MessageBox, our First Hand-Written Monitor",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/sharedMemoryLockedMessageBox/config")).default;
            return builder(config);
        },
        height: 600,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "pseuco-shared-memory-monitor-message-box": {
        title: "MessageBox goes Monitor",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./pseuCoProgramming/pseuCoProgramming")).default;
            const config = (await import("./pseuCoProgramming/exercises/sharedMemoryMonitorMessageBox/config")).default;
            return builder(config);
        },
        height: 600,
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Programming", maximalProgress: 1 }
            ]
        }
    },
    "proving-bisimilarity-workout-1": {
        title: "Workout: Proving Bisimilarity",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/it5l843sxdrveinsraxt
            // right: https://pseuco.com/#/edit/remote/tppsl0z1dzynni182qjk
            const ltsLeftImport = (await import('./provingBisimilarity/lts-workout-1-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-workout-1-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "F" }, {
                suggestBuildingRelationFirst: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Proving", maximalProgress: 24 }
            ]
        }
    },
    "disproving-bisimilarity-workout-1": {
        title: "Workout: Playing the Game",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/5840gaqiqtjwkjrmi4rp
            // right: https://pseuco.com/#/edit/remote/v3h9qz01owc1mj1kzrx3
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-workout-1-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-workout-1-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A1", right: "B1" }, {});
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "strong-bisimilarity-minimization-workout-1": {
        title: "Workout: Minimization",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ltsImport = (await import('./minimization/minimization-workout-1.json')).default; // https://pseuco.com/#/edit/remote/zw2cwl9zqfuttgc6gxlk

            const builder = (await import("./minimization/minimization")).default;
            return builder(ltsImport, {
                preferredRepresentatives: ["A", "B", "C", "F"]
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Partitioning", maximalProgress: 4 }, // number of states in final LTS
                { displayName: "Reconstructing", maximalProgress: 9 }, // number of transitions after reconstructions plus 1
            ]
        }
    },
    "proving-weak-bisimilarity-workout-1": {
        title: "Workout: Proving Weak Bisimilarity",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/ujhgxgz43gu04xnfln4l
            // right: https://pseuco.com/#/edit/remote/t5fb369r0mzwnpncid7x
            const ltsLeftImport = (await import('./provingBisimilarity/lts-workout-weak-1-left.json')).default;
            const ltsRightImport = (await import('./provingBisimilarity/lts-workout-weak-1-right.json')).default;

            const builder = (await import("./provingBisimilarity/provingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "G" }, {
                suggestBuildingRelationFirst: true,
                weak: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Proving", maximalProgress: 29 }
            ]
        }
    },
    "disproving-weak-bisimilarity-workout-1": {
        title: "Workout: Disproving Weak Bisimilarity",
        getComponent: async (): Promise<InteractiveComponent> => {
            // left: https://pseuco.com/#/edit/remote/gp3niwggozewtdftf95x
            // right: https://pseuco.com/#/edit/remote/p8vnzkket66icumi177i
            const ltsLeftImport = (await import('./disprovingBisimilarity/lts-workout-weak-1-left.json')).default;
            const ltsRightImport = (await import('./disprovingBisimilarity/lts-workout-weak-1-right.json')).default;

            const builder = (await import("./disprovingBisimilarity/disprovingBisimilarity")).default;
            return builder({ left: ltsLeftImport, right: ltsRightImport }, { left: "A", right: "I" }, {
                weak : true,
                weakAttackerSteps: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Playing", maximalProgress: 1 }
            ]
        }
    },
    "observation-congruence-minimization-workout-1": {
        title: "Workout: Minimizing up to Observation Congruence",
        getComponent: async (): Promise<InteractiveComponent> => {
            // https://pseuco.com/#/edit/remote/g6hlm1d1msb1uzydz7c4
            const ltsImport = (await import('./minimization/observation-congruent-workout-1-lts.json')).default;

            const builder = (await import("./minimization/minimization")).default;
            return builder(ltsImport, {
                preferredRepresentatives: ["C", "F", "G", "H", "I"],
                transitionPositionOverrides: [],
                weak: true,
                observationCongruent: true
            });
        },
        liveSessionVisualizationOptions: {
            stages: [
                { displayName: "Partitioning", maximalProgress: 5 }, // number of states in final LTS
                { displayName: "Reconstructing", maximalProgress: 11 }, // number of transitions after reconstructions plus 1
                { displayName: "Reducing", maximalProgress: 7 }, // number of transitions in final LTS
                { displayName: "initial τ", maximalProgress: 1 }
            ]
        }
    }, "ccs-0-first-example-1": {
        title: "SOS rules: Our Very First Step",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "light.(burn.extinguish.0 + smolder.0)";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccs0", solutionMode: { type: "finish-the-tree" } });
        }
    }, "ccs-0-first-example-2": {
        title: "SOS rules: The First Branch",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "burn.extinguish.0 + smolder.0";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccs0", solutionMode: { type: "find-enough-transitions", minTransitionsToFind: 2 } });
        }
    }, "ccs-0-first-example-3": {
        title: "SOS rules: This is Getting Easy",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "extinguish.0";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccs0", solutionMode: { type: "finish-the-tree" } });
        }
    }, "ccs-0-first-example-4": {
        title: "SOS rules: What‽",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "0";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccs0", solutionMode: { type: "open-the-rule-picker" } });
        }
    }, "ccs-0-lts-reconstruction": {
        title: "LTS Construction: The Full Monty",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "a.(b.(0) + a.(0 + 0) + 0) + b.(0) + a.(0 + 0)";
            const lts = (await import('./ccsSemantics/ccs-0.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccs0" });
        }
    }, "ccs-0-omega-lts-reconstruction": {
        title: "LTS Construction: Recursion Ahoy!",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "BurningMatch := burn . BurningMatch + extinguish.0 \n strike . (BurningMatch + smolder.0)";
            const lts = (await import('./ccsSemantics/ccs-0-omega.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccs0omega" });
        }
    }, "ccs-0-omega-infinite-recursion": {
        title: "Left, Left, Left, …",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "X := X + a.X \n X";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccs0omega", solutionMode: { type: "reach-number-of-steps", stepCount: 3, onlyCountRuleApplications: ["choiceL"] }, customInstruction: "To finish the exercise, repeatedly attempt to use the choice_l rule whenever possible." });
        }
    }, "ccs-0-omega-infinite-recursion-lts-reconstruction": {
        title: "Keep to the Right",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "X := X + a.X \n X";
            const lts = (await import('./ccsSemantics/ccs-0-omega-infinite-recursion.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccs0omega" });
        }
    }, "ccs-parallel-execution-with-ccs-0-omega": {
        title: "Manually Building Parallel Execution in CCS",
        getComponent: async (): Promise<InteractiveComponent> => {
            const builder = (await import("./ccsProgramming/ccsProgramming")).default;
            const config = (await import("./ccsProgramming/exercises/ccsParallelExecutionWithCCS0Omega/config")).default;
            return builder(config);
        },
        height: 400
    }, "ccs-deriving-restricted-transitions": {
        title: "Let's do something illegal!",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "User := coin! . coffee? . morning! . 0 \n Machine := coin? . coffee! . Machine \n (User | Machine) \\ {coin, coffee}";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccs", solutionMode: { type: "attempt-to-reveal-failed-rule-application" }, customInstruction: "To finish the exercise, attempt to derive a transition without using synchronization." });
        }
    }, "ccs-sync-vending-machine-user": {
        title: "One coffee, please!",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "User := coin! . coffee? . morning! . 0 \n Machine := coin? . coffee! . Machine \n (User | Machine) \\ {coin, coffee}";
            const lts = (await import('./ccsSemantics/ccs-sync-vending-machine-user.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccs" });
        }
    }, "ccs-prefix-recursion-below-parallel": {
        title: "…and beyond!",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "X := 0 | a! . X \n X";
            const lts = (await import('./ccsSemantics/ccs-prefix-recursion-below-parallel.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccs" });
        }
    }, "ccs-prefix-recursion-below-choice": {
        title: "Infinitely Unhelpful",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "X := 0 + a! . X \n X";
            const lts = (await import('./ccsSemantics/ccs-prefix-recursion-below-choice.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccs" });
        }
    }, "ccs-infinite-branching": {
        title: "Sheer Infinite Fun",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "X := X | a!.0 \n X";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccs", solutionMode: { type: "find-enough-transitions", minTransitionsToFind: 3 } });
        }
    }, "ccs-vp-first-passed-value": {
        title: "I'll pass!",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "(a!(1 + 1) . 0 | a?(2) . 0) \\ {a}";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccsvpz", solutionMode: { type: "finish-the-tree" }, customInstruction: "Derive a transition – and keep your hands and feet off any inference rule you have not seen before!" });
        }
    }, "ccs-vp-first-input": {
        title: "Value Lottery",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "a?x . 0";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccsvpz", solutionMode: { type: "find-enough-transitions", minTransitionsToFind: 3 } });
        }
    }, "ccs-vp-first-input-sync": {
        title: "Guesstimator 3000",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "(a?x . 0 | a!42 . 0) \\ {a}";

            const builder = (await import("./ccsProofTrees/ccsProofTrees")).default;
            return builder({ ccs, enabledRuleSet: "ccsvpz", solutionMode: { type: "finish-the-tree" } });
        }
    }, "ccs-vp-input-with-replacement": {
        title: "Replacement in Action",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "(a!21 . 0 | a?x . b!(x * 2) . 0) \\ {a}";
            const lts = (await import('./ccsSemantics/ccs-vp-input-with-replacement.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccsvpz" });
        }
    }, "ccs-vp-process-parameter": {
        title: "Replacement in Action",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "Sender[x] := b!x . 0 \n (a!42 . 0 | a?x . Sender[x]) \\ {a}";
            const lts = (await import('./ccsSemantics/ccs-vp-process-parameter.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccsvpz" });
        }
    }, "ccs-vp-coin-counting-coffee-machine-and-user": {
        title: "Coin Counting Coffee Machine & User",
        getComponent: async (): Promise<InteractiveComponent> => {
            const ccs = "Machine[b] := when(b < 5) coin?c . Machine[b + c] + when(b >= 5) coffee! . ReturningMachine[b - 5] \n ReturningMachine[b] := when(b > 0) change! . ReturningMachine[b - 1] + Machine[0] \n User := coin!2 . coin!2 . coin!2 . coffee? . change? . 0 \n (Machine[0] | User) \\ {coin, change, coffee}";
            const lts = (await import('./ccsSemantics/ccs-vp-coin-counting-coffee-machine-and-user.json')).default;

            const builder = (await import("./ccsSemantics/ccsSemantics")).default;
            return builder({ lts, ccs, enabledRuleSet: "ccsvpz", proofTreeAutoRevealStrategy: "easy-and-repetitive-non-vp-rules", prefillRevealModalFromPremise: true });
        }
    },
};

export default interactiveElementDefinitions;

import React, { useEffect, useMemo, useState } from "react";
import { Card, Container, Form, Spinner } from "react-bootstrap";
import { applicationName } from "../../../src/ProductInfo";
import ScrollContainer from "../../ScrollContainer";
import "./SearchPage.scss";
import { Book, Chapter, Part, VerticalBox } from "../../../../hybrid-documents/src/Book";
import { getBook } from "../../book/Book";
import { generateAnchors, renderVerticalToSearchableText } from "../../book/render";
import { useHistory } from "react-router-dom";

type SearchableElement = {
    element: VerticalBox,
    stringContent: string,
    normalizedStringContent: string,
    linkTarget: string,
    chapter: Chapter,
    part: Part
};

type SearchHit = {
    element: SearchableElement,
    stringStartIndex: number,
    stringLength: number
}

type SearchResult = {
    hits: SearchHit[];
    truncated: boolean;
}

const normalizeStringForSearch = (s: string): string => s.normalize("NFKD").replace(/\p{Diacritic}/gu, "").toLowerCase(); // https://stackoverflow.com/a/37511463/926412

/**
 * Tries to truncate a string's end (or start), without breaking words.
 */
const carefullyTruncateString = (s: string, start: boolean) => {
    if (s.length <= 10000) return s; // short enough, shortening does not make sense
    const match = (start ? /\s(\S.{0,80}\S*\s*)$/ : /^(\s*\S*.{0,80}\S)\s/).exec(s);
    if (match && match[1] !== s) return start ? `…${match[1]}` : `${match[1]}…`;
    return s;
};

const search = (elements: SearchableElement[], searchTerm: string, maxResults = 20): SearchResult => {
    const normalizedSearchTerm = normalizeStringForSearch(searchTerm);
    if (normalizedSearchTerm.length <= 0) return { hits: [], truncated: false };

    const hits: SearchHit[] = [];
    let truncated = false;

    outer: for (const element of elements) {
        if (element.normalizedStringContent.includes(normalizedSearchTerm)) {
            // We have a match, but thanks to normalizeStringForSearch() shifting around character positions, we don't know where.
            // But we can now afford to invest more time in a more stringent search.
            for (let startPositionInOriginalString = 0; startPositionInOriginalString < element.stringContent.length; startPositionInOriginalString++) {
                const truncatedOriginalStringContent = element.stringContent.substring(startPositionInOriginalString);
                // We're iterating through the original string's starting positions now (not thew no)
                if (normalizeStringForSearch(truncatedOriginalStringContent).startsWith(normalizedSearchTerm)) {
                    // Now, we know we have a hit starting at candidateStartPosition in the original string.
                    // But how long is it?

                    if (hits.length >= maxResults) {
                        // more than enough results, cancel search
                        truncated = true;
                        break outer;
                    }

                    let hitLengthInOriginalString = normalizedSearchTerm.length; // overapproximation
                    while (hitLengthInOriginalString > -1 && normalizeStringForSearch(truncatedOriginalStringContent.substring(0, hitLengthInOriginalString)).startsWith(normalizedSearchTerm)) hitLengthInOriginalString--;
                    hitLengthInOriginalString++; // return to last value that was truthy
                    if (hitLengthInOriginalString === 0) throw new Error("Internal inconsistency: Failed to identify hit length.");

                    hits.push({
                        element,
                        stringStartIndex: startPositionInOriginalString,
                        stringLength: hitLengthInOriginalString
                    });
                }
            }
        }
    }
    
    return {
        hits,
        truncated
    };
};

export const SearchPage: React.FC<{}> = () => {
    const [searchTermInputValue, setSearchTermInputValue] = useState<string>(""); // value in text box
    const [searchTermWaitingForSearch, setSearchTermWaitingForSearch] = useState<string>(""); // search time that was scheduled for search
    const [activeSearchTerm, setActiveSearchTerm] = useState<string>(""); // value current results are for

    const history = useHistory();

    const [book, setBook] = React.useState<Book | null>(null);
    const [bookLoading, setBookLoading] = React.useState<boolean>(false);
    const [bookError, setBookError] = React.useState<boolean>(false);

    useEffect(() => {
        if (book === null && !bookLoading) {
            setBookLoading(true);
            getBook().then((book) => {
                setBook(book);
            }, () => {
                setBookError(true);
            }).finally(() => setBookLoading(false));
        }
    }, [book, bookLoading]);

    const elements = useMemo(() => {
        if (!book) return null;
        const elements: SearchableElement[] = [];
        book.chapters.forEach((chapter) => {
            chapter.parts.forEach((part) => {
                const anchors = generateAnchors(part);

                part.content.forEach((element, i) => {
                    const stringContent = renderVerticalToSearchableText(element);
                    elements.push({
                        element,
                        stringContent,
                        normalizedStringContent: normalizeStringForSearch(stringContent),
                        linkTarget: `${chapter.id}/${part.id}/${anchors[i].anchorId}`,
                        chapter,
                        part
                    });
                });
            });
        });
        return elements;
    }, [book]);

    const [searchResults, setSearchResults] = useState<SearchResult | null>(null);
    const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (!elements) return; // still loading, nothing to do, re-check when done

        if (searchTermWaitingForSearch === searchTermInputValue) return; // everything is dandy, nothing to do

        setSearchTermWaitingForSearch(searchTermInputValue);
        if (searchTimeout !== null) clearTimeout(searchTimeout);
        setSearchTimeout(setTimeout(() => {
            setSearchTimeout(null);
            if (activeSearchTerm !== searchTermInputValue) {
                setActiveSearchTerm(searchTermInputValue);
                setSearchResults(searchTermInputValue.length ? search(elements, searchTermInputValue) : null);
            }
        }, 250));
    }, [activeSearchTerm, elements, searchTermInputValue, searchTermWaitingForSearch, searchTimeout]);

    const resultBlock = (book === null) ? (
        (bookError) ? <div className="error-container">
            <p><strong>Failed to load book.</strong></p>
            <p>Please check your internet connection.</p>
        </div> : (bookLoading) ? <div className="spinner-container">
            <p>Loading book…</p>
            <Spinner animation="border" />
        </div> : null) : searchResults !== null ? (searchResults.hits.length ? <div className="search-results">
        { searchResults.hits.map((hit, i) => <Card key={i} className="search-result" onClick={ () => history.push(`read/${hit.element.linkTarget}`) }>
            <Card.Body>
                <div className="hit-excerpt">
                    <span>{ carefullyTruncateString(hit.element.stringContent.substring(0, hit.stringStartIndex), true) }</span>
                    <span className="highlight">{ hit.element.stringContent.substring(hit.stringStartIndex, hit.stringStartIndex + hit.stringLength) }</span>
                    <span>{ carefullyTruncateString(hit.element.stringContent.substring(hit.stringStartIndex + hit.stringLength), false) }</span>
                </div>
                <div className="hit-position">
                    in <strong>{ hit.element.chapter.displayName }</strong> / <strong>{ hit.element.part.displayName }</strong>
                </div>
            </Card.Body>
        </Card>)}
        { searchResults.truncated ? <div className="search-results-truncated">—more results hidden—</div> : null }
    </div> : <div className="no-search-results">—no results—</div>) : null;

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="search-page">
            <Form.Control className="search-input-box" placeholder={`Search ${ applicationName }`} value={searchTermInputValue} onChange={(e) => setSearchTermInputValue(e.target.value)} />

            { resultBlock }
        </Container>
    </ScrollContainer>;
};

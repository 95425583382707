import React, { useEffect, useState, useCallback, useMemo, PropsWithChildren } from 'react';
import { Container, Button, Spinner } from 'react-bootstrap';

import "./ReadView.scss";
import { VerticalBox, Part, Chapter } from '../../../../hybrid-documents/src/Book';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getSaveKeyForInteractive, InteractiveElementBoxAPI } from '../../interactive/InteractiveElement';
import GlobalSettingsContext from '../../../src/GlobalSettings';
import classNames from 'classnames';
import { findLastIndex } from '../../util/array';
import { CurrentThemeContext } from '../../ThemeApplicator';
import { ToastContext } from '../../Toaster';
import { generateAnchors, renderVertical } from '../../book/render';
import { InternalNavigationLink, LinkNavigationSupportContext } from './LinkNavigationSupport';

const scrollOffsetFactor = 0.25; // between 0 and 1, position on the page that is considered top for anchor-scrolling
const scrollBufferFactor = 0.05; // between 0 and 1, bonus amount we scroll down such that minimal scroll-up or jitter does not select previous section

export type NavigationTarget = {
    displayName: string;
    route: string;
};

export type Navigation = {
    prevPart: NavigationTarget | null;
    nextPart: NavigationTarget | null;
};


const HoverAnchor: React.FC<PropsWithChildren<{ path: string | null, highlighted?: boolean}>> = ({ path, highlighted, children }) => {
    if (path === null) return <>{ children }</>;
    const to = `/read/${path}`;
    return <div className={`hover-anchor ${highlighted ? "highlighted" : ""}`}>
        <div className="anchor-icon"><InternalNavigationLink to={to}>🔗</InternalNavigationLink></div>
        { children }
    </div>;
};

export const ReadView: React.FC<{
    chapterId: string;
    partId: string;
    chapter: Chapter;
    part: Part;
    anchorId: string | null;
    onScrolledToAnchor: (anchorId: string | null, sectionId: string | null) => void;
    navigation: Navigation;
}> = ({ chapterId, partId, chapter, part, anchorId, onScrolledToAnchor, navigation }) => {
    const settings = React.useContext(GlobalSettingsContext);
    const theme = React.useContext(CurrentThemeContext);
    const toaster = React.useContext(ToastContext);

    const location = useLocation();
    const currentLocation = location.pathname;
    const history = useHistory();

    const [currentlyScrolledAnchorId, setCurrentlyScrolledAnchorId] = React.useState<string | null>(null);

    const [anchorMeasurements, setAnchorMeasurements] = React.useState<{ offset: number }[]>([]);
    const [anchorVisibilities, setAnchorVisibilities] = React.useState<boolean[]>([]);

    const [hideSpoilers, setHideSpoilers] = React.useState<boolean>(true);

    const contentContainerRef = React.useRef<HTMLDivElement>(null);

    const interactiveElementRefs: React.Ref<InteractiveElementBoxAPI>[] = useMemo(() => [], []);

    const [allInteractiveElementsLoaded, setAllInteractiveElementsLoaded] = useState<boolean>(false);
    const [elementHeightsChanging, setElementHeightsChanging] = useState<boolean>(false);

    // the anchor index of the element that should get the brief highlight indicating that it's what we scrolled to
    const [highlightedScrollTargetAnchorIndex, setHighlightedScrollTargetAnchorIndex] = useState<null | number>(null);

    useEffect(() => {
        if (highlightedScrollTargetAnchorIndex !== null) {
            const timeout = setTimeout(() => setHighlightedScrollTargetAnchorIndex(null), 4000);
            return () => clearTimeout(timeout);
        }
    });

    const updateInteractiveLoadStatus = useCallback((): void => {
        let allLoaded = true;
        interactiveElementRefs.forEach((ref) => {
            if (ref && "current" in ref && ref.current) {
                if (!ref.current.loaded) allLoaded = false;
            }
        });
        if (allLoaded !== allInteractiveElementsLoaded) {
            setElementHeightsChanging(true);
            setAllInteractiveElementsLoaded(allLoaded);
            setTimeout(() => setElementHeightsChanging(false));
        }
    }, [allInteractiveElementsLoaded, interactiveElementRefs]);

    useEffect((): void => {
        updateInteractiveLoadStatus(); // update the status now (if needed)
    }, [partId, chapterId, updateInteractiveLoadStatus]);

    const anchors = useMemo(() => generateAnchors(part), [part]);

    const linkNavigation = (target: string) => {
        history.push(target);

        if (currentLocation === target) {
            const currentAnchorIndex = anchors.findIndex((a) => a.anchorId === currentlyScrolledAnchorId);
            setHighlightedScrollTargetAnchorIndex(currentAnchorIndex);
        }
    };

    /**
     * Renders a vertical element, with hover anchor behavior.
     * @param element The element to be rendered.
     * @param index The index of the element. This is usually an array of length one. When the function is called recursively, to render more complex nested elements like lists, it becomes a longer array, with the last element being the current index (so e.g. [3, 7] indicates we are within a list or something similiar with index 3, and our subelement has index 7).
     */
    const renderVerticalAnchorBox = (element: VerticalBox, index: number[]): React.ReactElement => {
        const toplevel = index.length === 1;

        const id = toplevel ? anchors[index[0]].htmlId : `vertical-subelement-${index.map((i) => i.toString().padStart(3, "0")).join("-")}`;
        const key = index[index.length - 1];

        const highlighted = toplevel && index[0] === highlightedScrollTargetAnchorIndex;

        const elementPath = toplevel ? `${chapterId}/${partId}/${anchors[index[0]].anchorId}` : null;

        return <HoverAnchor key={key} path={elementPath} highlighted={highlighted}>
            { renderVertical(element, index, id, theme, interactiveElementRefs, anchorVisibilities, updateInteractiveLoadStatus) }
        </HoverAnchor>;
    };

    const measureAnchors = (container: HTMLElement): { offset: number }[] => { // returns array mapping vertical element id to scroll offset
        return anchors.map((anchor) => {
            const anchorElement = container.querySelector(`#${anchor.htmlId}`) as HTMLElement;
            if (anchorElement == null) throw new Error(`Failed to find element ${anchor.htmlId} for anchor ${anchor.anchorId}.`);
    
            let offset = 0;
            let element = anchorElement;
            while (!element.classList.contains("read-container")) {
                offset += element.offsetTop;
                const parent = element.parentElement;
                if (!parent) throw new Error("Failed to find container while measuring anchors.");
                element = parent;
            }
    
            return {
                offset
            };
        });
    };
    
    const updateAnchorVisibilities = useCallback(() => {
        const container = contentContainerRef.current;
        if (container === null) throw new Error("missing container ref");
    
        const newAnchorVisibilities: boolean[] = [];
        for (let i = 0; i < anchorMeasurements.length; i++) {
            const last = i === anchorMeasurements.length - 1;
            const notTooLow = container.scrollTop + container.clientHeight >= anchorMeasurements[i].offset;
            const notTooHigh = last || container.scrollTop <= anchorMeasurements[i + 1].offset;
            newAnchorVisibilities.push(notTooLow && notTooHigh);
        }
        if (JSON.stringify(newAnchorVisibilities) !== JSON.stringify(anchorVisibilities)) setAnchorVisibilities(newAnchorVisibilities);
    }, [anchorMeasurements, anchorVisibilities]);

    // ↓ not a problem because we only update if needed
    // ↓ (we need to re-run this even if no props change as the content could expand / collapse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        const container = contentContainerRef.current;
        if (container === null) throw new Error("missing container ref");
    
        const measurements = measureAnchors(container);

        if (JSON.stringify(measurements) !== JSON.stringify(anchorMeasurements)) setAnchorMeasurements(measurements);

        updateAnchorVisibilities();
    });

    const [scrollToTargetWasEverFinished, setScrollToTargetWasEverFinished] = useState<boolean>(false);

    const scrollToAnchorIndex = useCallback((anchorIndex: number): void => {
        const container = contentContainerRef.current;
        if (container === null) throw new Error("missing container ref");

        const measurement = anchorMeasurements[anchorIndex];
        if (!measurement) return; // can't scroll there now, but we'll get another chance once measurements are in
        const scrollOffset = Math.floor(scrollOffsetFactor * container.clientHeight);
        const scrollBuffer = Math.floor(scrollBufferFactor * container.clientHeight);
        container.scrollTo({ top: measurement.offset - scrollOffset + scrollBuffer, behavior: scrollToTargetWasEverFinished ? "smooth" : "instant" });
        setCurrentlyScrolledAnchorId(anchorId);
        onScrolledToAnchor(anchorId, anchors[anchorIndex].sectionId);
        setHighlightedScrollTargetAnchorIndex(anchorIndex);
    }, [anchorId, anchorMeasurements, anchors, onScrolledToAnchor, scrollToTargetWasEverFinished]);

    React.useEffect(() => {
        const container = contentContainerRef.current;
        if (container === null) throw new Error("missing container ref");
    
        if (anchorId === null && currentlyScrolledAnchorId !== null) {
            // we navigated to a new chapter (or deleted the section id from the URL), but are scrolled down
            container.scrollTo({ top: 0 });
            setCurrentlyScrolledAnchorId(null);
        }

        if (anchorId !== null && anchorId !== currentlyScrolledAnchorId) {
            if (!allInteractiveElementsLoaded || elementHeightsChanging) return; // defer the scroll until element positions have stabilized

            // we need to scroll
            const anchorIndex = anchors.findIndex(({ anchorId: a }) => a === anchorId);
            if (anchorIndex < 0) {
                if (anchorId.length) {
                    console.log(`Anchor ${anchorId} not found, cannot scroll there.`);
                    if (!scrollToTargetWasEverFinished) {
                        setScrollToTargetWasEverFinished(true); // well, it's not really finished, but we've given up – this gets rid of the "Preparing page..." spinner.
                        toaster.toast("error", "Invalid Link", "The link you followed refers to a section or element that does not exist.", 10000);
                    }
                }
            }
            
            scrollToAnchorIndex(anchorIndex);
        }
    }, [chapterId, partId, anchorId, currentlyScrolledAnchorId, anchorMeasurements, anchors, onScrolledToAnchor, allInteractiveElementsLoaded, elementHeightsChanging, scrollToAnchorIndex, toaster, scrollToTargetWasEverFinished]);

    const firstSpoilerIndex = part.content.findIndex((ve) => {
        switch (ve.type) {
            case "quiz":
            case "interactive": {
                const solved = settings.current.interactiveCompletion[getSaveKeyForInteractive(ve.type, ve.id)];
                return ve.spoiler && !solved;
            }
            default:
                return false;
        }
    });
    const spoilerFreeContent = firstSpoilerIndex < 0 ? part.content : part.content.slice(0, firstSpoilerIndex + 1);
    const spoilerContent = firstSpoilerIndex < 0 ? [] : part.content.slice(firstSpoilerIndex + 1);

    const [isScrolledIntoSpoilerTerritory, setIsScrolledIntoSpoilerTerritory] = useState<boolean>(false);

    const jumpToSpoilerInteractive = (): void => {
        scrollToAnchorIndex(firstSpoilerIndex);
    };

    const updateScrolledIntoSpoilersState = useCallback(() => {
        const container = contentContainerRef.current;
        if (container === null) throw new Error("missing container ref");

        const newIsScrolledIntoSpoilerTerritory = firstSpoilerIndex >= 0 && hideSpoilers && container.scrollTop > anchorMeasurements[firstSpoilerIndex + 1]?.offset - 100; // 100 pixel grace period, i.e. this is true if less than 100 pixels of the first unsolved exercise are visible
        if (newIsScrolledIntoSpoilerTerritory !== isScrolledIntoSpoilerTerritory) setIsScrolledIntoSpoilerTerritory(newIsScrolledIntoSpoilerTerritory);
    }, [anchorMeasurements, firstSpoilerIndex, hideSpoilers, isScrolledIntoSpoilerTerritory]);

    const onScroll = useCallback((): void => {
        const container = contentContainerRef.current;
        if (container === null) throw new Error("missing container ref");

        const scrollOffset = Math.floor(scrollOffsetFactor * container.clientHeight);

        const matchingAnchorIndex = container.scrollTop === 0 ? -1 : findLastIndex(anchorMeasurements, ({ offset }) => offset - scrollOffset <= container.scrollTop);

        const matchingAnchorId = matchingAnchorIndex === -1 ? null : anchors[matchingAnchorIndex].anchorId;
        const section = matchingAnchorIndex === -1 ? null : anchors[matchingAnchorIndex].sectionId;

        if (currentlyScrolledAnchorId !== matchingAnchorId) {
            if (container.scrollTop + container.clientHeight === container.scrollHeight) return; // ignore scroll position if we are at the bottom, to allow short last sections to be selected properly
            setCurrentlyScrolledAnchorId(matchingAnchorId);
            onScrolledToAnchor(matchingAnchorId, section);
        }

        updateScrolledIntoSpoilersState();
        updateAnchorVisibilities();
    }, [anchorMeasurements, anchors, currentlyScrolledAnchorId, onScrolledToAnchor, updateAnchorVisibilities, updateScrolledIntoSpoilersState]);

    useEffect(() => {
        // changing the hideSpoilers flag need to update 
        updateScrolledIntoSpoilersState();
    }, [hideSpoilers, updateScrolledIntoSpoilersState]);

    const scrollToTargetPending = anchorId !== null && anchorId !== currentlyScrolledAnchorId;
    if (!scrollToTargetPending && !scrollToTargetWasEverFinished) setScrollToTargetWasEverFinished(true);
    const showScrollPendingOverlay = scrollToTargetPending && !scrollToTargetWasEverFinished;

    return <div className="read-view" ref={contentContainerRef} onScroll={onScroll}>
        { showScrollPendingOverlay ? <div className="scroll-loading-overlay">
            <div className="spinner-container">
                <p>Preparing page…</p>
                <Spinner animation="border" />
            </div>
        </div> : null }
        <LinkNavigationSupportContext.Provider value={linkNavigation}>
            <Container className={classNames({ "read-container": true, "scroll-pending": showScrollPendingOverlay })}>
                <h1 className="fixed-heading">{chapter.displayName}</h1>
                <h2 className="fixed-heading">{part.displayName}</h2>
                {spoilerFreeContent.map((element, index) => renderVerticalAnchorBox(element, [index]))}
                {spoilerContent.length > 0 && hideSpoilers ? <div className="spoiler-warning">
                    <a className="textline" onClick={(): void => setHideSpoilers(false)}>— content below hidden to prevent spoilers, solve the exercise above or click here to show —</a>
                </div> : null}
                <div className={`spoiler-filter${hideSpoilers ? " enabled" : ""}`}>
                    {spoilerContent.map((element, index) => renderVerticalAnchorBox(element, [index + firstSpoilerIndex + 1]))}
                </div>
                <div className="chapter-footer">
                    <div className="end-marker">— end of section —</div>
                    <div className="chapter-navigation">
                        { navigation.prevPart === null ? null : <Link to={navigation.prevPart.route}>
                            <Button className="previous-chapter" variant="secondary"><span className="nav-icon">◀</span><span>{navigation.prevPart.displayName}</span></Button>
                        </Link> }
                        { navigation.nextPart === null ? null : <Link to={navigation.nextPart.route}>
                            <Button className="next-chapter float-end" variant="primary"><span>{navigation.nextPart.displayName}</span><span className="nav-icon">▶</span></Button>
                        </Link> }
                    </div>
                    <div className="chapter-footer-clearfix"></div>
                </div>
                <div className={classNames({ "spoiler-floater": true, active: isScrolledIntoSpoilerTerritory })}>
                    <div>
                        Content hidden to prevent spoilers.
                    </div>
                    <div>
                        <Button className="jump-to-interactive" size="sm" onClick={jumpToSpoilerInteractive}>Jump to Exercise</Button>
                        <Button  size="sm" variant="secondary" onClick={(): void => setHideSpoilers(false)}>Show Content</Button>
                    </div>
                </div>
            </Container>
        </LinkNavigationSupportContext.Provider>
    </div>;
};

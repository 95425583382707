import React, { PropsWithChildren, Fragment } from 'react';
import GlobalSettingsContext from '../src/GlobalSettings';
import { BodyAttributeApplicator } from './BodyAttributeApplicator';

const browserSupportsMatchMedia = window.matchMedia !== undefined;
export const browserSupportsDarkMode = browserSupportsMatchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all';

export type Theme = "light" | "dark";

export const CurrentThemeContext = React.createContext<Theme>("light");

export const ThemeApplicator: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const settings = React.useContext(GlobalSettingsContext);
    
    const [browserWantsDarkMode, setBrowserWantsDarkMode] = React.useState<boolean>(browserSupportsDarkMode && window.matchMedia('(prefers-color-scheme: dark)').matches);

    const [darkModeMediaMatch] = React.useState<MediaQueryList>(window.matchMedia('(prefers-color-scheme: dark)'));

    React.useEffect(() => {
        if (darkModeMediaMatch.addListener !== undefined) {
            const matchEventListener = (newMatch: MediaQueryListEvent): void => {
                setBrowserWantsDarkMode(newMatch.matches);
            };
            darkModeMediaMatch.addListener(matchEventListener);
            return (): void => {
                darkModeMediaMatch.removeListener(matchEventListener);
            };
        }
    }, [darkModeMediaMatch]);

    const useSystemTheme = settings.current.followSystemDarkMode && browserSupportsDarkMode;
    const theme: Theme = (useSystemTheme ? (browserWantsDarkMode ? "dark" : "light") : (settings.current.theme));

    return <Fragment>
        <CurrentThemeContext.Provider value={theme}>
            {children}
        </CurrentThemeContext.Provider>
        <BodyAttributeApplicator attribute="data-theme" value={theme} />
        <BodyAttributeApplicator attribute="data-bs-theme" value={theme} />
    </Fragment>;
};

import React, { useContext } from 'react';
import { Navbar, Nav, Badge, Container } from 'react-bootstrap';

import './App.scss';
import './documentStyles.scss';
import GlobalSettingsContext, { GlobalSettingsProvider } from './GlobalSettings';
import { ThemeApplicator } from '../hybrid-documents-reader-core/ThemeApplicator';
import { ThemeSwitcher } from '../hybrid-documents-reader-core/ThemeSwitcher';
import { HashRouter, Route, Switch, withRouter } from 'react-router-dom';
import AboutPage from './pages/about/AboutPage';
import { LandingPage } from './pages/landing/LandingPage';
import { ReadPage } from '../hybrid-documents-reader-core/pages/read/ReadPage';
import { DebugPage } from './pages/debug/DebugPage';
import { SettingsPage } from './pages/settings/SettingsPage';
import { NotFoundPage } from '../hybrid-documents-reader-core/pages/notfound/NotFoundPage';
import { OopsBoundary } from '../hybrid-documents-reader-core/OopsBoundary';
import { applicationName } from './ProductInfo';
import { InteractivePage } from '../hybrid-documents-reader-core/pages/interactive/InteractivePage';
import { OfflineStatusContextProvider, OfflineStatusContext } from '../hybrid-documents-reader-core/OfflineStatusContext';
import { applyAvailableUpdateNow } from '../hybrid-documents-reader-core/OfflineManagement';
import { UserStudyPage } from './pages/study/UserStudyPage';
import { UserStudyContext, UserStudyManager } from './study/UserStudy';
import { UserStudyCompleteLoginPage } from './pages/study/UserStudyCompleteLoginPage';
import { LiveTeachPage } from './pages/study/live/LiveTeachPage';
import { LivePage } from './pages/study/live/LivePage';
import { Toaster } from '../hybrid-documents-reader-core/Toaster';
import { LiveJoinPage } from './pages/study/live/LiveJoinPage';
import { UserStudySurveyPage } from './pages/study/survey/UserStudySurveyPage';
import { SearchPage } from '../hybrid-documents-reader-core/pages/search/SearchPage';
import { LinkNavigationSupportContextBasicProvider } from '../hybrid-documents-reader-core/pages/read/LinkNavigationSupport';

const Navigation: React.ComponentClass<{}> = withRouter(({ location }: { location: { pathname: string } }) => {
    const locElements = location.pathname.split('/');
    let locElementCount = 2;
    if (locElements[1] === "live") locElementCount = 3;
    const loc = `#${locElements.slice(0, locElementCount).join('/')}`;

    const settings = useContext(GlobalSettingsContext);
    const offlineStatus = useContext(OfflineStatusContext);
    const userStudy = useContext(UserStudyContext);

    return <Navbar bg="dark" variant="dark" expand="md" fixed="top" className="pseuco-navbar" collapseOnSelect>
        <Container fluid>
            <Navbar.Brand href="#/">{applicationName}</Navbar.Brand>
            <Navbar.Toggle aria-controls="pseuco-navbar-nav" />
            <Navbar.Collapse id="pseuco-navbar-nav" className="justify-content-between">
                <Nav activeKey={loc}>
                    <Nav.Link href="#/read">Read</Nav.Link>
                    <Nav.Link href="#/search">Search</Nav.Link>
                    { userStudy.enabled ? <Nav.Link href="#/study">User Study { userStudy.shouldAskForEnrollment ? <Badge pill bg="danger">Join</Badge> : userStudy.shouldAskForSurvey ? <Badge pill bg="danger">1</Badge> :  null }</Nav.Link> : null }
                    { settings.current.userStudyLiveSessionId ? <Nav.Link href="#/live">Live Session</Nav.Link> : null }
                    { userStudy.userRecord?.teacher ? <Nav.Link href="#/live/teach">Teach</Nav.Link> : null }
                </Nav>
                <Nav activeKey={loc}>
                    <ThemeSwitcher className="order-last order-md-first" />
                    { settings.current.showDebugPage ? <Nav.Link href="#/debug">Debug</Nav.Link> : null }
                    <Nav.Link href="#/settings">Settings</Nav.Link>
                    <Nav.Link href="#/about">About / Imprint { offlineStatus.updateReady || offlineStatus.updateApplying ? <Badge pill bg="secondary" onClick={(e: React.MouseEvent<HTMLElement>): void => {
                        applyAvailableUpdateNow();
                        e.preventDefault();
                    }}>{ offlineStatus.updateApplying ? "…" : 1}</Badge> : null }</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>;
});

const App: React.FC<{}> = () => {
    return (
        <React.StrictMode>
            <OopsBoundary>
                <OfflineStatusContextProvider>
                    <GlobalSettingsProvider>
                        <HashRouter>
                            <ThemeApplicator>
                                <Toaster>
                                    <LinkNavigationSupportContextBasicProvider>
                                        <UserStudyManager>
                                            <Navigation />
                                            <OopsBoundary>
                                                <Switch>
                                                    <Route exact path="/" component={LandingPage} />
                                                    <Route path="/read/:chapter?/:part?/:anchor(.*)?" component={ReadPage} />
                                                    <Route path="/search" component={SearchPage} />
                                                    <Route exact path="/study" component={UserStudyPage} />
                                                    <Route exact path="/study/complete-login" component={UserStudyCompleteLoginPage} />
                                                    <Route exact path="/study/survey" component={UserStudySurveyPage} />
                                                    <Route exact path="/live" component={LivePage} />
                                                    <Route exact path="/live/join/:id" component={LiveJoinPage} />
                                                    <Route exact path="/live/teach" component={LiveTeachPage} />
                                                    <Route path="/debug" component={DebugPage} />
                                                    <Route path="/settings" component={SettingsPage} />
                                                    <Route path="/about" component={AboutPage} />
                                                    <Route path="/:type(quiz|interactive)/:id" component={InteractivePage} />
                                                    <Route component={NotFoundPage} />
                                                </Switch>
                                            </OopsBoundary>
                                        </UserStudyManager>
                                    </LinkNavigationSupportContextBasicProvider>
                                </Toaster>
                            </ThemeApplicator>
                        </HashRouter>
                    </GlobalSettingsProvider >
                </OfflineStatusContextProvider>
            </OopsBoundary>
        </React.StrictMode>
    );
};

export default App;

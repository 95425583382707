import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { OfflineStatus } from '../../../src/pages/settings/OfflineStatus';

import "./NotFoundPage.scss";

export const NotFoundPage: React.FC<{}> = () => {
    return <Container className="not-found pad-navbar">
        <div className="subtitle">— not found —</div>
        <div className="title">404!</div>
        <div className="explainer">That's an error.</div>
        <div className="message">Whatever you're looking for, it's not here. Try going <Link to="/">somewhere else</Link>.</div>
        <OfflineStatus />
    </Container>;
};

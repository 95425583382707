import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from "react";
import GlobalSettingsContext from "../GlobalSettings";

import { Router } from "../../../pseuco-book-server/src/routing";
import { authorizeURI, officialClientId, testingClientId } from "./identityProvider";
import { getRandomByteString } from "../../hybrid-documents-reader-core/util/random";
import { applicationName, applicationVersion, localStoragePrefix } from "../ProductInfo";
import { ClientReportingEvent, LiveSessionJoinStatus, LiveSessionStudents, LiveSessionTeacherData, LiveSessionUpdate, SurveyResponses, UserRecord } from "../../../pseuco-book-server/src/types";
import { UserStudyNavigationReporter } from "./UserStudyNavigationReporter";
import { UserStudyVisibilityReporter } from "./UserStudyVisibilityReporter";
import { ToastContext } from "../../hybrid-documents-reader-core/Toaster";
import { useHistory, useLocation } from "react-router-dom";
import { UserStudyInteractiveFeedbackSurvey } from "./UserStudyInteractiveFeedbackSurvey";
import { UserStudyEnrollmentCallToActionModal } from "./UserStudyCallToAction";

const loginStateKey = `${localStoragePrefix}user-study-login-state`;

/**
 * A unique identifier for this session.
 * A session is a time pseuCo Book was loaded (independent of whether it is active on screen or not).
 */
const session = getRandomByteString(16);

export const getJoinLinkForLiveSession = (id: string): string => `${window.location.origin}${window.location.pathname}#/live/join/${id}`;

export type ConnectionState = "DISCONNECTED" | "ESTABLISHING" | "UNENROLLED" | "CONNECTED"

export type LiveSessionJoinState = "NOT_JOINED" | "LOCALLY_JOINED" | "JOINING" | "JOINED"

export type UserStudyAPI = {
    /**
     * Specifies whether the user study is enabled.
     * If it is disabled, the UI will not show, users cannot enroll, and no data is collected.
     */
    enabled: boolean;

    /**
     * Specifies the current state of the server connection.
     */
    connectionState: ConnectionState;

    /**
     * Whether UI elements that ask the user to enroll in the user study should be shown.
     */
    shouldAskForEnrollment: boolean;

    /**
     * Whether UI elements that ask the user to submit the survey should be shown.
     */
    shouldAskForSurvey: boolean;

    /**
     * Whether the user is logged in.
     * This is independent of connectivity, a user can be logged in without being connected.
     * If the session is expired server-side, this property will only reflect this after the next connection attempt.
     */
    loggedIn: boolean;

    /**
     * Record of the logged-in user.
     */
    userRecord: UserRecord | null;

    /**
     * Attempts to connect to the server.
     */
    connect: () => void;

    /**
     * Log in the user.
     * Calling this method causes the browser to navigate away from this web application.
     */
    login: () => void;

    /**
     * Completes a login, given the search (i.e. the part of the callback URL starting with "?") from the identity provider.
     */
    completeLogin: (search: string) => Promise<void>;

    /**
     * Logout without invalidating the session server-side.
     */
    localLogout: () => void;

    /**
     * Disconnects and invalidates the authentication token.
     */
    logout: () => void;

    /**
     * Enrolls in the user study.
     */
    enroll: () => Promise<void>;

    /**
     * Reports a client-side reporting event.
     * May be safely called when not connected or enrolled – events will be dropped in that case.
     */
    reportEvent: (e: ClientReportingEvent) => void;

    showInteractiveFeedbackSurvey: (id: string) => void;
    showEnrollmentCTA: () => void;

    listLiveSessions: () => Promise<LiveSessionTeacherData[]>;
    createLiveSession: (name: string) => Promise<LiveSessionTeacherData>;
    deleteLiveSession: (id: string) => Promise<void>;

    liveSessionJoinState: LiveSessionJoinState;

    joinLiveSession: (id: string, asTeacher: boolean) => void;
    leaveLiveSession: () => void;

    liveSessionId: string | null;
    liveSessionStatus: LiveSessionJoinStatus | null;
    liveSessionStudents: LiveSessionStudents | null;

    updateLiveSessionStatus: (update: LiveSessionUpdate) => void;
    liveSessionCallAttention: () => void;

    submitSurvey: (responses: SurveyResponses) => Promise<void>;
};

const errOutOfContext = () => { throw new Error("Cannot call user study methods outside of user study context."); };

export const UserStudyContext = React.createContext<UserStudyAPI>({
    enabled: false,
    connectionState: "DISCONNECTED",
    shouldAskForEnrollment: false,
    shouldAskForSurvey: false,
    loggedIn: false,
    userRecord: null,
    connect: errOutOfContext,
    login: errOutOfContext,
    completeLogin: errOutOfContext,
    localLogout: errOutOfContext,
    logout: errOutOfContext,
    enroll: errOutOfContext,
    reportEvent: errOutOfContext,
    showInteractiveFeedbackSurvey: errOutOfContext,
    showEnrollmentCTA: errOutOfContext,
    listLiveSessions: errOutOfContext,
    createLiveSession: errOutOfContext,
    deleteLiveSession: errOutOfContext,
    liveSessionJoinState: "NOT_JOINED",
    joinLiveSession: errOutOfContext,
    leaveLiveSession: errOutOfContext,
    liveSessionId: null,
    liveSessionStatus: null,
    updateLiveSessionStatus: errOutOfContext,
    liveSessionCallAttention: errOutOfContext,
    liveSessionStudents: null,
    submitSurvey: errOutOfContext
});

export const UserStudyManager: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const settings = useContext(GlobalSettingsContext);
    const toaster = useContext(ToastContext);
    const location = useLocation();
    const history = useHistory();

    const enabled = settings.current.enableUserStudy;

    let [router, setRouter] = useState<Router | null>(null);
    const [userRecord, setUserRecord] = useState<UserRecord | null>(null);

    const [interactiveFeedbackSurvey, setInteractiveFeedbackSurvey] = useState<{
        interactiveId: string;
    } | null>(null);

    const [enrollmentCTA, setEnrollmentCTA] = useState<{} | null>(null);

    const [surveyToastShown, setSurveyToastShown] = useState<boolean>(false); // used to ensure we only show the survey note once per session

    const [liveSessionStatus, setLiveSessionStatus] = useState<LiveSessionJoinStatus | null>(null);
    const [liveSessionJoined, setLiveSessionJoined] = useState<boolean>(false);
    const [liveSessionStudents, setLiveSessionStudents] = useState<LiveSessionStudents | null>(null);

    const loggedIn = settings.current.userStudyAuthenticationToken !== null;

    const hostName = window.location.hostname;
    const isLocal = hostName === "localhost";
    const useLocalServer = isLocal && !settings.current.userStudyForceOfficialServer;

    const _handleLeaveLiveSessionRef = useRef<() => void>(() => {/* ignore */});
    const _handleUpdateLiveSessionJoinStatusRef = useRef<(update: LiveSessionJoinStatus) => void>(() => {/* ignore */});
    const _handleLiveSessionCallAttentionRef = useRef<() => void>(() => {/* ignore */});
    const _handleUpdateLiveSessionStudentsRef = useRef<(update: LiveSessionStudents) => void>(() => {/* ignore */});

    /**
     * Like [[connect]], but for internal use: May be called without being logged in, and does not attempt to authenticate automatically.
     */
    const _connect = useCallback((): void => {
        if (router !== null) throw new Error("Cannot connect: Not currently disconnected.");

        const c = new WebSocket(useLocalServer ? `ws://localhost:15888` : `wss://book.pseuco.com:15888`);

        c.addEventListener("close", () => {
            setRouter(null);
            setUserRecord(null);
            setLiveSessionJoined(false);
            setLiveSessionStudents(null);
        });

        // ↓ I know the assignment will be lost in the next cycle, that's why we also call setRouter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setRouter(router = new Router(c, {}, {
            // encapsulated in refs to ensure the handler can be updated, so no stale state is used
            leaveLiveSession: () => _handleLeaveLiveSessionRef.current(),
            updateLiveSessionJoinStatus: (u) => _handleUpdateLiveSessionJoinStatusRef.current(u),
            liveSessionCallAttention: () => _handleLiveSessionCallAttentionRef.current(),
            updateLiveSessionStudents: (u) => _handleUpdateLiveSessionStudentsRef.current(u),
        }));
    }, [router, settings]);

    /**
     * Local-only logout (delete token and disconnect).
     */
    const localLogout = useCallback(() => {
        if (router) router.socket.close();
        // also leave live session – otherwise, any currently joined live session would be re-joined because settings.current may be stale
        settings.set({ ...settings.current, userStudyAuthenticationToken: null, userStudyLiveSessionId: null, userStudyLiveSessionValidated: false, userStudyLiveSessionJoinAsTeacher: false });
    }, [router, settings]);

    /**
     * Expected to be called after a connection has been opened (and possibly a login call has completed).
     * Authenticates to the server, readying the connection.
     */
    const _authenticate = useCallback(async (authenticationToken?: string): Promise<void> => {
        if (!router) throw new Error("Internal concistency: Cannot authenticate without router.");
        const token = authenticationToken ?? settings.current.userStudyAuthenticationToken;
        if (!token) throw new Error("Internal concistency: Cannot authenticate without being logged in.");
        const reply = await router.call("authenticate", { authenticationToken: token, session, clientVersion: applicationVersion });
        if (reply.success) {
            setUserRecord(reply.userRecord);

            if (reply.userRecord.surveyStatus === "ELIGIBLE" && !surveyToastShown && !location.pathname.startsWith("/study/survey")) {
                toaster.toast("primary", "User Study Survey", `Please fill out a final survey to complete your participation in ${applicationName} User Study.`, 30000, () => history.push("/study/survey"));
                setSurveyToastShown(true);
            }
        } else {
            localLogout(); // our token was invalid, so we should consider ourselves to be logged out now
            throw new Error("Authentication was rejected by the server.");
        }
    }, [history, localLogout, location, router, settings, surveyToastShown, toaster]);

    const connect = useCallback(async () => {
        if (!loggedIn) throw new Error("Cannot connect: Not logged in.");
        if (!enabled) throw new Error("Cannot connect: User study disabled.");
        await _connect();
        await _authenticate();
    }, [_authenticate, _connect, loggedIn, enabled]);

    const login = useCallback(() => {
        if (!enabled) throw new Error("Cannot login: User study disabled.");
        const redirectURI = `${window.location.origin}${window.location.pathname}#/study/complete-login`;
        const state = getRandomByteString(16);
        const targetURI = `${authorizeURI}?client_id=${encodeURIComponent(useLocalServer ? testingClientId : officialClientId)}&state=${state}&redirect_uri=${encodeURIComponent(redirectURI)}`;

        localStorage.setItem(loginStateKey, JSON.stringify({ state, preApprovedEnrollment: true }));

        console.log(`Sending you to ${targetURI} to log in…`);
        window.location.href = targetURI;
    }, [enabled, useLocalServer]);

    const completeLogin = useCallback(async (search: string): Promise<void> => {
        if (loggedIn || router !== null) throw new Error("Cannot log in: Already logged in or connected.");

        const searchParams = new URLSearchParams(search);
    
        const state = searchParams.get("state");
        const code = searchParams.get("code");

        if (!(state && code)) throw new Error("Invalid URL, missing required parameter.");

        const stateDataFromLocalStorage = localStorage.getItem(loginStateKey);
        if (!stateDataFromLocalStorage) {
            console.log("Aborting login attempt: No login state, possible request forgery.");
            throw new Error("Invalid login state.");
        }
        const { state: requestedState, preApprovedEnrollment } = JSON.parse(stateDataFromLocalStorage) as { state: string, preApprovedEnrollment: boolean };
        localStorage.removeItem(loginStateKey);

        if (requestedState === state) {
            _connect();
            if (!router) throw new Error("Internal inconsistency: Connecting failed to initialize router.");
            const reply = await (router as Router).call("login", { code, state, preApprovedEnrollment: !!preApprovedEnrollment }); // cast: TypeScript thinks router === null, but _connect assigned it
            if (!reply.success) throw new Error("The server rejected the login attempt.");
            settings.set({ ...settings.current, userStudyAuthenticationToken: reply.authenticationToken });
            await _authenticate(reply.authenticationToken); // passed by argument because changing settings only takes effect later
            return; // success
        } else {
            console.log("Aborting login attempt: Invalid login state, possible request forgery.");
            throw new Error("Invalid login state.");
        }
    }, [_authenticate, _connect, loggedIn, router, settings]);

    const liveSessionId = settings.current.userStudyLiveSessionId;

    const leaveLiveSession = useCallback((): void => {
        if (liveSessionId && router) router?.event("leaveLiveSession", {});
        settings.set({ ...settings.current, userStudyLiveSessionId: null, userStudyLiveSessionValidated: false, userStudyLiveSessionJoinAsTeacher: false });
        setLiveSessionJoined(false);
        setLiveSessionStatus(null);
        setLiveSessionJoinAttempted(false); // would reset automatically, but not if we re-join a new session in the same cycle
    }, [liveSessionId, router, settings]);
    
    const logout = useCallback(async (): Promise<void> => {
        leaveLiveSession();
        if (!loggedIn || !router) throw new Error("Cannot logout: Must be logged in and connected.");
        await router.call("logout", {});
        localLogout();
    }, [leaveLiveSession, localLogout, loggedIn, router]);
    
    const enroll = useCallback(async (): Promise<void> => {
        if (!loggedIn || !router || !userRecord) throw new Error("Cannot enroll: Must be logged in and connected.");
        await router.call("enroll", {});
        setUserRecord({ ...userRecord, enrolled: true });
    }, [loggedIn, router, userRecord]);

    const reportEvent = useCallback((e: ClientReportingEvent) => {
        if (!loggedIn || !router || !userRecord || !userRecord.enrolled) return; // silently ignore
        router.event("reportEvent", e);
    }, [loggedIn, router, userRecord]);

    const showInteractiveFeedbackSurvey = useCallback((id: string) => {
        setInteractiveFeedbackSurvey({ interactiveId: id });
    }, []);

    const showEnrollmentCTA = useCallback(() => {
        setEnrollmentCTA({});
    }, []);

    const listLiveSessions = useCallback(async (): Promise<LiveSessionTeacherData[]> => {
        if (!loggedIn || !router || !userRecord?.teacher) throw new Error("Cannot manage live sessions without being logged in as a teacher.");
        return (await router.call("listLiveSessions", {})).sessions;
    }, [loggedIn, router, userRecord]);

    const createLiveSession = useCallback(async (name: string): Promise<LiveSessionTeacherData> => {
        if (!loggedIn || !router || !userRecord?.teacher) throw new Error("Cannot manage live sessions without being logged in as a teacher.");
        return (await router.call("createLiveSession", { name })).session;
    }, [loggedIn, router, userRecord]);

    const deleteLiveSession = useCallback(async (id: string): Promise<void> => {
        if (!loggedIn || !router || !userRecord?.teacher) throw new Error("Cannot manage live sessions without being logged in as a teacher.");
        await router.call("deleteLiveSession", { id });
    }, [loggedIn, router, userRecord]);

    const joinLiveSession = useCallback((id: string, asTeacher: boolean): void => {
        if (id !== settings.current.userStudyLiveSessionId || asTeacher !== settings.current.userStudyLiveSessionJoinAsTeacher) {
            leaveLiveSession(); // it is perfectly valid to join a new live session while still being in an old one
            settings.set({ ...settings.current, userStudyLiveSessionId: id, userStudyLiveSessionJoinAsTeacher: asTeacher });
        }
    }, [leaveLiveSession, settings]);

    const _handleLiveSessionClosed = useCallback(() => {
        leaveLiveSession();
        toaster.toast("error", "Live Session Left", "The live session has been closed.", 10000);
    }, [leaveLiveSession, toaster]);

    const _connectToLiveSession = useCallback(async (): Promise<void> => {
        if (!loggedIn || !router || !userRecord?.enrolled) throw new Error("Cannot connect to live session: Must be connected and enrolled.");
        if (settings.current.userStudyLiveSessionId === null) throw new Error("Cannot connect to live session: Not joined to any session.");

        try {
            const response = await router.call("joinLiveSession", { id: settings.current.userStudyLiveSessionId, asTeacher: settings.current.userStudyLiveSessionJoinAsTeacher });
            setLiveSessionJoined(true);
            setLiveSessionStatus(response.status);
            setLiveSessionStudents(response.students ?? null);
            settings.set({ ...settings.current, userStudyLiveSessionValidated: true }); // remember this session existed at least once
        } catch (e) {
            console.log(`Failed to connect to live session.`);
            console.log(e);
            if (settings.current.userStudyLiveSessionValidated) {
                toaster.toast("error", "Could Not Join Live Session", "The live session has been closed.", 10000);
            } else {
                toaster.toast("error", "Could Not Join Live Session", "The invite link you received is not valid. The live session may have been closed.", 10000);
            }
            leaveLiveSession();
        }
    }, [loggedIn, router, settings, userRecord, leaveLiveSession, toaster]);

    const updateLiveSessionStatus = useCallback((update: LiveSessionUpdate) => {
        if (!router || !liveSessionJoined || !liveSessionStatus?.joinedAsTeacher) throw new Error("You need to be joined as a teacher to update a live session.");
        router.event("updateLiveSessionStatus", update);
    }, [liveSessionJoined, liveSessionStatus, router]);

    const _handleUpdateLiveSessionJoinStatus = useCallback((update: LiveSessionJoinStatus) => {
        setLiveSessionStatus(update);
    }, []);

    const _handleUpdateLiveSessionStudents = useCallback((update: LiveSessionStudents) => {
        setLiveSessionStudents(update);
    }, []);

    const liveSessionCallAttention = useCallback(() => {
        if (!router || !liveSessionJoined || !liveSessionStatus?.joinedAsTeacher) throw new Error("You need to be joined as a teacher to call attention to a live session.");
        router.event("liveSessionCallAttention", {});
    }, [liveSessionJoined, liveSessionStatus, router]);

    const _handleLiveSessionCallAttention = useCallback(() => {
        if (!location.pathname.startsWith("/live")) {
            toaster.toast("primary", "Attention Required", "The Live Session requires your attention.", 10000, () => history.push("/live"));
        }
    }, [history, location.pathname, toaster]);


    const submitSurvey = useCallback(async (responses: SurveyResponses) => {
        if (!router || !userRecord) throw new Error("Cannot submit survey: Must be connected and signed in. Check your connection.");
        if (userRecord.surveyStatus !== "ELIGIBLE") throw new Error("Cannot submit survey: Not eligible.");
        await router.call("submitSurvey", { responses });
        setUserRecord({ ...userRecord, surveyStatus: "COMPLETED" }); // update local record now, new “official” results will be sent once we re-authenticate
    }, [router, userRecord]);


    // Auto Connect Management

    const [wasAutoConnectedPreviously, setWasAutoConnectedPreviously] = useState<boolean>(false);

    useEffect(() => {
        if (enabled && loggedIn && router === null) {
            const t = setTimeout(() => {
                connect();
                setWasAutoConnectedPreviously(true);
            }, wasAutoConnectedPreviously ? (20000 + Math.random() * 20000) : 500);
            return () => clearTimeout(t);
        }
    }, [connect, loggedIn, router, enabled, wasAutoConnectedPreviously]);

    useEffect(() => {
        if (!enabled && liveSessionId) {
            leaveLiveSession(); // study was disabled, leave session
        }
    }, [enabled, leaveLiveSession, liveSessionId]);

    const [liveSessionJoinAttempted, setLiveSessionJoinAttempted] = useState<boolean>(false);
    useEffect(() => {
        if (!liveSessionJoinAttempted) {
            if (userRecord?.enrolled && liveSessionId !== null) {
                _connectToLiveSession();
                setLiveSessionJoinAttempted(true);
            }
        } else {
            if (router === null || liveSessionId === null) {
                // connection was lost, reset live session join flag
                setLiveSessionJoinAttempted(false);
            }
        }
    }, [_connectToLiveSession, liveSessionJoinAttempted, router, liveSessionId, userRecord]);


    const connectionState: ConnectionState = router === null ? "DISCONNECTED" : userRecord === null ? "ESTABLISHING" : !userRecord.enrolled ? "UNENROLLED" : "CONNECTED";

    const shouldAskForEnrollment = enabled && (!loggedIn || connectionState === "UNENROLLED");

    const shouldAskForSurvey = connectionState === "CONNECTED" && !!userRecord && userRecord.surveyStatus === "ELIGIBLE";

    const liveSessionJoinState = liveSessionId === null ? "NOT_JOINED" : !liveSessionJoined ? (userRecord?.enrolled ? "JOINING" : "LOCALLY_JOINED") : "JOINED";

    // refresh handlers

    _handleLeaveLiveSessionRef.current = _handleLiveSessionClosed;
    _handleUpdateLiveSessionJoinStatusRef.current = _handleUpdateLiveSessionJoinStatus;
    _handleLiveSessionCallAttentionRef.current = _handleLiveSessionCallAttention;
    _handleUpdateLiveSessionStudentsRef.current = _handleUpdateLiveSessionStudents;

    return <UserStudyContext.Provider value={{
        enabled,
        connectionState,
        shouldAskForEnrollment,
        shouldAskForSurvey,
        loggedIn,
        userRecord,
        connect,
        login,
        completeLogin,
        localLogout,
        logout,
        enroll,
        reportEvent,
        showInteractiveFeedbackSurvey,
        showEnrollmentCTA,
        listLiveSessions,
        createLiveSession,
        deleteLiveSession,
        liveSessionJoinState,
        joinLiveSession,
        leaveLiveSession,
        liveSessionId,
        liveSessionStatus,
        updateLiveSessionStatus,
        liveSessionCallAttention,
        liveSessionStudents,
        submitSurvey
    }}>
        <UserStudyNavigationReporter />
        <UserStudyVisibilityReporter />
        <UserStudyInteractiveFeedbackSurvey forInteractive={interactiveFeedbackSurvey} />
        <UserStudyEnrollmentCallToActionModal show={enrollmentCTA} />
        {children}
    </UserStudyContext.Provider>;
};

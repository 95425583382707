import GlobalSettingsContext from "../src/GlobalSettings";

import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import React from "react";

import moonIcon from '../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-231-moon.svg';
import sunIcon from '../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-232-sun.svg';
import { browserSupportsDarkMode } from "./ThemeApplicator";

export const ThemeSwitcher: React.FC<{ className: string }> = ({ className }) => {
    const settings = React.useContext(GlobalSettingsContext);

    if (settings.current.followSystemDarkMode && browserSupportsDarkMode) return null;

    return <ToggleButtonGroup name="theme-radio" type="radio" value={settings.current.theme} onChange={(v) => {
        settings.set({ ...settings.current, theme: v === "dark" ? "dark" : "light" });
    }} className={className}>
        <ToggleButton id="theme-radio-light" value="light" variant="secondary">
            <img style={{height: "16px"}} src={sunIcon} alt="light" />
        </ToggleButton>
        <ToggleButton id="theme-radio-dark" value="dark" variant="secondary">
            <img style={{height: "16px"}} src={moonIcon} alt="dark" />
        </ToggleButton>
    </ToggleButtonGroup>;
};

import React from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import GlobalSettingsContext from '../../GlobalSettings';
import ScrollContainer from '../../../hybrid-documents-reader-core/ScrollContainer';

export const DebugPage: React.FC<{}> = () => {
    const settings = React.useContext(GlobalSettingsContext);

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container>
            <h1>Debugging Page</h1>
            <h2>Hidden Settings</h2>
            <Form>
                <Form.Check type="checkbox" id="debug-show-in-navbar" label="Show this page in the navigation bar" defaultChecked={settings.current.showDebugPage} onClick={(): void => settings.set({ ...settings.current, showDebugPage: !settings.current.showDebugPage })} />
                <Form.Check type="checkbox" id="debug-force-enable-user-study" label="Force-enable user study" defaultChecked={settings.current.enableUserStudy} onClick={(): void => settings.set({ ...settings.current, enableUserStudy: !settings.current.enableUserStudy })} />
                <Form.Check type="checkbox" id="debug-user-study-official-api-only" label="Always connect to official user study API" defaultChecked={settings.current.userStudyForceOfficialServer} onClick={(): void => settings.set({ ...settings.current, userStudyForceOfficialServer: !settings.current.userStudyForceOfficialServer })} />
            </Form>
            <h2>Current Settings</h2>
            <pre>{JSON.stringify(settings.current, null, 4)}</pre>
            <h2>Reset</h2>
            <Button variant="danger" onClick={(): void => settings.set({ ...settings.current, interactiveCompletion: {} })}>Clear Exercise Completion</Button>
        </Container>
    </ScrollContainer>;
};

import React from "react";
import { RouteComponentProps } from "react-router";
import { InteractiveElementBox } from "../../interactive/InteractiveElement";
import { NotFoundPage } from "../notfound/NotFoundPage";
import interactiveElementDefinitions from "../../../src/interactive/interactive";
import { Container } from "react-bootstrap";

import "./InteractivePage.scss";

type Params = {
    type: "quiz" | "interactive";
    id: string;
}

export const InteractivePage: React.FC<RouteComponentProps<Params>> = ({ match }) => {
    if (match.params.type === "interactive" && interactiveElementDefinitions[match.params.id] === undefined) return <NotFoundPage />;
    return <div className="interactive-page pad-navbar">
        <Container className="interactive-container" fluid={true}>
            <InteractiveElementBox type={match.params.type} id={match.params.id} launchOnLoad={true} showDedicatedRouteLink={false} fullHeight={true} visible={true} />
        </Container>
    </div>;
};

import { Book, VerticalBox, HeadingVerticalBox } from '../../../hybrid-documents/src/Book';

export const isHeading = (input: VerticalBox): input is HeadingVerticalBox => input.type === "heading";

let cachedBook: Promise<Book> | null;

export const getBook = async (): Promise<Book> => {
    if (cachedBook) return cachedBook; // return old promise
    const promise = import('../../data/book.json') as Promise<Book>;
    cachedBook = promise;
    return promise;
};

export { getImage } from '../../data/images';

import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Button, OverlayTrigger, Popover } from "react-bootstrap";
import interactiveElementDefinitions from "../interactive/interactive";
import { applicationName } from "../ProductInfo";
import { UserStudyContext } from "./UserStudy";
import "./UserStudyInteractiveFeedbackSurvey.scss";

import chemistryIcon from "../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-626-chemistry-alt.svg";
import infoIcon from "../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-636-circle-info.svg";
import { RatingControl } from "./SurveyElements";

/**
 * Renders a post-completion feedback survey for an interactive element.
 */
export const UserStudyInteractiveFeedbackSurvey: React.FC<{ forInteractive: { interactiveId: string } | null }> = ({ forInteractive }) => {
    const userStudy = useContext(UserStudyContext);

    const [state, setState] = useState<{
        ratings: {
            clarity: number | null,
            difficulty: number | null,
            learning: number | null
        },
        feedback: string
    } | null>(null);

    useEffect(() => {
        setState(forInteractive === null ? null : {
            ratings: {
                clarity: null,
                difficulty: null,
                learning: null
            },
            feedback: ""
        });
    }, [forInteractive]); // reset state whenever forInteractive is updated

    return <Modal className="interactive-post-survey" show={state !== null} onHide={() => setState(null)}>
        <Form onKeyDown={ (e) => e.stopPropagation() /* to prevent [[ReadPage]] from navigating based on arrow key presses */ }>
            <Modal.Header closeButton>
                <Modal.Title>Exercise Solved</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="congratulations">
                    <div className="emoji">🎉</div>
                    <div className="label">Well done!</div>
                </div>
                { state ?
                    <div>
                        <p>Please help us to improve the exercise “{ forInteractive ? interactiveElementDefinitions[forInteractive.interactiveId].title : null }” by answering four quick questions.</p>
                        <Form.Group className="question-group">
                            <Form.Label className="main-question">
                                Was the exercise clear?
                                <OverlayTrigger delay={{ show: 100, hide: 500 }} placement="left" overlay={  <Popover id="popover-basic">
                                    <Popover.Header as="h3">Clarity</Popover.Header>
                                    <Popover.Body>
                                        This refers to the problem statement only.<br />
                                        For example, if you knew exactly what the exercise wanted you to do, answer <em>very clear</em> irrespective of whether it was hard to actually do it.
                                    </Popover.Body>
                                </Popover>}>
                                    <img className="info-icon invert-dark" src={infoIcon} />
                                </OverlayTrigger>
                            </Form.Label>
                            <RatingControl ratings={[ { value: 1, label: "very unclear" }, { value: 2, label: "somewhat unclear" }, { value: 3, label: "so-so" }, { value: 4, label: "somewhat clear" }, { value: 5, label: "very clear" } ]} rating={state.ratings.clarity} onChange={(r) => setState({ ...state, ratings: { ...state.ratings, clarity: r } })} />
                        </Form.Group>
                        <Form.Group className="question-group">
                            <Form.Label className="main-question">
                                Was the exercise difficult?
                                <OverlayTrigger delay={{ show: 100, hide: 500 }} placement="left" overlay={  <Popover id="popover-basic">
                                    <Popover.Header as="h3">Difficulty</Popover.Header>
                                    <Popover.Body>
                                        This refers to the content of the exercise, i.e. how hard did you have to think after understanding what the exercise wanted you to do?
                                    </Popover.Body>
                                </Popover>}>
                                    <img className="info-icon invert-dark" src={infoIcon} />
                                </OverlayTrigger>
                            </Form.Label>
                            <RatingControl ratings={[ { value: 5, label: "very difficult" }, { value: 4, label: "somewhat difficult" },{ value: 3, label: "so-so" },{ value: 2, label: "somewhat easy" },{ value: 1, label: "very easy" } ]} rating={state.ratings.difficulty} onChange={(r) => setState({ ...state, ratings: { ...state.ratings, difficulty: r } })} />
                        </Form.Group>
                        <Form.Group className="question-group">
                            <Form.Label className="main-question">
                                How much did you learn in this exercise?
                                <OverlayTrigger delay={{ show: 100, hide: 500 }} placement="left" overlay={  <Popover id="popover-basic">
                                    <Popover.Header as="h3">Learning</Popover.Header>
                                    <Popover.Body>
                                        In other words: Was the time you invested into this exercise well-spent compared to other learning activities like reading lecture notes or working on exercise sheets?
                                    </Popover.Body>
                                </Popover>}>
                                    <img className="info-icon invert-dark" src={infoIcon} />
                                </OverlayTrigger>
                            </Form.Label>
                            <RatingControl ratings={[ { value: 1, label: "very little" }, { value: 2, label: "somewhat little" }, { value: 3, label: "so-so" }, { value: 4, label: "somewhat much" }, { value: 5, label: "very much" } ]} rating={state.ratings.learning} onChange={(r) => setState({ ...state, ratings: { ...state.ratings, learning: r } })} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="main-question">
                                Do you have additional feedback?
                                <OverlayTrigger delay={{ show: 100, hide: 2000 }} placement="left" overlay={  <Popover id="popover-basic">
                                    <Popover.Header as="h3">Feedback</Popover.Header>
                                    <Popover.Body>
                                        All feedback will be read and considered, but we cannot respond to questions here.<br />
                                        If you have a question, <a href="mailto:freiberger@depend.uni-saarland.de">send an email to the author of this exercise</a> instead.
                                    </Popover.Body>
                                </Popover>}>
                                    <img className="info-icon invert-dark" src={infoIcon} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control as="textarea" className="feedback" value={state.feedback} onChange={(t) => setState({ ...state, feedback: t.target.value })} placeholder="Additional Feedback" />
                        </Form.Group>
                        <p className="user-study-notice">
                            <img className="science-icon invert-dark" src={chemistryIcon} />
                            This survey is a part of {applicationName} User Study.
                        </p>
                    </div>
                    : null }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={(e) => {
                    e.preventDefault();
                    if (state === null || forInteractive === null) throw new Error("Cannot submit feedback on unknown exercise.");
                    userStudy.reportEvent({ type: "INTERACTIVE_SUBMIT_FEEDBACK", interactiveType: "interactive", interactiveId: forInteractive.interactiveId, ...state });
                    setState(null);
                }} disabled={userStudy.connectionState !== "CONNECTED"}>
                    { userStudy.connectionState === "CONNECTED" ? "Submit" : "Not Connected" }
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>
    ;
};

import React, { useContext } from "react";
import { Button, Container } from "react-bootstrap";
import { applicationName } from "../../../ProductInfo";
import ScrollContainer from "../../../../hybrid-documents-reader-core/ScrollContainer";

import blackboardIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-424-blackboard.svg";

import "./LiveJoinPage.scss";
import { UserStudyContext } from "../../../study/UserStudy";
import { useHistory } from "react-router";
import { RouteComponentProps } from "react-router-dom";

export const LiveJoinPage: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
    const userStudy = useContext(UserStudyContext);
    const history = useHistory();

    const join = () => {
        userStudy.joinLiveSession(match.params.id, false);
        history.push("/live");
    };

    return <ScrollContainer padNavbar={true} padTop={true}>
        <Container className="live-session-join">
            { userStudy.enabled ? <>
                <div className="join-explainer">
                    <div className="blackboard-icon-container">
                        <img className="blackboard-icon invert-dark" src={blackboardIcon} />
                    </div>
                    <div className="join-explainer-content">
                        <h1>You have been invited to join a Live Session!</h1>
                        <p>
                            In a Live Session, your teacher can direct you to specific exercises, and they will automatically open on your screen.
                            Your teacher will receive live, pseudonymous feedback about your progress.
                        </p>
                        <Button variant="primary" className="join-button" onClick={join}>Join Live Session</Button>
                    </div>
                </div>
            </> : <div className="study-disabled">
                — {applicationName} User Study is not available —
            </div>}
        </Container>
    </ScrollContainer>;
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { ApplicationName, applicationName } from "../../../ProductInfo";
import ScrollContainer from "../../../../hybrid-documents-reader-core/ScrollContainer";
import { UserStudyContext } from "../../../study/UserStudy";

import notesIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-40-notes.svg";
import chemistryIcon from "../../../../pseuco-shared-components/glyphicons/glyphicons-basic/glyphicons-basic-626-chemistry-alt.svg";

import "./UserStudySurveyPage.scss";
import { RatingControl } from "../../../study/SurveyElements";
import { SurveyResponses } from "../../../../../pseuco-book-server/src/types";
import GlobalSettingsContext from "../../../GlobalSettings";
import { Link, useHistory } from "react-router-dom";

export const UserStudySurveyPage: React.FC<{}> = () => {
    const userStudy = useContext(UserStudyContext);
    const settings = useContext(GlobalSettingsContext);

    const history = useHistory();

    const [showSurvey, setShowSurvey] = useState<boolean>(false); // This flag ensures the survey isn't closed even if the connection is lost while on this page.

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | null>(null);

    const [responses, setResponses] = useState<SurveyResponses>({
        staticContentUsefulness: null,
        quizUsefulness: null,
        demonstrationUsefulness: null,
        exerciseUsefulness: null,
        usefulnessFeedback: "",
        technicalIssueFeedback: "",
        likeFeedback: "",
        dislikeFeedback: "",
        suggestionFeedback: "",
        pdfVersionUsage: null,
        lectureNoteReplacement: null,
        chapterEqualityUsefulness: null,
        chapterProgrammingUsefulness: null,
        chapterProgrammingFeedback: ""
    });

    const [responsesLoaded, setResponsesLoaded] = useState<boolean>(false);
    useEffect(() => {
        if (!responsesLoaded) {
            try {
                setResponsesLoaded(true);
                if (settings.current.userStudySurveySaveState) setResponses(JSON.parse(settings.current.userStudySurveySaveState));
            } catch (e) {
                // ignore
            }
        }
    }, [responsesLoaded, settings]);

    useEffect(() => {
        if (!responsesLoaded) return; // do not overwrite draft with defaults
        if (!showSurvey) return; // do not re-save after submitting
        const responseJSON = JSON.stringify(responses);
        if (responseJSON !== settings.current.userStudySurveySaveState) settings.set({ ...settings.current, userStudySurveySaveState: responseJSON });
    });

    if (showSurvey) {
        return <ScrollContainer padNavbar={true} padTop={true}>
            <Container className="user-study-survey">
                <div className="user-study-survey-call-to-action">
                    <div className="notes-icon-container">
                        <img className="notes-icon invert-dark" src={notesIcon} />
                    </div>
                    <div className="user-study-survey-call-to-action-content">
                        <h1>{ applicationName } User Study Completion Survey</h1>
                        <p>
                            As the <em>Concurrent Programming</em> lecture concludes, I have a final survey for you, summarizing your experience with { applicationName }.
                            Please take a moment to submit this survey – it only takes a few minutes, but it's very helpful feedback to make { applicationName } more useful, year by year.
                        </p>
                        <p>
                            Thank you for your help and your participation in { applicationName } User Study in general – it's very much appreciated.
                            Best of luck in the final exam!
                        </p>
                        <p>
                            —Felix 👋
                        </p>
                    </div>
                </div>
                <h2>Introduction</h2>
                <p>
                    This survey is only about { applicationName }, i.e. this web application and the downloadable PDF version of it.
                    It is <em>not</em> about the lecture notes, assignment sheets, or other materials from the <em>Concurrent Programming</em> lecture.
                    If you have any feedback concerning the lecture itself or lecture materials, contact the team or submit <a href="https://dcms.cs.uni-saarland.de/np_22/landing#comment">anonymous feedback in dCMS</a>.
                </p>
                <p>
                    If you leave this survey, a draft will be saved on your machine.
                    Your answers will only be taken into account if you submit the survey.
                    After submitting the survey, you'll no longer be able to change your answers.
                </p>

                <h2>Overall Usefulness</h2>
                <p>
                    { ApplicationName } consists of static parts (descriptions, explanations, definitions, …) and interactive elements.
                    The latter can be (roughly) divided into three types:
                    <ul>
                        <li>quizzes (<Link to="/quiz/bisimilarity-game-rules-explained">sets of single- or multiple-choice questions</Link>),</li>
                        <li>demonstrations (mini-exercises that explain an idea, e.g. <Link to="/interactive/trace-equivalent-vending-machines">“Getting Some Drinks”</Link>, a demonstration of why trace equivalence isn't enough)</li>
                        <li>training exercises (longer-form exercises where you have to <Link to="/interactive/proving-bisimilarity-harder">prove something</Link>, <Link to="/interactive/pseuco-shared-memory-monitor-message-box">write a program</Link>, …)</li>
                    </ul>
                </p>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        Overall, how useful were the static parts of { applicationName }?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 5, label: "very useful" }, { value: 4, label: "rather useful" }, { value: 3, label: "somewhat useful" }, { value: 2, label: "mostly useless" }, { value: 1, label: "very useless" } ]} rating={responses.staticContentUsefulness} onChange={(r) => setResponses({ ...responses, staticContentUsefulness: r })} disabled={submitting} /></div>
                </Form.Group>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        Overall, how useful were the quizzes in { applicationName }?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 5, label: "very useful" }, { value: 4, label: "rather useful" }, { value: 3, label: "somewhat useful" }, { value: 2, label: "mostly useless" }, { value: 1, label: "very useless" } ]} rating={responses.quizUsefulness} onChange={(r) => setResponses({ ...responses, quizUsefulness: r })} disabled={submitting} /></div>
                </Form.Group>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        Overall, how useful were the demonstrations in { applicationName }?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 5, label: "very useful" }, { value: 4, label: "rather useful" }, { value: 3, label: "somewhat useful" }, { value: 2, label: "mostly useless" }, { value: 1, label: "very useless" } ]} rating={responses.demonstrationUsefulness} onChange={(r) => setResponses({ ...responses, demonstrationUsefulness: r })} disabled={submitting} /></div>
                </Form.Group>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        Overall, how useful were the training exercises in { applicationName }?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 5, label: "very useful" }, { value: 4, label: "rather useful" }, { value: 3, label: "somewhat useful" }, { value: 2, label: "mostly useless" }, { value: 1, label: "very useless" } ]} rating={responses.exerciseUsefulness} onChange={(r) => setResponses({ ...responses, exerciseUsefulness: r })} disabled={submitting} /></div>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="main-question">
                        Do you have additional comments on which parts of { applicationName } you found useful?
                    </Form.Label>
                    <Form.Control as="textarea" className="feedback" value={responses.usefulnessFeedback} onChange={(t) => setResponses({ ...responses, usefulnessFeedback: t.target.value })} placeholder="Additional Comments" disabled={submitting} />
                </Form.Group>

                <h2>Chapters</h2>
                <p>
                    So far, { applicationName } encompasses two chapters: The older <em>Equality</em> chapter, covering different notions of equality and how to prove and disprove them, and the newer <em>Programming with pseuCo</em> chapter, containing many programming exercises.
                </p>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        Overall, how useful was the <em>Equality</em> chapter?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 5, label: "very useful" }, { value: 4, label: "rather useful" }, { value: 3, label: "somewhat useful" }, { value: 2, label: "mostly useless" }, { value: 1, label: "very useless" } ]} rating={responses.chapterEqualityUsefulness} onChange={(r) => setResponses({ ...responses, chapterEqualityUsefulness: r })} disabled={submitting} /></div>
                </Form.Group>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        Overall, how useful was the <em>Programming with pseuCo</em> chapter?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 5, label: "very useful" }, { value: 4, label: "rather useful" }, { value: 3, label: "somewhat useful" }, { value: 2, label: "mostly useless" }, { value: 1, label: "very useless" } ]} rating={responses.chapterProgrammingUsefulness} onChange={(r) => setResponses({ ...responses, chapterProgrammingUsefulness: r })} disabled={submitting} /></div>
                </Form.Group>
                <p>
                    The <em>Programming with pseuCo</em> chapter included a new style of exercises, asking you to write pseuCo programs outside of pseuCo.com, providing detailed error messages through the pseuCo Debugger in the case of error messages.
                </p>
                <Form.Group>
                    <Form.Label className="main-question">
                        How well did this type of exercise work for you?
                        How could they be improved?
                    </Form.Label>
                    <Form.Control as="textarea" className="feedback" value={responses.chapterProgrammingFeedback} onChange={(t) => setResponses({ ...responses, chapterProgrammingFeedback: t.target.value })} placeholder="Your Comments" disabled={submitting} />
                </Form.Group>

                <h2>Miscellaneous</h2>
                <Form.Group>
                    <Form.Label className="main-question">
                        Did you have any technical issues while using { applicationName }?
                    </Form.Label>
                    <Form.Control as="textarea" className="feedback" value={responses.technicalIssueFeedback} onChange={(t) => setResponses({ ...responses, technicalIssueFeedback: t.target.value })} placeholder="Please describe your issues, if you had any." disabled={submitting} />
                </Form.Group>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        Did you use the PDF version of { ApplicationName }?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 3, label: "regularly"}, { value: 2, label: "occasionally" }, { value: 1, label: "never"}, { value: 0, label: "did not know it existed" }]} rating={responses.pdfVersionUsage} onChange={(r) => setResponses({ ...responses, pdfVersionUsage: r })} disabled={submitting} /></div>
                </Form.Group>
                <Form.Group className="question-group">
                    <Form.Label className="main-question">
                        In future iterations of the lecture, should an expanded version of { applicationName } completely replace the lecture notes?
                    </Form.Label>
                    <div className="rating-rail"><RatingControl ratings={[ { value: 5, label: "yes" }, { value: 4, label: "mostly yes" }, { value: 3, label: "neutral" }, { value: 2, label: "mostly no" }, { value: 1, label: "no" } ]} rating={responses.lectureNoteReplacement} onChange={(r) => setResponses({ ...responses, lectureNoteReplacement: r })} disabled={submitting} /></div>
                </Form.Group>

                <h2>Improving { applicationName }</h2>
                <Form.Group>
                    <Form.Label className="main-question">
                        What did you like about { applicationName }?
                    </Form.Label>
                    <Form.Control as="textarea" className="feedback" value={responses.likeFeedback} onChange={(t) => setResponses({ ...responses, likeFeedback: t.target.value })} placeholder="Your Comments" disabled={submitting} />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="main-question">
                        What did you dislike about { applicationName }?
                    </Form.Label>
                    <Form.Control as="textarea" className="feedback" value={responses.dislikeFeedback} onChange={(t) => setResponses({ ...responses, dislikeFeedback: t.target.value })} placeholder="Your Comments" disabled={submitting} />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="main-question">
                        Do you have any further suggestions for improving { applicationName }?
                    </Form.Label>
                    <Form.Control as="textarea" className="feedback" value={responses.suggestionFeedback} onChange={(t) => setResponses({ ...responses, suggestionFeedback: t.target.value })} placeholder="Your Comments" disabled={submitting} />
                </Form.Group>

                <Button className="survey-submit" variant="primary" type="submit" onClick={async (e) => {
                    e.preventDefault();
                    setSubmitting(true);
                    try {
                        await userStudy.submitSurvey(responses);
                        setSubmitting(false);
                        setShowSurvey(false);
                        settings.set({ ...settings.current, userStudySurveySaveState: null });
                        // no need to change state to COMPLETED, this is handled by userStudy
                    } catch (e) {
                        setSubmitting(false);
                        setSubmitError(typeof e.message === "string" ? e.message : "<unknown error>");
                    }
                }} disabled={submitting}>
                    { submitting ? "Submitting…" : "Submit Survey" }
                </Button>

                <p className="user-study-notice">
                    <img className="science-icon invert-dark" src={chemistryIcon} />
                    This survey is a part of {applicationName} User Study.
                </p>

                <Modal show={submitError !== null} onHide={() => setSubmitError(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Submission Failed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            We're sorry, but your answers could not submitted. Please check your connection, and try again later.
                        </p>
                        <p>
                            Your answers have been saved, you can leave this page and return later.
                        </p>
                        <p>
                            Here's the error: <code>{ submitError }</code>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {
                            setSubmitError(null);
                        }}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </ScrollContainer>;
    } else {
        if (!userStudy.loggedIn) return <div className="user-study-survey-unavailable">
            You must be logged in to the User Study to participate in this survey.
            <Button onClick={() => history.push("/study")}>Log In</Button>
        </div>;
        if (!userStudy.userRecord) return <div className="user-study-survey-unavailable">
            This survey is unavailable because you are not connected to the User Study.
        </div>;
        if (userStudy.userRecord && userStudy.userRecord.surveyStatus === "UNAVAILABLE") return <div className="user-study-survey-unavailable">
            This survey is currently unavailable.
        </div>;
        if (userStudy.userRecord && userStudy.userRecord.surveyStatus === "COMPLETED") return <div className="user-study-survey-thanks">
            <div className="title-emoji">🎉</div>
            <p>
                <strong>Thank you for submitting this survey and participating in { applicationName } User Study.</strong><br />
                Your support will help to make { applicationName } better for the next generation of students.
            </p>
            <p>Rock on!</p>
        </div>;
        if (userStudy.userRecord && userStudy.userRecord.surveyStatus === "ELIGIBLE") {
            setShowSurvey(true); // this will open the survey on the next render cycle
        }
    }

    return null;
};

import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { Toast } from "react-bootstrap";

import "./Toaster.scss";

export type ToastContext = {
    toast: (variant: ToastVariant, title: string | undefined, content: string, lifetime?: number, onClick?: () => void) => void;
}

export const ToastContext = createContext<ToastContext>({
    toast: () => { throw new Error("Toasting requires a Toaster."); }
});

let nextToastId = 0;

type ToastVariant = "primary" | "success" | "error";

type Toast = {
    variant: ToastVariant;
    title?: string;
    content: string;
    onClick?: () => void;
    show: boolean;
    id: number;
    lifetime: number | null;
};

const AutoCall: React.FC<{ when: number, call: () => void }> = ({ when, call }) => {
    useEffect(() => {
        const timeout = setTimeout(call, when - Date.now());
        return () => clearTimeout(timeout);
    });
    return null;
};

export const Toaster: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    /**
     * Toasts that are currently spawning.
     * A toasts needs to be added to DOM before its show flag is set to allow it to animate in.
     */
    const [spawningToasts, setSpawningToasts] = useState<Toast[]>([]);

    /**
     * Toasts that are currently dying.
     * A toasts is dying if it is being animated out.
     */
    const [dyingToasts, setDyingToasts] = useState<Toast[]>([]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (dyingToasts.length > 0) {
                setToasts(toasts.filter((t) => !dyingToasts.includes(t)));
                setDyingToasts([]);
            }
        }, 1000);
        return () => clearTimeout(timeout);
    }, [dyingToasts, toasts]);

    const closeToast = (toast: Toast) => {
        setToasts(toasts.map((t) => t === toast ? { ...t, show: false } : t));
        setDyingToasts([...dyingToasts, toast]);
    };

    useEffect(() => {
        if (spawningToasts.length > 0) {
            setToasts(toasts.map((t) => spawningToasts.includes(t) ? { ...t, show: true } : t));
            setSpawningToasts([]);
        }
    }, [spawningToasts, toasts]);

    return <ToastContext.Provider value={{
        toast: (variant: ToastVariant, title: string | undefined, content: string, lifetime = undefined, onClick?: () => void): void => {
            const toast = { variant, title, content, show: false, id: nextToastId++, lifetime: lifetime === undefined ? null : Date.now() + lifetime, onClick };
            setToasts((t) => [...t, toast]);
            setSpawningToasts((t) => [...t, toast]);
        }
    }}>
        <div className="toast-area">
            { toasts.map((toast, i) => <Toast className={`toast-notification toast-notification-variant-${toast.variant} ${toast.onClick ? "clickable" : ""}`} key={i} show={toast.show} onClose={((e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                closeToast(toast);
            }) as () => void} // onClick passed e, but the type does not reflect that: https://github.com/react-bootstrap/react-bootstrap/issues/5726
            onClick={() => {
                if (toast.onClick) {
                    closeToast(toast);
                    toast.onClick();
                }
            }}>
                { toast.show && toast.lifetime !== null ? <AutoCall when={toast.lifetime} call={() => closeToast(toast)} /> : null }
                { toast.title ? <Toast.Header>
                    <strong className="me-auto">{ toast.title }</strong>
                </Toast.Header> : null }
                <Toast.Body>{ toast.content }</Toast.Body>
            </Toast>) }
        </div>
        { children }
    </ToastContext.Provider>;
};

import Emitter from 'component-emitter';
import { Workbox } from 'workbox-window';

export const offlineStatus = {
    updateReady: false,
    updateApplying: false,
};

export const offlineStatusEvents = new Emitter;

let wb: Workbox;

export const initializeOfflineManagement = (): void => {
    if ('serviceWorker' in navigator) {
        wb = new Workbox('./sw.js');
    
        wb.addEventListener('waiting', () => {
            console.log("An update has been downloaded and will be deployed at the next opportunity.");
            offlineStatus.updateReady = true;
            offlineStatusEvents.emit('update');
        });

        wb.addEventListener('controlling', (event) => {
            if (event.isUpdate) {
                // reload the page when an update was applied
                window.location.reload();
            }
        });
        
        wb.register();
    }
};

export const applyAvailableUpdateNow = (): void => {
    if (offlineStatus.updateReady) {
        if (wb && wb) {
            wb.messageSkipWaiting();
        }

        offlineStatus.updateReady = false;
        offlineStatus.updateApplying = true;
        offlineStatusEvents.emit('update');
    }
};
